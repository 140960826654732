import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    SaveButton,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    DateInput,
    ImageInput,
    ImageField,
    ReferenceArrayInput,
    SelectArrayInput,
    useCreate,
    useNotify,
    FormWithRedirect,
    useRefresh
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useTranslate } from 'react-admin';

const accessRights = localStorage.getItem('access_rights');

function TaskQuickCreate({ project_id }) {
     const [showDialog, setShowDialog] = useState(false);
     const [create, { loading }] = useCreate('tasks');
     const notify = useNotify();
     const translate = useTranslate();

     const handleClick = () => {
          setShowDialog(true);
     };

     const handleCloseClick = () => {
          setShowDialog(false);
     };

     const handleChange = useRefresh();

     const handleSubmit = async values => {
          values.project_id = project_id;
          create(
               { payload: { data: values } },
               {
                    onSuccess: ({ data }) => {
                         setShowDialog(false);
                         handleChange();
                    },
                    onFailure: ({ error }) => {
                         notify(error.message, 'error');
                    }
               }
          );
     };

     return (
          <>
               <Button onClick={handleClick}>
                   <IconContentAdd /> <span>{translate('custom.tasks.create_task')}</span>
              </Button>
               <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={handleCloseClick}
                    aria-label="Create Task"
               >
                    <DialogTitle>{translate('custom.tasks.create_task')}</DialogTitle>
                    <FormWithRedirect
                         resource="tasks"
                         save={handleSubmit}
                         render={({
                              handleSubmitWithRedirect,
                              pristine,
                              saving
                         }) => (
                              <>
                                   <DialogContent>
                                        <ReferenceArrayInput source="user_id" label='resources.tasks.fields.user' reference="users" perPage="1000" filter={{ project_id: project_id }} fullWidth>
                                             <SelectArrayInput optionText="name" />
                                        </ReferenceArrayInput>
                                        <TextInput source="task_title" label='resources.tasks.fields.task_title' fullWidth  />
                                        <TextInput source="description" label='resources.tasks.fields.description' multiline fullWidth  />
                                        <TextInput source="location" label='resources.tasks.fields.location' multiline fullWidth  />
                                        <DateInput source="start_date" label='resources.tasks.fields.start_date' fullWidth  />
                                        <DateInput source="deadline_date" label='resources.tasks.fields.deadline_date' fullWidth  />
                                        <TextInput source="internal_notes" label='resources.tasks.fields.internal_notes' multiline fullWidth  />
                                        <TextInput source="remarks" label='resources.tasks.fields.remarks' multiline fullWidth  />
                                   </DialogContent>
                                   <DialogActions>
                                        <Button onClick={handleCloseClick} disabled={loading}>
                                             <IconCancel /><span>{translate('custom.general.cancel')}</span>
                                        </Button>
                                        <SaveButton
                                             handleSubmitWithRedirect={
                                                  handleSubmitWithRedirect
                                             }
                                             pristine={pristine}
                                             saving={saving}
                                             disabled={loading}
                                        />
                                   </DialogActions>
                              </>
                         )}
                    />
               </Dialog>
          </>
     );
}

export default TaskQuickCreate;
