import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    SaveButton,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    DateInput,
    ImageInput,
    ImageField,
    useCreate,
    useNotify,
    FormWithRedirect,
    useRefresh
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import { useTranslate } from 'react-admin';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

function ReportCustomItemQuickCreate({ report_id }) {
     const [showDialog, setShowDialog] = useState(false);
     const [create, { loading }] = useCreate('reportitemscustom');
     const notify = useNotify();
     const translate = useTranslate();

     const handleClick = () => {
          setShowDialog(true);
     };

     const handleCloseClick = () => {
          setShowDialog(false);
     };

     const handleChange = useRefresh();

     const convertFileToBase64 = file =>
          new Promise((resolve, reject) => {
               const reader = new FileReader();
               reader.onload = () => resolve(reader.result);
               reader.onerror = reject;

               reader.readAsDataURL(file.rawFile);
          });

     const handleSubmit = async values => {
          values.report_id = report_id;

          if(values.pictures_before)
          {
               if(Array.isArray(values.pictures_before))
               {
                    const newPictures = values.pictures_before.filter(
                         p => p.rawFile instanceof File
                    );

                    const formerPictures = values.pictures_before.filter(
                         p => !(p.rawFile instanceof File)
                    );

                    return Promise.all(newPictures.map(convertFileToBase64))
                         .then(base64Pictures =>
                              base64Pictures.map(picture64 => ({
                                   src: picture64,
                                   title: `${values.title}`,
                              }))
                         )
                         .then(transformedNewPictures =>
                         {
                              if(values.pictures_after)
                              {
                                   values.pictures_before = [
                                      ...transformedNewPictures,
                                      ...formerPictures,
                                 ];

                                   if(Array.isArray(values.pictures_after))
                                   {
                                        const newPicturesAfter = values.pictures_after.filter(
                                             p => p.rawFile instanceof File
                                        );

                                        const formerPicturesAfter = values.pictures_after.filter(
                                             p => !(p.rawFile instanceof File)
                                        );

                                        return Promise.all(newPicturesAfter.map(convertFileToBase64))
                                             .then(base64Pictures =>
                                                  base64Pictures.map(picture64 => ({
                                                       src: picture64,
                                                       title: `${values.title}`,
                                                  }))
                                             )
                                             .then(transformedNewPictures =>
                                                  create({ payload: { data: {
                                                      ...values,
                                                      pictures_after: [
                                                          ...transformedNewPictures,
                                                          ...formerPictures,
                                                      ],
                                                  } } },
                                                  {
                                                       onSuccess: ({ data }) => {
                                                            setShowDialog(false);
                                                            handleChange();
                                                       },
                                                       onFailure: ({ error }) => {
                                                            notify(error.message, 'error');
                                                       }
                                                  }
                                         ));
                                  }
                                  else
                                  {
                                       const myFileAfter = values.pictures_after;
                                       if ( !myFileAfter.rawFile instanceof File ){
                                            return Promise.reject('Error: Not a file...'); // Didn't test this...
                                       }

                                       return Promise.resolve( convertFileToBase64(myFileAfter) )
                                       .then( (picture64) => ({
                                            src: picture64,
                                            title: `${myFileAfter.title}`
                                       }))
                                       .then( transformedMyFile =>
                                            create({ payload: { data: {
                                                     ...values,
                                                     pictures_after: transformedMyFile
                                                 } } },
                                                 {
                                                      onSuccess: ({ data }) => {
                                                           setShowDialog(false);
                                                           handleChange();
                                                      },
                                                      onFailure: ({ error }) => {
                                                           notify(error.message, 'error');
                                                      }
                                                 }
                                        )
                                   )
                                  }
                             }
                             else
                             {
                                   create({ payload: { data: {
                                       ...values,
                                       pictures_before: [
                                           ...transformedNewPictures,
                                           ...formerPictures,
                                       ],
                                   } } },
                                   {
                                        onSuccess: ({ data }) => {
                                             setShowDialog(false);
                                             handleChange();
                                        },
                                        onFailure: ({ error }) => {
                                             notify(error.message, 'error');
                                        }
                                   }
                              )}
                         }
                );
              }
              else
              {
                   const myFile = values.pictures_before;
                   if ( !myFile.rawFile instanceof File ){
                        return Promise.reject('Error: Not a file...'); // Didn't test this...
                   }

                   return Promise.resolve( convertFileToBase64(myFile) )
                   .then( (picture64) => ({
                        src: picture64,
                        title: `${myFile.title}`
                   }))
                   .then( transformedMyFile =>
                    {
                         if(values.pictures_after)
                         {
                              values.pictures_before = transformedMyFile

                              if(Array.isArray(values.pictures_after))
                              {
                                   const newPicturesAfter = values.pictures_after.filter(
                                        p => p.rawFile instanceof File
                                   );

                                   const formerPicturesAfter = values.pictures_after.filter(
                                        p => !(p.rawFile instanceof File)
                                   );

                                   return Promise.all(newPicturesAfter.map(convertFileToBase64))
                                        .then(base64Pictures =>
                                             base64Pictures.map(picture64 => ({
                                                  src: picture64,
                                                  title: `${values.title}`,
                                             }))
                                        )
                                        .then(transformedNewPictures =>
                                             create({ payload: { data: {
                                                 ...values,
                                                 pictures_after: [
                                                     ...transformedNewPictures,
                                                     ...formerPicturesAfter,
                                                 ],
                                             } } },
                                             {
                                                  onSuccess: ({ data }) => {
                                                       setShowDialog(false);
                                                       handleChange();
                                                  },
                                                  onFailure: ({ error }) => {
                                                       notify(error.message, 'error');
                                                  }
                                             }
                                    ));
                             }
                             else
                             {
                                  const myFileAfter = values.pictures_after;
                                  if ( !myFileAfter.rawFile instanceof File ){
                                       return Promise.reject('Error: Not a file...'); // Didn't test this...
                                  }

                                  return Promise.resolve( convertFileToBase64(myFileAfter) )
                                  .then( (picture64) => ({
                                       src: picture64,
                                       title: `${myFileAfter.title}`
                                  }))
                                  .then( transformedMyFile =>
                                       create({ payload: { data: {
                                                ...values,
                                                pictures_after: transformedMyFile
                                            } } },
                                            {
                                                 onSuccess: ({ data }) => {
                                                      setShowDialog(false);
                                                      handleChange();
                                                 },
                                                 onFailure: ({ error }) => {
                                                      notify(error.message, 'error');
                                                 }
                                            }
                                   )
                              )
                             }
                        }
                        else
                        {
                             create({ payload: { data: {
                                      ...values,
                                      pictures_before: transformedMyFile
                                  } } },
                                  {
                                       onSuccess: ({ data }) => {
                                            setShowDialog(false);
                                            handleChange();
                                       },
                                       onFailure: ({ error }) => {
                                            notify(error.message, 'error');
                                       }
                                  }
                         )}
                    }
               )
              }
         }
         else if(values.pictures_after)
         {
              if(Array.isArray(values.pictures_after))
             {
                  const newPicturesAfter = values.pictures_after.filter(
                       p => p.rawFile instanceof File
                  );

                  const formerPicturesAfter = values.pictures_after.filter(
                       p => !(p.rawFile instanceof File)
                  );

                  return Promise.all(newPicturesAfter.map(convertFileToBase64))
                       .then(base64Pictures =>
                            base64Pictures.map(picture64 => ({
                                 src: picture64,
                                 title: `${values.title}`,
                            }))
                       )
                       .then(transformedNewPictures =>
                            create({ payload: { data: {
                                ...values,
                                pictures_after: [
                                    ...transformedNewPictures,
                                    ...formerPicturesAfter,
                                ],
                            } } },
                            {
                                 onSuccess: ({ data }) => {
                                      setShowDialog(false);
                                      handleChange();
                                 },
                                 onFailure: ({ error }) => {
                                      notify(error.message, 'error');
                                 }
                            }
                   ));
            }
            else
            {
                 const myFileAfter = values.pictures_after;
                 if ( !myFileAfter.rawFile instanceof File ){
                      return Promise.reject('Error: Not a file...'); // Didn't test this...
                 }

                 return Promise.resolve( convertFileToBase64(myFileAfter) )
                 .then( (picture64) => ({
                      src: picture64,
                      title: `${myFileAfter.title}`
                 }))
                 .then( transformedMyFile =>
                      create({ payload: { data: {
                              ...values,
                              pictures_after: transformedMyFile
                           } } },
                           {
                                onSuccess: ({ data }) => {
                                     setShowDialog(false);
                                     handleChange();
                                },
                                onFailure: ({ error }) => {
                                     notify(error.message, 'error');
                                }
                           }
                  )
             )
            }
        }
        else
        {
             create(
                  { payload: { data: values } },
                  {
                            onSuccess: ({ data }) => {
                                 setShowDialog(false);
                                 handleChange();
                       },
                       onFailure: ({ error }) => {
                            notify(error.message, 'error');
                       }
                  }
             );
        }
     };

     return (
          <>
               <Button onClick={handleClick}>
                    <IconContentAdd /> <span>{translate('custom.reports.add_custom_item')}</span>
               </Button>
               <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={handleCloseClick}
                    aria-label="Add Custom Item"
               >
                    <DialogTitle>{translate('custom.reports.add_custom_item')}</DialogTitle>
                    <FormWithRedirect
                         resource="reportitemscustom"
                         save={handleSubmit}
                         render={({
                              handleSubmitWithRedirect,
                              pristine,
                              saving
                         }) => (
                              <>
                                   <DialogContent>
                                        <TextInput multiline source="description" label='custom.reports.description' fullWidth />
                                        <TextInput multiline source="location" label='custom.reports.location' fullWidth />
                                        <TextInput multiline source="remarks" label='custom.reports.remarks' fullWidth />

                                        <ImageInput source="pictures_before" label='custom.reports.before_image'  multiple accept="image/jpeg,image/png" >
                                             <ImageField source="src" />
                                        </ImageInput>
                                        <ImageInput source="pictures_after" label='custom.reports.after_image'  multiple accept="image/jpeg,image/png" >
                                             <ImageField source="src" />
                                        </ImageInput>
                                   </DialogContent>
                                   <DialogActions>
                                        <Button onClick={handleCloseClick} disabled={loading}>
                                             <IconCancel /><span>{translate('custom.general.cancel')}</span>
                                        </Button>
                                        <SaveButton
                                             handleSubmitWithRedirect={
                                                  handleSubmitWithRedirect
                                             }
                                             pristine={pristine}
                                             saving={saving}
                                             disabled={loading}
                                        />
                                   </DialogActions>
                              </>
                         )}
                    />
               </Dialog>
          </>
     );
}

export default ReportCustomItemQuickCreate;
