import React, { useState, useCallback } from "react";
import PropTypes from 'prop-types';
import { useInput, required } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { useRecordContext, Toolbar, SaveButton, Filter, Show, SimpleShowLayout, List, SimpleList, Datagrid, ArrayField, ReferenceField, ReferenceManyField, SingleFieldList, BooleanField, ChipField, DateField, UrlField, ImageField, TextField, EmailField, EditButton, DeleteButton, ShowButton, CreateButton, Create, Edit, SimpleForm, BooleanInput, ImageInput, PasswordInput, ReferenceInput, SelectInput, TextInput, ReferenceArrayInput, SelectArrayInput, } from 'react-admin';
import { useMediaQuery, Drawer, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import RoomIcon from '@material-ui/icons/Room';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import StarIcon from '@material-ui/icons/Star';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';

import TaskQuickCreate from './TaskQuickCreate';
import TeamQuickCreate from './TeamQuickCreate';
import TeammemberQuickCreate from './TeammemberQuickCreate';
import LogShow from './LogShow';
import UserQuickShow from './UserQuickShow';
import ClientQuickShow from './ClientQuickShow';

import config from './config';

const accessRights = localStorage.getItem('access_rights');

function TabPanel(props) {
     const { children, value, index, ...other } = props;

     return (
          <div
               role="tabpanel"
               hidden={value !== index}
               id={`simple-tabpanel-${index}`}
               aria-labelledby={`simple-tab-${index}`}
               {...other}
          >
               {value === index && (
                    <Box p={3}>
                         <Typography>{children}</Typography>
                    </Box>
               )}
          </div>
     );
}

TabPanel.propTypes = {
     children: PropTypes.node,
     index: PropTypes.any.isRequired,
     value: PropTypes.any.isRequired,
};

function a11yProps(index) {
     return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
     };
}

const useStyles = makeStyles({
     profilePicture: {
          paddingTop: "5px",
          '& img': {
               maxHeight: "50px",
               margin: 0,
               border: '2px solid #bbb',
               borderRadius: '50px',
               backgroundColor: '#EEE',
          },
     },
     editProfilePicture: {
          '& img': {
               border: '2px solid #bbb',
               borderRadius: '50%',
               backgroundColor: '#EEE',
          },
     },
     root: {
          flexGrow: 1,
          width: "100%",
     },
     taskassigned: {
          color: "#2b3197"
     },
     taskstarted: {
          color: "#aa6413"
     },
     taskcompleted: {
          color: "#3fb53f"
     },
});

const GooglMapFieldWrapper = withScriptjs(withGoogleMap((props) => {

     const { source } = props;
     const record = useRecordContext(props);

     const latCenter = parseFloat(record.latitude);
     const lngCenter = parseFloat(record.longitude);;
     const zoom = 13;

     return (
          <div>
               <GoogleMap
                    defaultZoom={zoom}
                    defaultCenter={{ lat: latCenter, lng: lngCenter }}
               >
               <Marker position={{ lat: latCenter, lng: lngCenter }} />
               </GoogleMap>
          </div>
)}));

const MapField = (props) => {

     return (
          <div style={{ height: '60vh', width: '100%' }}>
               <GooglMapFieldWrapper
                    googleMapURL={googleMapURL}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `60vh`, position: "relative" }} />}
                    mapElement={<div style={{ height: `100%` }} />}
               />
          </div>
     );
};

const ForemenField = (props) => {

     const { source } = props;
     const record = useRecordContext(props);

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     const translate = useTranslate();

     return record && record.foremen ? (
          <div style={{borderTop: "1px solid #DDD", paddingTop: "20px", marginTop: "15px"}}>
               <Typography variant="h6">{translate('resources.projects.fields.foremen_id')}</Typography>
               <ul style={{paddingLeft: 0}}>
                    {record.foremen.map(item => (
                         <>
                              { isSmall ? (
                                   <li key={item.id} style={{listStyleType: 'none', display: 'block', background: '#F6F6F6', margin: '0 1.2% 15px', borderRadius: '25px', border: '1px solid #DDD', padding: 0}}>
                                        <UserQuickShow user_id={item.id} image_height="50px" />
                                        <span style={{paddingLeft: "10px"}}>{item.name}</span>
                                   </li>
                              ) : (
                                   <li key={item.id} style={{listStyleType: 'none', width: '47%', display: 'inline-block', background: '#F6F6F6', margin: '0 1.2% 15px', borderRadius: '25px', border: '1px solid #DDD', padding: 0}}>
                                        <UserQuickShow user_id={item.id} image_height="50px" />
                                        <span style={{paddingLeft: "10px"}}>{item.name}</span>
                                   </li>
                              )}
                         </>
                    ))}
               </ul>
          </div>
) : null};

const TeamField = (props) => {

     const { source } = props;
     const record = useRecordContext(props);
     const redirect = record ? `/projects/${record.id}/show` : false;

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     const translate = useTranslate();

     return record && record.teams ? (
          <div>
               {(accessRights != "worker") ? (
                    <div style={{float: "right", paddingTop: "2px", paddingRight: "10px"}}>
                         <TeamQuickCreate label="Create Team" project_id={record.id} />
                    </div>
               ) : null }
               <Typography variant="h6">{translate('custom.projects.title_team')}</Typography>
               <div style={{borderTop: "1px solid #DDD", marginTop: "16px"}}>
                    <ul style={{paddingLeft: 0}}>
                         {record.teams.map(item => item && item.id ? (
                              <li key={item.id} style={{listStyleType: 'none', background: '#FAFAFA', margin: '0 0 15px', border: '1px solid #DDD', padding: "15px"}}>
                                   <div style={{float: "right", marginTop: "-10px"}}>
                                        {(accessRights != "worker") ? (
                                             <>
                                                  <EditButton to={{
                                                       pathname: `/teams/${item.id}`,
                                                       state: { record: { project_id: record.id } },
                                                  }} />
                                                  <DeleteButton undoable={false} basePath={redirect} record={item} resource="teams" />
                                             </>
                                        ) : null }
                                   </div>
                                   <Typography variant="h6" style={{marginBottom: "15px"}}>{item.team_name}</Typography>
                                   <div style={{marginBottom: "10px"}}>
                                        {item.members.map(memberitem => (
                                             <>
                                             { isSmall ? (
                                                  <div key={memberitem.id} style={{width: "100%", background: '#EEE', margin: '0 1.2% 15px', borderRadius: '25px', border: '1px solid #DDD', padding: 0, overflow: 'hidden'}}>
                                                       {(accessRights != "worker") ? (
                                                            <div style={{float: 'right', lineHeight: '42px'}}><DeleteButton undoable={false} basePath={redirect} record={memberitem} resource="teammembers" label="" /></div>
                                                       ) : null }
                                                       {memberitem.is_teamleader ?
                                                            <div style={{position: 'absolute', zIndex: 10, marginLeft: '26px', marginTop: '-10px', color: 'red'}}><StarIcon /></div> : ''
                                                       }
                                                       <UserQuickShow user_id={memberitem.user_id} image_height="50px" />
                                                       <span style={{paddingLeft: "10px", whiteSpace: "nowrap", width: "calc(100% - 130px)", overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", verticalAlign: "middle"}}>{memberitem.name}</span>
                                                  </div>
                                             ) : (
                                                  <div key={memberitem.id} style={{width: '47%', display: 'inline-block', background: '#EEE', margin: '0 1.2% 15px', borderRadius: '25px', border: '1px solid #DDD', padding: 0, overflow: 'hidden'}}>
                                                       {(accessRights != "worker") ? (
                                                            <div style={{float: 'right', lineHeight: '42px'}}><DeleteButton undoable={false} basePath={redirect} record={memberitem} resource="teammembers" label="" /></div>
                                                       ) : null }
                                                       {memberitem.is_teamleader ?
                                                            <div style={{position: 'absolute', zIndex: 10, marginLeft: '26px', marginTop: '-10px', color: 'red'}}><StarIcon /></div> : ''
                                                       }
                                                       <UserQuickShow user_id={memberitem.user_id} image_height="50px" />
                                                       <span style={{paddingLeft: "10px", whiteSpace: "nowrap", width: "calc(100% - 130px)", overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", verticalAlign: "middle"}}>{memberitem.name}</span>
                                                  </div>
                                             )}
                                             </>
                                        ))}
                                   </div>
                                   {(accessRights != "worker") ? (
                                        <TeammemberQuickCreate team_id={item.id} />
                                   ) : null }
                              </li>
                         ) : null )}
                    </ul>
               </div>
          </div>
) : null};


const TaskField = (props) => {

     const { source } = props;
     const record = useRecordContext(props);
     const redirect = record ? `/projects/${record.id}/show` : false;

     const translate = useTranslate();

     const classes = useStyles();

     return record && record.tasks ? (
          <div>
               <div style={{float: "right", paddingTop: "2px", paddingRight: "10px"}}>
                    <TaskQuickCreate label="Create Task" project_id={record.id} />
               </div>
               <Typography variant="h6">{translate('custom.projects.title_task')}</Typography>
               <div style={{borderTop: "1px solid #DDD", marginTop: "10px"}}>
                    <ul style={{paddingLeft: 0}}>
                         {record.tasks.map(item => (
                              <li key={item.id} style={{listStyleType: 'none', background: '#FAFAFA', margin: '0 0 15px', border: '1px solid #DDD', padding: "15px", position: "relative"}}>
                                   <div>
                                        <div style={{float: "right", marginRight: "-12px", marginTop: "-5px"}}>
                                             <Button component={Link} to={{
                                                  pathname: `/tasks/${item.id}/show`
                                             }} style={{fontSize: "13px"}}>
                                                  <VisibilityIcon style={{fontSize: "18px", marginRight: "5px"}} /><span>{translate('custom.tasks.show_more')}</span>
                                             </Button>
                                        </div>

                                        {(item.status == "assigned") ? (
                                             <><div className={classes[`task${item.status}`]} style={{textTransform: "capitalize", marginTop: "-15px", marginBottom: "0", marginLeft: "-15px", marginRight: "-15px", paddingTop: "5px", paddingBottom: "5px", paddingLeft: "5px", background: "#DDD", fontSize: "12px", fontWeight: "bold", lineHeight: "20px"}}><AssignmentIcon style={{verticalAlign: "top", fontSize: "20px"}} /> {translate('custom.tasks.assigned')}</div></>
                                        ):(
                                             <>
                                             {(item.status == "started") ? (
                                                  <div className={classes[`task${item.status}`]} style={{textTransform: "capitalize", marginTop: "-15px", marginBottom: "0", marginLeft: "-15px", marginRight: "-15px", paddingTop: "5px", paddingBottom: "5px", paddingLeft: "5px", background: "#DDD", fontSize: "12px", fontWeight: "bold", lineHeight: "20px"}}><AssignmentLateIcon style={{verticalAlign: "top", fontSize: "20px"}} /> {translate('custom.tasks.started')}</div>
                                             ):(
                                                  <div className={classes[`task${item.status}`]} style={{textTransform: "capitalize", marginTop: "-15px", marginBottom: "0", marginLeft: "-15px", marginRight: "-15px", paddingTop: "5px", paddingBottom: "5px", paddingLeft: "5px", background: "#DDD", fontSize: "12px", fontWeight: "bold", lineHeight: "20px"}}><AssignmentTurnedInIcon style={{verticalAlign: "top", fontSize: "20px"}} /> {translate('custom.tasks.completed')}</div>
                                             )}
                                             </>
                                        )}

                                        <div style={{ fontSize: "12px", fontWeight: "bold", padding: "2px 8px", background: "#EEE", marginLeft: "-15px", marginRight: "-15px", marginBottom: "12px" }}>
                                             <div style={{float: "right"}}>{translate('resources.tasks.fields.deadline_date')}: {item.deadline_date}</div>
                                             <div style={{}}>{translate('resources.tasks.fields.start_date')}: {item.start_date}</div>
                                        </div>

                                        <div style={{}}>
                                             {item.users.map(memberitem => (
                                                  <div key={memberitem.id} style={{display: 'inline-block', margin: "0 2px", padding: 0, overflow: 'hidden'}}>
                                                       <UserQuickShow user_id={memberitem.user_id} image_height="30px" />
                                                  </div>
                                             ))}
                                        </div>
                                   </div>

                                   <Typography style={{fontWeight: "bold"}}>{item.task_title}</Typography>
                                   <div>{item.description}</div>

                              </li>
                         ))}
                    </ul>
               </div>
          </div>
) : null};

const ClientField = (props) => {

     const { source } = props;
     const record = useRecordContext(props);
     const translate = useTranslate();

     return record ? (
          <div>
               <label style={{color: "rgba(0, 0, 0, 0.54)", padding: 0, fontSize: "12px", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: 400, lineHeight: 1, letterSpacing: "0.00938em"}}>{translate('resources.projects.fields.client_id')}</label>
               <div>
                    <ClientQuickShow client_id={record.client_id} />
               </div>
          </div>
) : null};

const ProjectNameField = (props) => {

     const { source } = props;
     const record = useRecordContext(props);

     return record ? (
          <div style={{position: "absolute", fontSize: "22px", top: "75px", left: "10px", height: "30px", overflow: "hidden", whiteSpace: "nowrap", width: "80%", textOverflow: "ellipsis"}}>
               {record.project_name}
          </div>
) : null};

export const ProjectShow = props => {

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     const translate = useTranslate();

     const [value, setValue] = React.useState(0);

     const handleChange = (event, newValue) => {
          setValue(newValue);
     };

     return (
          <Show {...props} title=" ">
               { isSmall ? (
                    <div>
                         <ProjectNameField />
                         <div  className={classes.root}>
                         <AppBar position="static" color="default">
                              <Tabs
                                   value={value}
                                   onChange={handleChange}
                                   indicatorColor="primary"
                                   textColor="primary"
                                   centered
                                   variant="scrollable"
                                   scrollButtons="auto"
                                   aria-label="scrollable auto tabs"
                              >
                                   <Tab label={translate('custom.projects.tab_project_info')} />
                                   <Tab label={translate('custom.projects.tab_map')} />
                                   <Tab label={translate('custom.projects.tab_team')} />
                                   <Tab label={translate('custom.projects.tab_task')} />
                                   <Tab label={translate('custom.projects.tab_project_logs')} />
                              </Tabs>
                         </AppBar>
                         </div>
                         <TabPanel value={value} index={0}>
                              <SimpleShowLayout>
                                   <TextField source="project_name" label="" style={{fontSize: "30px"}} />
                                   <ClientField />
                                   <TextField source="address" label="resources.projects.fields.address" />
                                   <TextField source="notes" label="resources.projects.fields.notes" />
                                   <ForemenField />
                              </SimpleShowLayout>
                         </TabPanel>
                         <TabPanel value={value} index={1}>
                              <MapField />
                         </TabPanel>
                         <TabPanel value={value} index={2}>
                              <TeamField />
                         </TabPanel>
                         <TabPanel value={value} index={3}>
                              <TaskField />
                         </TabPanel>
                         <TabPanel value={value} index={4}>
                              <div style={{position: "relative"}}>
                                   <div>
                                        <LogShow component_type="projectlogs" />
                                   </div>
                              </div>
                         </TabPanel>
                    </div>
               ) : (
                    <div>
                         <div>
                              <div style={{display: "inline-block", width: "49%", marginRight: "1%", verticalAlign: "top"}}>
                                   <SimpleShowLayout>
                                        <TextField source="project_name" label="" style={{fontSize: "30px"}} />
                                        <ClientField />
                                        <TextField source="address" label="resources.projects.fields.address" />
                                        <TextField source="notes" label="resources.projects.fields.notes" />
                                        <ForemenField />
                                   </SimpleShowLayout>
                              </div>
                              <div style={{display: "inline-block", width: "49%", verticalAlign: "top", paddingTop: "10px", paddingBottom: "10px"}}>
                                   <MapField />
                              </div>
                         </div>
                         <div style={{borderTop: "1px solid #DDD", margin: "50px 15px 15px", paddingTop: "50px"}}>
                              <Paper className={classes.root}>
                                   <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        centered
                                   >
                                        <Tab label={translate('custom.projects.tab_team_tasks')} />
                                        <Tab label={translate('custom.projects.tab_project_logs')} />
                                   </Tabs>
                              </Paper>
                              <TabPanel value={value} index={0}>
                                   <div>
                                        <div style={{display: "inline-block", width: "49%", marginRight: "1%", verticalAlign: "top"}}>
                                             <div>
                                                  <TeamField />
                                             </div>
                                        </div>
                                        <div style={{display: "inline-block", width: "49%", marginRight: "1%", verticalAlign: "top"}}>
                                             <div>
                                                  <TaskField />
                                             </div>
                                        </div>
                                   </div>
                              </TabPanel>
                              <TabPanel value={value} index={1}>
                                   <div style={{position: "relative"}}>
                                        <div>
                                             <LogShow component_type="projectlogs" />
                                        </div>
                                   </div>
                              </TabPanel>
                         </div>
                    </div>

               )}
          </Show>
)};

const ProjectFilter = (props) => (
     <Filter {...props}>
          <TextInput source="project_name" />
          <ReferenceInput source="client_id" reference="clients">
               <SelectInput optionText="name" />
          </ReferenceInput>
     </Filter>
);

export const ProjectList = props => {

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();
     const translate = useTranslate();

     return (
          <>
               <div style={{paddingLeft: "15px", paddingTop: "15px", fontSize: "20px", fontWeight: "bold"}}>{translate('resources.projects.name')}</div>
               <List {...props} title=" " filters={<ProjectFilter />} bulkActionButtons={false} perPage="1000" exporter={false}>
                    {isSmall ? (
                         <SimpleList
                              linkType="show"
                              primaryText={record => record.project_name}
                              secondaryText={record => record.address}
                         />
                    ) : (
                         <Datagrid rowClick="show">
                              <TextField source="project_name" />
                              <ReferenceField link="show" source="client_id" reference="clients">
                                   <TextField source="name" />
                              </ReferenceField>
                              <TextField source="address" />
                              <ShowButton />
                         </Datagrid>
                    )}
               </List>
          </>
)};

const EditTitle = ({ record }) => {
     return <span>Project: {record ? `${record.project_name}` : ''}</span>;
};

const EditToolbar = props => {

     if(accessRights == "superadmin")
     {
          return (
               <Toolbar {...props} style={{justifyContent: "space-between"}}>
                    <SaveButton />
                    <DeleteButton />
               </Toolbar>
          )
     }
     else
     {
          return (
               <Toolbar {...props}>
                    <SaveButton />
               </Toolbar>
          )
     }
};

export const ProjectEdit = props => {
     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();
     const translate = useTranslate();

     return (
     <div>
          {isSmall ? (
               <Edit undoable={false} title=" " {...props}>
                    <SimpleForm redirect="/projects" toolbar={<EditToolbar />}>
                         <TextInput source="project_name" label="resources.projects.fields.project_name" fullWidth  />
                         <ReferenceArrayInput source="foremen_id" label="resources.projects.fields.foremen_id" reference="foremen" fullWidth>
                              <SelectArrayInput optionText="name" />
                         </ReferenceArrayInput>
                         <ReferenceInput source="client_id" label="resources.projects.fields.client_id" reference="clients" fullWidth>
                              <SelectInput optionText="name" />
                         </ReferenceInput>
                         <TextInput multiline source="address" label="resources.projects.fields.address" fullWidth />
                         <TextInput multiline source="notes" label="resources.projects.fields.notes" fullWidth />

                         <MapInputField {...props}  />
                    </SimpleForm>
               </Edit>
          ) : (
               <Edit undoable={false} title=" " {...props}>
                    <SimpleForm redirect="/projects" toolbar={<EditToolbar />}>
                         <div style={{width: "60%", margin: "0 auto"}}>
                              <TextInput source="project_name" label="resources.projects.fields.project_name" fullWidth  />
                              <ReferenceArrayInput source="foremen_id" label="resources.projects.fields.foremen_id" reference="foremen" fullWidth>
                                   <SelectArrayInput optionText="name" />
                              </ReferenceArrayInput>
                              <ReferenceInput source="client_id" label="resources.projects.fields.client_id" reference="clients" fullWidth>
                                   <SelectInput optionText="name" />
                              </ReferenceInput>
                              <TextInput multiline source="address" label="resources.projects.fields.address" fullWidth />
                              <TextInput multiline source="notes" label="resources.projects.fields.notes" fullWidth />

                              <MapInputField {...props}  />
                         </div>
                    </SimpleForm>
               </Edit>
          )}
     </div>
)};

const googleMapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&region=th&key=${config.google_maps_key}`;
let mapObject = {};

const onMapLoad = (map) => {
     mapObject = map;
}

const GooglMapInputWrapper = withScriptjs(withGoogleMap((props) => {

     const form = useForm();
     var formdata = form.getState().values;

     const latCenter = (formdata.latitude) ? parseFloat(formdata.latitude): 13.7563;
     const lngCenter = (formdata.longitude) ? parseFloat(formdata.longitude) : 100.50183;

     const [lat, setLat] = React.useState(latCenter);
     const [lng, setLng] = React.useState(lngCenter);

     const onDragEndFunc = () => {
          form.change('latitude', mapObject.getCenter().lat());
          form.change('longitude', mapObject.getCenter().lng());
     }

     const getAutocompleteResult = (value) => {
          console.log(value.label);

          geocodeByAddress(value.label)
               .then(results => getLatLng(results[0]))
               .then(({ lat, lng }) =>
               {
                    setLat(lat);
                    setLng(lng);

                    form.change('latitude', lat);
                    form.change('longitude', lng);
               }
          );
     }

     return (
          <div>
               <div style={{display: "none"}}>
                    <div style={{display: "inline-block", width: "49%", marginRight: "2%"}}>
                         <TextInput source="latitude" fullWidth  />
                    </div>
                    <div style={{display: "inline-block", width: "49%"}}>
                         <TextInput source="longitude" fullWidth  />
                    </div>
               </div>
               <div style={{position: "absolute", top: "-42px", left:0, right:0}}>
                    <GooglePlacesAutocomplete selectProps={{
                         onChange: getAutocompleteResult,
                    }} />
               </div>
               <div style={{position: "absolute", top: "50%", left:"50%", marginTop: "-33px", marginLeft: "-18px"}}>
                    <RoomIcon fontSize="large" style={{ color: "#F00" }} />
               </div>

               <GoogleMap
                    defaultZoom={13}
                    defaultCenter={{ lat: lat, lng: lng }}
                    center={{ lat: lat, lng: lng }}
                    onDragEnd={onDragEndFunc}
                    ref={props.onMapLoad}
                    gestureHandling="greedy"
               >

               </GoogleMap>
          </div>
)}));

const MapInputField = (props) => {


     return (
          <div>
               <div style={{ height: '60vh', width: '100%' }}>
                    <GooglMapInputWrapper
                         googleMapURL={googleMapURL}
                         loadingElement={<div style={{ height: `100%` }} />}
                         containerElement={<div style={{ height: `400px`, position: "relative" }} />}
                         mapElement={<div style={{ height: `100%`, marginTop: "42px" }} />}
                         onMapLoad={onMapLoad}
                    />

               </div>
          </div>
     );
};

export const ProjectCreate = props => {

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();
     const translate = useTranslate();

     return (
          <div>
               {(isSmall) ? (
                    <Create undoable={false} title=" " {...props}>
                         <SimpleForm redirect="/projects">
                              <TextInput source="project_name" label="resources.projects.fields.project_name" fullWidth  />
                              <ReferenceArrayInput source="foremen_id" label="resources.projects.fields.foremen_id" reference="foremen" fullWidth>
                                   <SelectArrayInput optionText="name" />
                              </ReferenceArrayInput>
                              <ReferenceInput source="client_id" label="resources.projects.fields.client_id" reference="clients" fullWidth>
                                   <SelectInput optionText="name" />
                              </ReferenceInput>
                              <TextInput multiline source="address" label="resources.projects.fields.address" fullWidth />
                              <TextInput multiline source="notes" label="resources.projects.fields.notes" fullWidth />

                              <MapInputField />
                         </SimpleForm>
                    </Create>
               ) : (
                    <Create undoable={false} title=" " {...props}>
                         <SimpleForm redirect="/projects">
                              <div style={{width: "60%", margin: "0 auto"}}>
                                   <TextInput source="project_name" label="resources.projects.fields.project_name" fullWidth  />
                                   <ReferenceArrayInput source="foremen_id" label="resources.projects.fields.foremen_id" reference="foremen" fullWidth>
                                        <SelectArrayInput optionText="name" />
                                   </ReferenceArrayInput>
                                   <ReferenceInput source="client_id" label="resources.projects.fields.client_id" reference="clients" fullWidth>
                                        <SelectInput optionText="name" />
                                   </ReferenceInput>
                                   <TextInput multiline source="address" label="resources.projects.fields.address" fullWidth />
                                   <TextInput multiline source="notes" label="resources.projects.fields.notes" fullWidth />

                                   <MapInputField />
                              </div>
                         </SimpleForm>
                    </Create>
               )}
          </div>
)};
