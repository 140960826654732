import * as React from 'react';
import { useState } from 'react';
import { useQuery, AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Logo from './Logo';
import LocaleSwitcher from './LocaleSwitcher';
import NotificationsShow from './NotificationsShow';

import config from './config';

const useStyles = makeStyles({
     title: {
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          color: 'Lavender',
     },
     spacer: {
          flex: 1,
     },
     barBackground: {
          backgroundColor: config.appbar_background,
     },
});



const MyAppBar = props => {
     const classes = useStyles();
     return (
          <>
               <AppBar {...props} className={classes.barBackground}>
                    <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
                    <Logo />
                    <LocaleSwitcher />
                    <span className={classes.spacer} />
               </AppBar>
               <NotificationsShow />
          </>
     );
};

export default MyAppBar;
