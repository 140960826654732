import * as React from "react";
import PropTypes from 'prop-types';
import { useRecordContext, useQuery, useUpdate, useRefresh, useRedirect , Filter, CreateButton, DeleteButton, ShowButton, Show, List, SimpleShowLayout, SimpleShowLayoutList, SimpleList, Datagrid, ReferenceField, ReferenceManyField, SingleFieldList, ReferenceArrayInput, SelectArrayInput, BooleanField, ChipField, DateField, ImageField, TextField, EmailField, EditButton, Create, Edit, SimpleForm, BooleanInput, ImageInput, PasswordInput, ReferenceInput, SelectInput, TextInput, DateInput, } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Toolbar } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { useLocation } from 'react-router';
import { Calendar } from '@natscale/react-calendar';
import '@natscale/react-calendar/dist/main.css';

import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import TaskImageQuickCreate from './TaskImageQuickCreate';
import TaskImageEdit from './TaskImageEdit';
import TaskImageEditMobile from './TaskImageEditMobile';
import LogQuickCreate from './LogQuickCreate';
import LogShow from './LogShow';
import ReportQuickCreate from './ReportQuickCreate';

import UserQuickShow from './UserQuickShow';

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

import { useTranslate } from 'react-admin';

const accessRights = localStorage.getItem('access_rights');

function TabPanel(props) {
     const { children, value, index, ...other } = props;

     return (
          <div
               role="tabpanel"
               hidden={value !== index}
               id={`simple-tabpanel-${index}`}
               aria-labelledby={`simple-tab-${index}`}
               {...other}
          >
               {value === index && (
                    <Box p={3}>
                         <Typography>{children}</Typography>
                    </Box>
               )}
          </div>
     );
}

TabPanel.propTypes = {
     children: PropTypes.node,
     index: PropTypes.any.isRequired,
     value: PropTypes.any.isRequired,
};

function a11yProps(index) {
     return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
     };
}

const useStyles = makeStyles({
     profilePicture: {
          paddingTop: "5px",
          '& img': {
               maxHeight: "50px",
               margin: 0,
               border: '2px solid #bbb',
               borderRadius: '50px',
               backgroundColor: '#EEE',
          },
     },
     taskImageField: {
          '& img': {
               maxWidth: "100%",
          },
     },
     root: {
          flexGrow: 1,
          width: "100%",
     },
     taskassigned: {
          color: "#2b3197"
     },
     taskstarted: {
          color: "#aa6413"
     },
     taskcompleted: {
          color: "#3fb53f"
     },
});

const TaskImageField = (props) => {

     const { source } = props;
     const record = useRecordContext(props);
     const redirect = record ? `/tasks/${record.id}/show` : false;
     const translate = useTranslate();

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     return record ? (
          <div>
               <div style={{float: "right", marginTop: "-5px", paddingRight: "10px"}}>
                    <TaskImageQuickCreate task_id={record.id} />
               </div>
               <Typography variant="h6">{translate('custom.tasks.task_images')}</Typography>
               { isSmall ? (
                    <TaskImageEditMobile task_id={record.id} attachments={record.attachments} />
               ) : (
                    <TaskImageEdit task_id={record.id} attachments={record.attachments} />
               )}
          </div>

) : null};

const EditTaskStatusButton = (props) => {

     const { source } = props;
     const record = useRecordContext(props);
     const translate = useTranslate();

     console.log(record);

     const [update, { loading, error }] = useUpdate();

     const handleRefresh = useRefresh();

     const editTaskStatus = () => {
          if(record)
          {
               const diff = (record.status == "assigned") ? { status: "started" } : { status: "completed" };

               update('tasks', record.id, diff, record);

               setTimeout(function()
               {
                    handleRefresh();
               }, 1000);
          }
     }


     return (record) ? (
          <>
               {record.status == "assigned" ? (
                    <>
                         <Button onClick={editTaskStatus} style={{color: "#3f51b5"}}>
                              <AssignmentLateIcon /> {translate('custom.tasks.start_task')}
                         </Button>
                         <br />
                    </>
               ) : (
                    <>
                         {record.status == "started" ? (
                              <>
                                   <Button onClick={editTaskStatus} style={{color: "#0A0"}}>
                                        <AssignmentTurnedInIcon /> {translate('custom.tasks.complete_task')}
                                   </Button>
                                   <br />
                              </>
                         ) : null}
                    </>
               )}
               <ReportQuickCreate project_id={record.project_id} task_id={record.id} />
          </>
     ) : null
};

const TaskTitleField = (props) => {

     const { source } = props;
     const record = useRecordContext(props);

     return record ? (
          <div style={{position: "absolute", fontSize: "22px", top: "75px", left: "10px"}}>
               {record.task_title}
          </div>
) : null};

const TaskStatusField = (props) => {

     const { source } = props;
     const record = useRecordContext(props);

     const classes = useStyles();
     const translate = useTranslate();

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     const margin = isSmall ? "-22px -25px 0px -25px" : "0px -13px 0px 0px";

     return record ? (
          <>
          {(record.status == "assigned") ? (
               <><div className={classes[`task${record.status}`]} style={{textTransform: "capitalize", margin: margin, paddingTop: "6px", paddingBottom: "6px", paddingLeft: "5px", background: "#DDD", fontSize: "12px", fontWeight: "bold", lineHeight: "20px"}}><AssignmentIcon style={{verticalAlign: "top", fontSize: "20px"}} /> {translate('custom.tasks.assigned')}</div></>
          ):(
               <>
               {(record.status == "started") ? (
                    <div className={classes[`task${record.status}`]} style={{textTransform: "capitalize", margin: margin, paddingTop: "6px", paddingBottom: "6px", paddingLeft: "5px", background: "#DDD", fontSize: "12px", fontWeight: "bold", lineHeight: "20px"}}><AssignmentLateIcon style={{verticalAlign: "top", fontSize: "20px"}} /> {translate('custom.tasks.started')}</div>
               ):(
                    <>
                    {(record.status == "completed") ? (
                         <div className={classes[`task${record.status}`]} style={{textTransform: "capitalize", margin: margin, paddingTop: "6px", paddingBottom: "6px", paddingLeft: "5px", background: "#DDD", fontSize: "12px", fontWeight: "bold", lineHeight: "20px"}}><AssignmentTurnedInIcon style={{verticalAlign: "top", fontSize: "20px"}} /> {translate('custom.tasks.completed')}</div>
                    ): null}
                    </>
               )}
               </>
          )}
          </>
) : null};

const TaskDateFields = (props) => {

     const { source } = props;
     const record = useRecordContext(props);

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const translate = useTranslate();

     const width = isSmall ? "100%" : "49%";

     return record ? (
          <div style={{marginBottom: "15px"}}>
          {record.start_date_format ? (
               <div style={{display: "inline-block", width: width, paddingBottom: "6px", fontSize: "13px"}}><b>{translate('custom.tasks.start_date')}:</b> {record.start_date_format}</div>
          ) : null}
          {record.deadline_date_format ? (
               <div style={{display: "inline-block", width: width, paddingBottom: "6px", fontSize: "13px"}}><b>{translate('custom.tasks.deadline_date')}:</b> {record.deadline_date_format}</div>
          ) : null}
          {record.started_on ? (
               <div style={{display: "inline-block", width: width, paddingBottom: "6px", fontSize: "13px"}}><b>{translate('custom.tasks.started_on')}:</b> {record.started_on}</div>
          ) : null}
          {record.completed_on ? (
               <div style={{display: "inline-block", width: width, paddingBottom: "6px", fontSize: "13px"}}><b>{translate('custom.tasks.completed_on')}:</b> {record.completed_on}</div>
          ) : null}
          </div>
     ) : null};

export const TaskShow = props => {

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     const [value, setValue] = React.useState(0);
     const translate = useTranslate();

     const handleChange = (event, newValue) => {
          setValue(newValue);
     };

     return (
          <>

               <Show {...props} title=" ">
                    { isSmall ? (
                         <div>
                              <TaskTitleField />
                              <div  className={classes.root}>
                              <AppBar position="static" color="default">
                                   <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        centered
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs"
                                   >
                                        <Tab label={translate('custom.tasks.tab_task_info')} />
                                        <Tab label={translate('custom.tasks.tab_task_images')} />
                                        <Tab label={translate('custom.tasks.tab_task_logs')} />
                                   </Tabs>
                              </AppBar>
                              </div>
                              <TabPanel value={value} index={0}>
                                   <div style={{float: "right", marginTop: "-2px", marginRight: "-15px"}}>
                                        <EditTaskStatusButton />
                                   </div>
                                   <TaskStatusField />
                                   <SimpleShowLayout>
                                        <div style={{marginTop: "50px", marginBottom: "-10px"}}>
                                             <TaskShowUserField />
                                        </div>
                                        <TextField source="task_title" label="" style={{fontSize: "30px"}} />
                                        <TaskDateFields />
                                        <ReferenceField link="show" source="project_id" label='resources.tasks.fields.project' reference="projects">
                                             <TextField style={{fontSize: "18px"}} source="project_name" />
                                        </ReferenceField>
                                        <TextField source="location" label='resources.tasks.fields.location' />
                                        <TextField source="description" label='resources.tasks.fields.description' />
                                        <TextField source="internal_notes" label='resources.tasks.fields.internal_notes' />
                                        <TextField source="remarks" label='resources.tasks.fields.remarks' />
                                        <TextField source="model" label='resources.tasks.fields.model' />



                                   </SimpleShowLayout>
                              </TabPanel>
                              <TabPanel value={value} index={1}>
                                   <TaskImageField />
                              </TabPanel>
                              <TabPanel value={value} index={2}>
                                   <div style={{position: "relative"}}>
                                        <div>
                                             <LogShow component_type="tasklogs" />
                                        </div>
                                   </div>
                              </TabPanel>
                         </div>
                    ) : (
                         <div>
                              <div>
                                   <div style={{display: "inline-block", position: "relative", width: "39%", marginRight: "1%", verticalAlign: "top"}}>
                                        <div style={{position: "absolute", top: "-2px", right: 0, marginRight: "-10px"}}>
                                             <EditTaskStatusButton />
                                        </div>
                                        <TaskStatusField />
                                        <SimpleShowLayout>
                                             <div style={{marginTop: "50px", marginBottom: "-10px"}}>
                                                  <TaskShowUserField />
                                             </div>
                                             <TextField source="task_title" label="" style={{fontSize: "30px"}} />
                                             <TaskDateFields />
                                             <ReferenceField link="show" source="project_id" label='resources.tasks.fields.project' reference="projects">
                                                  <TextField source="project_name" />
                                             </ReferenceField>
                                             <TextField source="location" label='resources.tasks.fields.location' />
                                             <TextField source="description" label='resources.tasks.fields.description' />
                                             <TextField source="internal_notes" label='resources.tasks.fields.internal_notes' />
                                             <TextField source="remarks" label='resources.tasks.fields.remarks' />
                                             <TextField source="model" label='resources.tasks.fields.model' />



                                        </SimpleShowLayout>
                                   </div>
                                   <div style={{display: "inline-block", position: "relative", borderLeft: "1px solid #CCC", minHeight: "100%", width: "58%", paddingLeft: "1%", verticalAlign: "top", paddingTop: "10px", paddingBottom: "10px"}}>
                                        <TaskImageField />
                                   </div>
                              </div>
                              <div style={{borderTop: "1px solid #DDD", margin: "0 15px 15px", paddingTop: "35px"}}>
                                   <div style={{position: "relative"}}>
                                        <div>
                                             <LogShow component_type="tasklogs" />
                                        </div>
                                   </div>
                              </div>
                         </div>

                    )}
               </Show>
          </>
)};

const TaskShowUserField = (props) => {

     const { source } = props;
     const record = useRecordContext(props);

     return record ? (
          <div style={{}}>
               {record.users ? record.users.map(memberitem => (
                    <div key={memberitem.id} style={{display: 'inline-block', margin: "0 2px", padding: 0, overflow: 'hidden'}}>
                         <UserQuickShow user_id={memberitem.user_id} image_height="30px" />
                    </div>
               )) : null}
          </div>

) : null};

const TaskListUserField = (props) => {

     const { source } = props;
     const record = useRecordContext(props);

     return record ? (
          <div style={{minWidth: "80px"}}>
               {(record.users && record.users[0]) ? (
                    <>
                         <div key={record.users[0].id} style={{display: 'inline-block', margin: "0 2px", padding: 0, overflow: 'hidden'}}>
                              <UserQuickShow user_id={record.users[0].user_id} image_height="30px" />
                         </div>
                         {(record.users.length > 1) ? (
                              <div key={record.users[0].id} style={{display: 'inline-block', margin: "0 2px", padding: 0, overflow: 'hidden'}}>
                                   <div style={{height: '30px', width: '30px', lineHeight: '30px', textAlign: 'center', borderRadius: '50%', backgroundColor: '#DDD', border: '1px solid #DDD', verticalAlign: 'middle'}}>+{(record.users.length-1)}</div>
                              </div>
                         ) : null}
                    </>
               ) : null}
          </div>

) : null};

const TaskFilter = (props) => {

     const translate = useTranslate();

     return (
          <Filter {...props}>
               <ReferenceInput source="project_id" reference="projects">
                    <SelectInput optionText="project_name" />
               </ReferenceInput>
               <ReferenceInput source="user_id" reference="users">
                    <SelectInput optionText="name" />
               </ReferenceInput>
               <SelectInput source="status" choices={[
                    { id: 'assigned', name: translate('custom.tasks.assigned') },
                    { id: 'started', name: translate('custom.tasks.started') },
                    { id: 'completed', name: translate('custom.tasks.completed') },
               ]} />
               <DateInput source="date_range_start" label="custom.general.date_range_start" />
               <DateInput source="date_range_end" label="custom.general.date_range_end" />
          </Filter>
)};

const TaskCalendar = props => {

     const translate = useTranslate();

     const { data, loading, error } = useQuery({
          type: 'getList',
          resource: 'tasks',
          payload: { pagination: { page: 1 , perPage: 1000 }, sort: { field: 'published_at', order: 'DESC' } }
     });

     const redirect = useRedirect();

     const calendarOnChange = React.useCallback(
          (val) => {
               var currentDate = new Date(val);
               var dateFormat = currentDate.getFullYear()+"-"+(currentDate.getMonth()+1)+"-"+currentDate.getDate();

               redirect(`/tasks?filter=${JSON.stringify({ date_range_start: dateFormat, date_range_end: dateFormat })}`);
          }
     );

     if(!loading)
     {
          const taskDates = new Array();

          if(data)
          {
               for(var i=0;i<data.length;i++)
               {
                    var dateItems = data[i].start_date.split("/");
                    taskDates.push(new Date(dateItems[2], (dateItems[1]-1), dateItems[0]));
               }
          }

          const calendarProps = {
               size: 300,
               fontSize: 16,
               viewDate: new Date(),
               showDualCalendar: true,
               highlights: taskDates,
          };

          return (
               <div style={{margin: "0 auto 20px"}}>
                    <Calendar {...calendarProps} onChange={calendarOnChange} />
                    <Button style={{width: 300}} component={Link} to={{ pathname: '/tasks'}}>{translate('custom.tasks.show_all')}</Button>
               </div>
          )
     }
     else
     {
          return null;
     }
};

export const TaskList = props => {

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();


     return (
          <>
               <TaskCalendar />
               <List {...props} title=" " filters={<TaskFilter />} bulkActionButtons={false} perPage="1000" exporter={false}>
                    {isSmall ? (
                         <div>
                          <SimpleList
                              linkType="show"
                              primaryText={record => <><div style={{textTransform: "capitalize", fontSize: "11px"}}>{record.status} - {record.start_date}</div><div>{record.task_title}</div></>}
                              secondaryText={record => record.project_name}
                              tertiaryText={record => (record.users && record.users[0]) ? (
                                        <>
                                             <div key={record.users[0].id} style={{display: 'inline-block', margin: "-30px 2px 0", padding: 0, overflow: 'hidden'}}>
                                                  <UserQuickShow user_id={record.users[0].user_id} image_height="40px" />
                                             </div>
                                             {(record.users.length > 1) ? (
                                                  <div key={record.users[0].id} style={{display: 'inline-block', margin: "-30px 2px 0", padding: 0, overflow: 'hidden'}}>
                                                       <div style={{height: '40px', width: '40px', lineHeight: '40px', textAlign: 'center', borderRadius: '50%', backgroundColor: '#DDD', border: '1px solid #DDD', verticalAlign: 'middle'}}>+{(record.users.length-1)}</div>
                                                  </div>
                                             ) : null}
                                        </>
                                   ) : null}
                           />
                          </div>
                      ) : (
                         <Datagrid rowClick="show">
                              <TextField source="task_title" />
                              <TextField source="project_name" />
                              <TaskListUserField />
                              <TextField source="status" style={{textTransform: "capitalize"}} />
                              <TextField source="start_date" />
                              <TextField source="deadline_date" />
                              <TextField source="updated_at" />
                              <ShowButton />
                         </Datagrid>
                    )}
               </List>
          </>
)};

const TaskUserInput = props => {
     const { source } = props;
     const record = useRecordContext(props);

     return record.project_id ? (
          <ReferenceArrayInput source="user_id" label='resources.tasks.fields.user' reference="users" fullWidth perPage="1000" filter={{ project_id: record.project_id }}>
               <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
     ) : (
          <ReferenceArrayInput source="user_id" label='resources.tasks.fields.user' reference="users" fullWidth perPage="1000">
               <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
     )
}

export const TaskEdit = props => {

     const translate = useTranslate();

     const location = useLocation();


     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     return (
     <div>
          {isSmall ? (
               <Edit undoable={false} title=" " {...props}>
                    <SimpleForm redirect="/tasks">
                         <ReferenceInput source="project_id" reference="projects" label='resources.tasks.fields.project' fullWidth perPage="1000">
                              <SelectInput optionText="project_name" />
                         </ReferenceInput>
                         <TaskUserInput />
                         <TextInput source="task_title" label='resources.tasks.fields.task_title' fullWidth  />
                         <TextInput source="description" label='resources.tasks.fields.description' multiline fullWidth  />
                         <TextInput source="location" label='resources.tasks.fields.location' multiline fullWidth  />
                         <DateInput source="start_date" label='resources.tasks.fields.start_date' fullWidth  />
                         <DateInput source="deadline_date" label='resources.tasks.fields.deadline_date' fullWidth  />
                         <TextInput source="internal_notes" label='resources.tasks.fields.internal_notes' multiline fullWidth  />
                         <TextInput source="remarks" label='resources.tasks.fields.remarks' multiline fullWidth  />
                         <TextInput source="model" label='resources.tasks.fields.model' multiline fullWidth  />
                    </SimpleForm>
               </Edit>
          ) : (
               <Edit undoable={false} title=" " {...props}>
                    <SimpleForm redirect="/tasks">
                         <div style={{width: "60%", margin: "0 auto"}}>
                              <ReferenceInput source="project_id" reference="projects" label='resources.tasks.fields.project' fullWidth perPage="1000">
                                   <SelectInput optionText="project_name" />
                              </ReferenceInput>
                              <TaskUserInput />
                              <TextInput source="task_title" label='resources.tasks.fields.task_title' fullWidth  />
                              <TextInput source="description" label='resources.tasks.fields.description' multiline fullWidth  />
                              <TextInput source="location" label='resources.tasks.fields.location' multiline fullWidth  />
                              <DateInput source="start_date" label='resources.tasks.fields.start_date' fullWidth  />
                              <DateInput source="deadline_date" label='resources.tasks.fields.deadline_date' fullWidth  />
                              <TextInput source="internal_notes" label='resources.tasks.fields.internal_notes' multiline fullWidth  />
                              <TextInput source="remarks" label='resources.tasks.fields.remarks' multiline fullWidth  />
                              <TextInput source="model" label='resources.tasks.fields.model' multiline fullWidth  />
                         </div>
                    </SimpleForm>
               </Edit>
          )}
     </div>
)};

export const TaskCreate = props => {

     const translate = useTranslate();

     const location = useLocation();
     const project_id =
          location.state && location.state.record
          ? location.state.record.project_id
          : undefined;


     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     return (
          <div>
               {(isSmall) ? (
                    <Create undoable={false} {...props}>
                         <SimpleForm redirect="/tasks">
                              <ReferenceInput source="project_id" reference="projects"  label='resources.tasks.fields.project' fullWidth>
                                   <SelectInput optionText="project_name" />
                              </ReferenceInput>
                              <ReferenceArrayInput source="user_id" reference="users"  label='resources.tasks.fields.user' fullWidth>
                                   <SelectArrayInput optionText="name" />
                              </ReferenceArrayInput>
                              <TextInput source="task_title" label='resources.tasks.fields.task_title' fullWidth  />
                              <TextInput source="description" label='resources.tasks.fields.description' multiline fullWidth  />
                              <TextInput source="location" label='resources.tasks.fields.location' multiline fullWidth  />
                              <DateInput source="start_date" label='resources.tasks.fields.start_date' fullWidth  />
                              <DateInput source="deadline_date" label='resources.tasks.fields.deadline_date' fullWidth  />
                              <TextInput source="internal_notes" label='resources.tasks.fields.internal_notes' multiline fullWidth  />
                              <TextInput source="remarks" label='resources.tasks.fields.remarks' multiline fullWidth  />
                              <TextInput source="model" label='resources.tasks.fields.model' multiline fullWidth  />
                         </SimpleForm>
                    </Create>
               ) : (
                    <Create undoable={false} {...props}>
                         <SimpleForm redirect="/tasks">
                              <div style={{width: "60%", margin: "0 auto"}}>
                                   <ReferenceInput source="project_id" reference="projects"  label='resources.tasks.fields.project' fullWidth>
                                        <SelectInput optionText="project_name" />
                                   </ReferenceInput>
                                   <ReferenceArrayInput source="user_id" reference="users"  label='resources.tasks.fields.user' fullWidth>
                                        <SelectArrayInput optionText="name" />
                                   </ReferenceArrayInput>
                                   <TextInput source="task_title" label='resources.tasks.fields.task_title' fullWidth  />
                                   <TextInput source="description" label='resources.tasks.fields.description' multiline fullWidth  />
                                   <TextInput source="location" label='resources.tasks.fields.location' multiline fullWidth  />
                                   <DateInput source="start_date" label='resources.tasks.fields.start_date' fullWidth  />
                                   <DateInput source="deadline_date" label='resources.tasks.fields.deadline_date' fullWidth  />
                                   <TextInput source="internal_notes" label='resources.tasks.fields.internal_notes' multiline fullWidth  />
                                   <TextInput source="remarks" label='resources.tasks.fields.remarks' multiline fullWidth  />
                                   <TextInput source="model" label='resources.tasks.fields.model' multiline fullWidth  />
                              </div>
                         </SimpleForm>
                    </Create>
               )}
          </div>
)};
