import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    SaveButton,
    TextInput,
    PasswordInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    ImageInput,
    ImageField,
    useQuery,
    useUpdate,
    useNotify,
    FormWithRedirect,
    useRefresh
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useTranslate } from 'react-admin';

function MyProfileQuickEdit() {

     const userId = localStorage.getItem('user_id');

     const { data, loadingData, errorData } = useQuery({
          type: 'getOne',
          resource: 'users',
          payload: { id: userId }
     });

     const [showDialog, setShowDialog] = useState(false);
     const [update, { loading, error }] = useUpdate();
     const notify = useNotify();

     const translate = useTranslate();

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     const handleClick = () => {
          setShowDialog(true);
     };

     const handleCloseClick = () => {
          setShowDialog(false);
     };

     const handleChange = useRefresh();

     const convertFileToBase64 = file =>
         new Promise((resolve, reject) => {
             const reader = new FileReader();
             reader.onload = () => resolve(reader.result);
             reader.onerror = reject;

             reader.readAsDataURL(file.rawFile);
         });



     const handleSubmit = async values => {
          if(data)
          {
               update('users', data.id, values, data,
               {
                    onSuccess: ({ data }) => {
                         setShowDialog(false);
                         handleChange();
                    },
                    onFailure: ({ error }) => {
                         notify(error.message, 'error');
                    }
               });
          }
     };

     return (
          <>
               <Button onClick={handleClick}>
                    <EditIcon /> <span>{translate('custom.users.edit_profile')}</span>
               </Button>
               <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Edit My Profile">
                    <DialogTitle>{translate('custom.users.edit_profile')}</DialogTitle>

                    <FormWithRedirect resource="users" record={data} save={handleSubmit}
                         render={({
                              handleSubmitWithRedirect,
                              pristine,
                              saving
                         }) => (
                              <>
                                   <DialogContent>
                                        <ImageInput source="pictures" label="resources.users.fields.profile_picture" accept="image/jpeg,image/png" placeholder={translate('resources.users.fields.profile_picture_description')} >
                                             <ImageField source="src" />
                                        </ImageInput>
                                        <TextInput source="name" label="resources.users.fields.name" fullWidth  />
                                        <TextInput source="username" label="resources.users.fields.username" fullWidth  />
                                        <TextInput source="email" label="resources.users.fields.email" fullWidth />
                                        <TextInput source="line_id" label="resources.users.fields.line_id" fullWidth />
                                        <TextInput source="phone" label="resources.users.fields.phone" fullWidth />
                                        <PasswordInput source="passwordreset" label="resources.users.fields.reset_password" value="" fullWidth />
                                   </DialogContent>
                                   <DialogActions>
                                        <Button onClick={handleCloseClick} disabled={loading}>
                                             <IconCancel /><span>{translate('ra.action.cancel')}</span>
                                        </Button>
                                        <SaveButton handleSubmitWithRedirect={handleSubmitWithRedirect} pristine={pristine} saving={saving} disabled={loading} />
                                   </DialogActions>
                              </>
                         )}
                    />
               </Dialog>
          </>
     );
}

export default MyProfileQuickEdit;
