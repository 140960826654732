import * as React from "react";
import { useRecordContext, Filter, Show, SimpleShowLayout,List, SimpleList, Datagrid, ReferenceField, BooleanField, ChipField, DateField, ImageField, TextField, EmailField, EditButton, ShowButton, Create, Edit, SimpleForm, BooleanInput, ImageInput, PasswordInput, ReferenceInput, SelectInput, TextInput, } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Toolbar } from '@material-ui/core';

import UserQuickShow from './UserQuickShow';
import MyProfileQuickEdit from './MyProfileQuickEdit';

import { useTranslate } from 'react-admin';

import { Redirect } from 'react-router';

import config from './config';

const accessRights = localStorage.getItem('access_rights');

const useStyles = makeStyles({
     profilePicture: {
          paddingTop: "5px",
          '& img': {
               maxHeight: "50px",
               margin: 0,
               border: '2px solid #bbb',
               borderRadius: '50px',
               backgroundColor: '#EEE',
          },
     },
     editProfilePicture: {
          '& img': {
               border: '2px solid #bbb',
               borderRadius: '50%',
               backgroundColor: '#EEE',
          },
     },
});

const UserQuickShowInput = (props) => {
     const { source } = props;
     const record = useRecordContext(props);

     return record ? (
          <UserQuickShow user_id={record.id} image_height="50px" show_type="showbutton" show_edit={true} />
     ) : null
};

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name" />
    </Filter>
);

export const UserList = props => {

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();
     const translate = useTranslate();

     return (
          <>
               <div style={{textAlign: "right"}}>
                    <MyProfileQuickEdit />
               </div>
               <List {...props} title=" " filters={<UserFilter />} bulkActionButtons={false} perPage="1000" exporter={false}>
                    {isSmall ? (
                         <div>
                               <SimpleList
                                   linkType="show"
                                   leftAvatar={record => <img src={record.profile_picture} style={{maxHeight: "100%"}} />}
                                   primaryText={record => record.name}
                                   secondaryText={record => record.email}
                                   tertiaryText={record => (record.active) ? translate('custom.users.active') : translate('custom.users.inactive') }
                               >

                               </SimpleList>
                          </div>
                      ) : (
                         <Datagrid>
                              <ImageField source="profile_picture" className={classes.profilePicture} />
                              <TextField source="name" />
                              <EmailField source="email" />
                              <TextField source="phone" />
                              <ReferenceField source="user_group" reference="usergroups">
                                   <ChipField source="label" />
                              </ReferenceField>
                              <BooleanField source="active" />
                              <UserQuickShowInput />
                         </Datagrid>
                    )}
               </List>
          </>
)};

const UserProfileCard = (props) => {

     const { source } = props;
     const record = useRecordContext(props);
     const translate = useTranslate();

     const phoneUrl = (record && record.phone) ? `tel:${record.phone}` : null;
     const lineUrl = (record && record.line_id) ? `https://line.me/R/${record.line_id}` : null;

     return record ? (
          <div style={{padding: "0 10px", marginBottom: "15px"}}>
               <div style={{maxWidth: "400px", margin: "0 auto", overflow: "hidden", padding: "15px", borderRadius: "10px", boxShadow: "0 0 5px 2px #aaa", background: "#FAFAFA"}}>
                    <div style={{fontSize: "17px", background: config.appbar_background, margin: "-16px -16px 20px", padding: "8px", textAlign: "center", color: "#FFF", textTransform: "uppercase"}}>User Details</div>
                    <div style={{textAlign: "center"}}>
                         <img src={record.profile_picture} style={{maxWidth: "150px", borderRadius: "50%", marginBottom: "10px"}} />
                    </div>
                    <div style={{fontSize: "25px", marginBottom: "16px", textAlign: "center"}}>{record.name}</div>
                    <div style={{fontSize: "16px", marginBottom: "10px"}}>
                         <b>{translate('resources.users.fields.user_group')}:</b>
                         <span style={{float: "right"}}>{record.user_goup_label}</span>
                    </div>
                    <div style={{fontSize: "16px", marginBottom: "10px"}}>
                         <b>{translate('resources.users.fields.email')}:</b>
                         <span style={{float: "right"}}>{record.email}</span>
                    </div>
                    <div style={{fontSize: "16px", marginBottom: "10px"}}>
                         <b>{translate('resources.users.fields.phone')}:</b>
                         { record.phone ? (
                              <span style={{float: "right"}}><a href={phoneUrl}>{record.phone}</a></span>
                         ) : (
                              <span style={{float: "right"}}> - </span>
                         )}
                    </div>
                    <div style={{fontSize: "16px", marginBottom: "10px"}}>
                         <b>{translate('resources.users.fields.line_id')}:</b>
                         { record.line_id ? (
                              <span style={{float: "right"}}><a href={lineUrl}>{record.line_id}</a></span>
                         ) : (
                              <span style={{float: "right"}}> - </span>
                         )}
                    </div>
                    <div style={{fontSize: "16px", marginBottom: "10px"}}>
                         <b>{translate('resources.users.fields.created_at')}:</b>
                         <span style={{float: "right"}}>{record.created_at}</span>
                    </div>
               </div>
          </div>
     ) : null;
};

export const UserShow = props => {

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     return (
          <>
               <Show {...props} title=" " component="div">
                    { isSmall ? (
                         <UserProfileCard />
                    ) : (
                         <UserProfileCard />
                    )}
               </Show>
          </>
)};

const EditTitle = ({ record }) => {
     return <span>User: {record ? `${record.name}` : ''}</span>;
};

const Aside = ({ record }) => {
     const classes = useStyles();

     return(
     <div style={{ width: 200, margin: '1em' }}>
          {record && (
               <div style={{textAlign: "center"}}>
                    <ImageField source="profile_picture" label="" className={classes.editProfilePicture}  />
                    <Typography variant="h6">
                         { record.name }
                    </Typography>
               </div>
          )}
     </div>
)};

const AccessRightsCheck = (props) => {
     const { source } = props;
     const record = useRecordContext(props);

     if(accessRights == "admin" && (record.user_goup_label == "Super Admin" || record.user_goup_label == "Admin"))
     {
          return (<Redirect to="/users" />);
     }
     else
     {
          return null;
     }
}

export const UserEdit = props => {
     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     const translate = useTranslate();

     return (
     <div>
          {isSmall ? (
               <Edit undoable={false} title=" " {...props}>
                    <SimpleForm redirect="/users">
                         <AccessRightsCheck />
                         <ImageInput source="pictures" label="resources.users.fields.profile_picture" accept="image/jpeg,image/png" placeholder={translate('resources.users.fields.profile_picture_description')} >
                              <ImageField source="src" />
                         </ImageInput>
                         <ReferenceInput source="user_group" label="resources.users.fields.user_group" reference="usergroups" fullWidth>
                              <SelectInput optionText="label" />
                         </ReferenceInput>
                         <TextInput source="name" label="resources.users.fields.name" fullWidth  />
                         <TextInput source="username" label="resources.users.fields.username" fullWidth  />
                         <TextInput source="email" label="resources.users.fields.email" fullWidth />
                         <TextInput source="line_id" label="resources.users.fields.line_id" fullWidth />
                         <TextInput source="phone" label="resources.users.fields.phone" fullWidth />
                         <PasswordInput source="passwordreset" label="resources.users.fields.reset_password" value="" fullWidth />
                         <BooleanInput label="Active" label="resources.users.fields.active" source="active" />
                    </SimpleForm>
               </Edit>
          ) : (
               <Edit undoable={false} title={<EditTitle />} aside={<Aside />} {...props}>
                    <SimpleForm redirect="/users">
                         <AccessRightsCheck />
                         <div style={{width: "60%", margin: "0 auto"}}>
                              <ImageInput source="pictures" label="resources.users.fields.profile_picture" accept="image/jpeg,image/png" placeholder={translate('resources.users.fields.profile_picture_description')} >
                                   <ImageField source="src" />
                              </ImageInput>
                              <ReferenceInput source="user_group" label="resources.users.fields.user_group" reference="usergroups" fullWidth>
                                   <SelectInput optionText="label" />
                              </ReferenceInput>
                              <TextInput source="name" label="resources.users.fields.name" fullWidth  />
                              <TextInput source="username" label="resources.users.fields.username" fullWidth  />
                              <TextInput source="email" label="resources.users.fields.email" fullWidth />
                              <TextInput source="line_id" label="resources.users.fields.line_id" fullWidth />
                              <TextInput source="phone" label="resources.users.fields.phone" fullWidth />
                              <PasswordInput source="passwordreset" label="resources.users.fields.reset_password" value="" fullWidth />
                              <BooleanInput label="Active" label="resources.users.fields.active" source="active" />
                         </div>
                    </SimpleForm>
               </Edit>
          )}
     </div>
)};


export const UserCreate = props => {
     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     const translate = useTranslate();

     return (
          <div>
               {(isSmall) ? (
                    <Create title=" " undoable={false} {...props}>
                         <SimpleForm redirect="/users">
                              <ImageInput source="pictures" label="resources.users.fields.profile_picture" accept="image/jpeg,image/png" placeholder={translate('resources.users.fields.profile_picture_description')} >
                                   <ImageField source="src" />
                              </ImageInput>
                              <ReferenceInput source="user_group" label="resources.users.fields.user_group" reference="usergroups" fullWidth>
                                   <SelectInput optionText="label" />
                              </ReferenceInput>
                              <TextInput source="name" label="resources.users.fields.name" fullWidth  />
                              <TextInput source="username" label="resources.users.fields.username" fullWidth  />
                              <TextInput source="email" label="resources.users.fields.email" fullWidth />
                              <TextInput source="line_id" label="resources.users.fields.line_id" fullWidth />
                              <TextInput source="phone" label="resources.users.fields.phone" fullWidth />
                              <PasswordInput source="password" label="resources.users.fields.set_password" value="" fullWidth />
                              <BooleanInput label="Active" label="resources.users.fields.active" source="active" />
                         </SimpleForm>
                    </Create>
               ) : (
                    <Create title=" " undoable={false} {...props}>
                         <SimpleForm redirect="/users">
                              <div style={{width: "60%", margin: "0 auto"}}>
                                   <ImageInput source="pictures" label="resources.users.fields.profile_picture" accept="image/jpeg,image/png" placeholder={translate('resources.users.fields.profile_picture_description')} >
                                        <ImageField source="src" />
                                   </ImageInput>
                                   <ReferenceInput source="user_group" label="resources.users.fields.user_group" reference="usergroups" fullWidth>
                                        <SelectInput optionText="label" />
                                   </ReferenceInput>
                                   <TextInput source="name" label="resources.users.fields.name" fullWidth  />
                                   <TextInput source="username" label="resources.users.fields.username" fullWidth  />
                                   <TextInput source="email" label="resources.users.fields.email" fullWidth />
                                   <TextInput source="line_id" label="resources.users.fields.line_id" fullWidth />
                                   <TextInput source="phone" label="resources.users.fields.phone" fullWidth />
                                   <PasswordInput source="password" label="resources.users.fields.set_password" value="" fullWidth />
                                   <BooleanInput label="Active" label="resources.users.fields.active" source="active" />
                              </div>
                         </SimpleForm>
                    </Create>
               )}
          </div>
)};
