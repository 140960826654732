import { fetchUtils, Admin, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

import config from './config';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('api_token');

    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(config.api_server, httpClient);


/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

const myDataProvider = {
    ...dataProvider,
    update: (resource, params) => {
        if ((resource !== 'users' && resource !== 'clients' && resource !== 'tasks' && resource !== 'reports') || !params.data.pictures) {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }
        /**
         * For posts update only, convert uploaded image in base 64 and attach it to
         * the `picture` sent property, with `src` and `title` attributes.
         */

         if(params.data.pictures)
         {
              if(Array.isArray(params.data.pictures))
              {
                   // Freshly dropped pictures are File objects and must be converted to base64 strings
                  const newPictures = params.data.pictures.filter(
                      p => p.rawFile instanceof File
                  );
                  const formerPictures = params.data.pictures.filter(
                      p => !(p.rawFile instanceof File)
                  );

                  return Promise.all(newPictures.map(convertFileToBase64))
                      .then(base64Pictures =>
                          base64Pictures.map(picture64 => ({
                              src: picture64,
                              title: `${params.data.title}`,
                          }))
                      )
                      .then(transformedNewPictures =>
                          dataProvider.update(resource, {
                              ...params,
                              data: {
                                  ...params.data,
                                  pictures: [
                                      ...transformedNewPictures,
                                      ...formerPictures,
                                  ],
                              },
                          })
                      );
              }
              else
              {
                   const myFile = params.data.pictures;
                   if ( !myFile.rawFile instanceof File ){
                        return Promise.reject('Error: Not a file...'); // Didn't test this...
                   }

                   return Promise.resolve( convertFileToBase64(myFile) )
                   .then( (picture64) => ({
                        src: picture64,
                        title: `${myFile.title}`
                   }))
                   .then( transformedMyFile => dataProvider.update(resource, {
                        ...params,
                        data: {
                             ...params.data,
                             pictures: transformedMyFile
                        }
                   }));
              }
         }

    },
    create: (resource, params) => {
        if ((resource !== 'users' && resource !== 'clients' && resource !== 'tasks' && resource !== 'reports') || !params.data.pictures) {
            // fallback to the default implementation
            return dataProvider.create(resource, params);
        }
        /**
         * For posts update only, convert uploaded image in base 64 and attach it to
         * the `picture` sent property, with `src` and `title` attributes.
         */

         if(params.data.pictures)
         {
              if(Array.isArray(params.data.pictures))
              {
                   // Freshly dropped pictures are File objects and must be converted to base64 strings
                  const newPictures = params.data.pictures.filter(
                      p => p.rawFile instanceof File
                  );
                  const formerPictures = params.data.pictures.filter(
                      p => !(p.rawFile instanceof File)
                  );

                  return Promise.all(newPictures.map(convertFileToBase64))
                      .then(base64Pictures =>
                          base64Pictures.map(picture64 => ({
                              src: picture64,
                              title: `${params.data.title}`,
                          }))
                      )
                      .then(transformedNewPictures =>
                          dataProvider.create(resource, {
                              ...params,
                              data: {
                                  ...params.data,
                                  pictures: [
                                      ...transformedNewPictures,
                                      ...formerPictures,
                                  ],
                              },
                          })
                      );
              }
              else
              {
                   const myFile = params.data.pictures;
                   if ( !myFile.rawFile instanceof File ){
                        return Promise.reject('Error: Not a file...'); // Didn't test this...
                   }

                   return Promise.resolve( convertFileToBase64(myFile) )
                   .then( (picture64) => ({
                        src: picture64,
                        title: `${myFile.title}`
                   }))
                   .then( transformedMyFile => dataProvider.create(resource, {
                        ...params,
                        data: {
                             ...params.data,
                             pictures: transformedMyFile
                        }
                   }));
              }
         }

    },
};

export default myDataProvider;
