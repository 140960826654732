import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    SaveButton,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    DateInput,
    useCreate,
    useNotify,
    FormWithRedirect,
    useRefresh
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import { useTranslate } from 'react-admin';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

function ReportItemQuickCreate({ report_id }) {
     const [showDialog, setShowDialog] = useState(false);
     const [create, { loading }] = useCreate('reportitems');
     const notify = useNotify();
     const translate = useTranslate();

     const handleClick = () => {
          setShowDialog(true);
     };

     const handleCloseClick = () => {
          setShowDialog(false);
     };

     const handleChange = useRefresh();

     const handleSubmit = async values => {
          values.report_id = report_id;
          create(
               { payload: { data: values } },
               {
                         onSuccess: ({ data }) => {
                              setShowDialog(false);
                              handleChange();
                    },
                    onFailure: ({ error }) => {
                         notify(error.message, 'error');
                    }
               }
          );
     };

     return (
          <>
               <Button onClick={handleClick}>
                    <IconContentAdd /> <span>{translate('custom.reports.add_task_items')}</span>
               </Button>
               <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={handleCloseClick}
                    aria-label="Add Task Items"
               >
                    <DialogTitle>{translate('custom.reports.add_task_items')}</DialogTitle>

                    <FormWithRedirect
                         resource="reportitems"
                         save={handleSubmit}
                         render={({
                              handleSubmitWithRedirect,
                              pristine,
                              saving
                         }) => (
                              <>
                              <DialogContent>
                                   <DateInput source="start_range" label='custom.general.date_range_start' fullWidth />
                                   <DateInput source="end_range" label='custom.general.date_range_end' fullWidth />
                              </DialogContent>
                              <DialogActions>
                                   <Button onClick={handleCloseClick} disabled={loading}>
                                        <IconCancel /><span>{translate('custom.general.cancel')}</span>
                                   </Button>
                                   <SaveButton
                                        handleSubmitWithRedirect={
                                             handleSubmitWithRedirect
                                        }
                                        pristine={pristine}
                                        saving={saving}
                                        disabled={loading}
                                   />
                              </DialogActions>
                         </>
                    )}
                    />
               </Dialog>
          </>
     );
}

export default ReportItemQuickCreate;
