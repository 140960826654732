import React, { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import {
     required,
     SaveButton,
     TextInput,
     ReferenceInput,
     SelectInput,
     BooleanInput,
     FileInput,
     FileField,
     DateInput,
     ImageInput,
     ImageField,
     useCreate,
     useNotify,
     useQuery,
     FormWithRedirect,
     useRefresh
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import { useTranslate } from 'react-admin';

function ReportQuickCreate({ task_id, project_id }) {
     const [showDialog, setShowDialog] = useState(false);
     const [create, { loading }] = useCreate("reports");
     const notify = useNotify();
     const translate = useTranslate();

     const [state, setState] = React.useState({
          checkedType: false,
     });

     const handleSwitchChange = (event) => {
          setState({ ...state, [event.target.name]: event.target.checked });
     };

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     const [selectedImages, setSelectedImages] = useState(new Array());

     const { data, taskLoadingData, taskErrorData } = useQuery({
          type: 'getOne',
          resource: 'tasks',
          payload: { id: task_id }
     });

     const toggleSelectedImage = (e, attachmentId) => {

          let currentSelectedImages = selectedImages;

          if(currentSelectedImages.indexOf(attachmentId) != -1)
          {

               setSelectedImages(currentSelectedImages.filter(item => item !== attachmentId));
          }
          else
          {
               if(currentSelectedImages.length < 4)
               {
                    setSelectedImages(currentSelectedImages => [...currentSelectedImages, attachmentId]);
               }
               else
               {
                    notify(translate('custom.reports.image_select_error'), 'error');
               }
          }
     }

     const handleClick = () => {
          setShowDialog(true);
     };

     const handleCloseClick = () => {
          setShowDialog(false);
     };

     const handleChange = useRefresh();

     const convertFileToBase64 = file =>
          new Promise((resolve, reject) => {
               const reader = new FileReader();
               reader.onload = () => resolve(reader.result);
               reader.onerror = reject;

               reader.readAsDataURL(file.rawFile);
     });

     const ReportLogos = props => {
          const { values } = useFormState();

          return (values.logos && (values.logos == "contractor" || values.logos == "standard_contractor")) ? (
               <ImageInput source="pictures" label="resources.reports.fields.contractor_logo" accept="image/jpeg,image/png" >
                    <ImageField source="src" />
               </ImageInput>
          ) : null;
     }

     const handleSubmit = async values => {

          values.project_id = project_id;
          values.task_id = task_id;
          values.task_images = selectedImages;


          if(values.files)
          {
               if(Array.isArray(values.files))
               {
                    const newFiles = values.files.filter(
                         p => p.rawFile instanceof File
                    );

                    const formerFiles = values.files.filter(
                         p => !(p.rawFile instanceof File)
                    );

                    return Promise.all(newFiles.map(convertFileToBase64))
                    .then(base64Files =>
                         base64Files.map(file64 => ({
                              src: file64,
                              title: `${values.title}`,
                         }))
                    )
                    .then(transformedNewFiles => {
                         console.log(transformedNewFiles);
                         create({ payload: { data: {
                              ...values,
                              uploaded_files: [
                                   ...transformedNewFiles,
                                   ...formerFiles,
                              ],
                         } } },
                         {
                              onSuccess: ({ data }) => {
                                   setShowDialog(false);
                                   handleChange();
                              },
                              onFailure: ({ error }) => {
                                   notify(error.message, 'error');
                              }
                         }
                    )});
               }
               else
               {
                    const myFile = values.files;
                    if ( !myFile.rawFile instanceof File ){
                         return Promise.reject('Error: Not a file...'); // Didn't test this...
                    }

                    return Promise.resolve( convertFileToBase64(myFile) )
                    .then( (file64) => ({
                         src: file64,
                         title: `${myFile.title}`
                    }))
                    .then( transformedMyFile => {

                              console.log(transformedMyFile);
                                   create({ payload: { data: {
                                        ...values,
                                        uploaded_files: transformedMyFile
                                   } } },
                                   {
                                        onSuccess: ({ data }) => {
                                             setShowDialog(false);
                                             handleChange();
                                        },
                                        onFailure: ({ error }) => {
                                             notify(error.message, 'error');
                                        }
                                   }
                              )
                         }
                    )
               }
          }
          else
          {
               create(
                    { payload: { data: values } },
                    {
                         onSuccess: ({ data }) => {
                              setShowDialog(false);
                              handleChange();
                         },
                         onFailure: ({ error }) => {
                              notify(error.message, 'error');
                         }
                    }
               );
          }
     };

     return (
          <>
               <Button onClick={handleClick} style={{color: "#3f51b5"}}>
                    <IconContentAdd /> <span>{translate('custom.reports.create_report')}</span>
               </Button>
               <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create Report">
                    <DialogTitle>{translate('custom.reports.create_report')}</DialogTitle>

                    <FormWithRedirect record={data} resource="reports" save={handleSubmit}
                         render={({
                              handleSubmitWithRedirect,
                              pristine,
                              saving
                         }) => (
                              <>
                                   <DialogContent>
                                        <Grid component="label" container alignItems="center" spacing={1} style={{textAlign: "center", margin: "0 auto"}}>
                                             <Grid item>{translate('custom.reports.switch_create_report')}</Grid>
                                             <Grid item>
                                                  <Switch
                                                       checked={state.checkedType}
                                                       onChange={handleSwitchChange}
                                                       color="primary"
                                                       name="checkedType"
                                                       inputProps={{ 'aria-label': 'primary checkbox' }}
                                                  />
                                             </Grid>
                                             <Grid item>{translate('custom.reports.switch_add_to_report')}</Grid>
                                        </Grid>
                                        <hr />
                                        {(!state.checkedType) ? (
                                             <>
                                                  <div>
                                                       <DateInput fullWidth source="date" label='resources.reports.fields.date' />
                                                  </div>
                                                  <div>
                                                       <SelectInput fullWidth source="type"  label='resources.reports.fields.type' choices={[
                                                            { id: 'photo-report', name: 'Photo Report' },
                                                            { id: 'defect-report', name: 'Defect Report' },
                                                       ]} />
                                                  </div>
                                                  <div>
                                                       <SelectInput fullWidth source="signatures" label='resources.reports.fields.signatures' choices={[
                                                            { id: 'standard', name: translate('custom.reports.standard') },
                                                            { id: 'consultant', name: translate('custom.reports.standard_consultant') },
                                                            { id: 'contractor', name: translate('custom.reports.standard_contractor') },
                                                            { id: 'full', name: translate('custom.reports.full') },
                                                       ]} />
                                                  </div>
                                                  <div>
                                                       <SelectInput fullWidth source="logos" label='resources.reports.fields.logos' choices={[
                                                            { id: 'standard', name: translate('custom.reports.standard_logo') },
                                                            { id: 'contractor', name: translate('custom.reports.contractor_logo') },
                                                            { id: 'standard_contractor', name: translate('custom.reports.standard_contractor_logo') },
                                                       ]} />
                                                  </div>
                                                  <div>
                                                       <ReportLogos />
                                                  </div>
                                             </>
                                        ) : (
                                             <ReferenceInput source="report_id" reference="reports" filter={{project_id: project_id}} fullWidth>
                                                  <SelectInput optionText="report_id" />
                                             </ReferenceInput>
                                        )}

                                        <hr />

                                        <TextInput multiline source="description" label='custom.reports.description' fullWidth />
                                        <TextInput multiline source="location" label='custom.reports.location' fullWidth />
                                        <TextInput multiline source="remarks" label='custom.reports.remarks' fullWidth />

                                        <div style={{marginTop: "10px", marginBottom: "5px", fontSize: "13px", fontWeight: "bold"}}>{translate('custom.reports.image_select_text')}</div>

                                        <div style={{textAlign: "center"}}>
                                             { (data && data.attachments) ? data.attachments.map(attachment => (attachment.id) ? (
                                                  <div key={attachment.id} onClick={((e) => toggleSelectedImage(e, attachment.id))} style={{cursor: "pointer", background: "#EEE", display: "inline-block", overflow: "hidden", border: "2px solid #CCC", width: "29%", margin: "5px 1%", position: "relative"}}>
                                                       { (selectedImages.indexOf(attachment.id) != -1) ? (
                                                            <div style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0,0,0,0.4)", textAlign: "center", color: "#0F0"}}>
                                                                 <CheckCircleIcon style={{fontSize: "60px", marginTop: "calc(50% - 30px)"}} />
                                                            </div>
                                                       ) : null }
                                                       <img src={attachment.thumb} style={{maxWidth: "100%", marginBottom: "-7px"}} />
                                                  </div>
                                             ) : null) : null}
                                        </div>

                                   </DialogContent>
                                   <DialogActions>
                                        <Button onClick={handleCloseClick} disabled={loading}>
                                             <IconCancel /><span>{translate('custom.general.cancel')}</span>
                                        </Button>
                                        <SaveButton handleSubmitWithRedirect={handleSubmitWithRedirect} pristine={pristine} saving={saving} disabled={loading} />
                                   </DialogActions>
                              </>
                         )}
                    />
               </Dialog>
          </>
     );
}

export default ReportQuickCreate;
