import config from './config';

export default {
     // called when the user attempts to log in
     login: ({ username, password }) => {

          const data = { username: username, password: password };

          return fetch(`${config.api_server}/auth-user`, {
               method: 'POST',
               mode: 'cors',
               cache: 'no-cache',
               headers: {
                    'Content-Type': 'application/json'
               },
               redirect: 'follow',
               referrerPolicy: 'no-referrer',
               body: JSON.stringify(data)
          }).then(response => response.json())
          .then(responsedata => {

               if(responsedata.response == "true")
               {
                    localStorage.setItem('api_token', responsedata.api_token);
                    localStorage.setItem('user_id', responsedata.id);
                    localStorage.setItem('profile_picture', responsedata.profile_picture);
                    localStorage.setItem('access_rights', responsedata.group_name);
               }
               else
               {

               }

               return responsedata;
          });



     },
     // called when the user clicks on the logout button
     logout: () => {
          localStorage.removeItem('api_token');
          localStorage.removeItem('user_id');
          localStorage.removeItem('profile_picture');
          localStorage.removeItem('access_rights');
          localStorage.removeItem('login_refresh');
          return Promise.resolve();
     },
     // called when the API returns an error
     checkError: ({ status }) => {
          if (status === 401 || status === 403) {
               localStorage.removeItem('api_token');
               localStorage.removeItem('user_id');
               localStorage.removeItem('profile_picture');
               localStorage.removeItem('access_rights');
               localStorage.removeItem('login_refresh');
               return Promise.reject();
          }
          return Promise.resolve();
     },
     // called when the user navigates to a new location, to check for authentication
     checkAuth: () => {

          if(localStorage.getItem('api_token'))
          {
               const api_token = localStorage.getItem('api_token');

               return fetch(`${config.api_server}/user?api_token=${api_token}`, {
                    method: 'GET',
               }).then(response => response.json())
               .then(responsedata => {

                    if(responsedata.active == 1)
                    {
                         if(!localStorage.getItem('access_rights'))
                         {
                              localStorage.setItem('access_rights', responsedata.group_name);
                         }

                         if(!localStorage.getItem('login_refresh'))
                         {
                              localStorage.setItem('login_refresh', '1');
                              window.location.reload();
                         }


                         return Promise.resolve();
                    }
                    else
                    {
                         return Promise.reject();
                    }
               });

          }
          else
          {
               return Promise.reject();
          }
     },
     // called when the user navigates to a new location, to check for permissions / roles
     getPermissions: () => Promise.resolve(),
};
