import React, { useState, useCallback } from "react";
import PropTypes from 'prop-types';
import { useInput, required } from 'react-admin';
import { useForm, useFormState } from 'react-final-form';
import { useRecordContext, AutocompleteInput, Filter, Button, Show, SimpleShowLayout, TopToolbar, List, SimpleList, Datagrid, ArrayField, DateInput, ReferenceField, ReferenceManyField, SingleFieldList, BooleanField, ChipField, DateField, UrlField, ImageField, TextField, EmailField, EditButton, DeleteButton, ShowButton, CreateButton, Create, Edit, SimpleForm, BooleanInput, ImageInput, PasswordInput, ReferenceInput, SelectInput, TextInput, ReferenceArrayInput, SelectArrayInput, } from 'react-admin';
import { useMediaQuery, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Toolbar } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RoomIcon from '@material-ui/icons/Room';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import StarIcon from '@material-ui/icons/Star';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import GetAppIcon from '@material-ui/icons/GetApp';

import { useTranslate } from 'react-admin';

import ReportItemQuickCreate from './ReportItemQuickCreate';
import ReportCustomItemQuickCreate from './ReportCustomItemQuickCreate';
import ReportTaskItemQuickCreate from './ReportTaskItemQuickCreate';

import config from './config';

const useStyles = makeStyles({
     profilePicture: {
          paddingTop: "5px",
          '& img': {
               maxHeight: "50px",
               margin: 0,
               border: '2px solid #bbb',
               borderRadius: '50px',
               backgroundColor: '#EEE',
          },
     },
     editProfilePicture: {
          '& img': {
               border: '2px solid #bbb',
               borderRadius: '50%',
               backgroundColor: '#EEE',
          },
     },
     root: {
          flexGrow: 1,
     },
});

const ReportPhotoLayout = (props) => {

     const { source } = props;
     const record = useRecordContext(props);
     const redirect = record ? `/reports/${record.id}/show` : false;
     const translate = useTranslate();

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     const logoHeight = isSmall ? "25px" : "60px";

     return (
          <div>
               {(record.project_id != null && record.project) ? (
                    <div>
                         <div style={{borderBottom: "2px solid #ccc", paddingBottom: "10px"}}>
                              <div style={{display: "inline-block", width: "49%"}}>
                                   {(record.client) ? (
                                        <img src={record.client.logo} style={{maxWidth: "100%", maxHeight: logoHeight}} />
                                   ) : null}
                              </div>
                              <div style={{display: "inline-block", width: "49%", textAlign: "right"}}>
                                   {(record.logos == "contractor" || record.logos == "standard_contractor") ? (
                                        <img src={record.contractor_logo} style={{maxWidth: "100%", maxHeight: logoHeight, marginLeft: "10px", marginRight: "10px"}} />
                                   ) : null}
                                   {(record.logos == "standard" || record.logos == "standard_contractor") ? (
                                        <img src={record.logo} style={{maxWidth: "100%", maxHeight: logoHeight, marginLeft: "10px", marginRight: "10px"}} />
                                   ) : null}
                              </div>
                         </div>
                         <div style={{borderBottom: "2px solid #ccc", paddingTop: "2px", paddingBottom: "4px", textAlign: "center", textTransform: "uppercase", fontWeight: "bold"}}>
                              {record.type}
                         </div>
                         { isSmall ? (
                              <div style={{borderBottom: "2px solid #ccc", padding: "8px 8px 10px"}}>
                                   <div style={{display: "inline-block", width: "70%", verticalAlign: "top"}}>
                                        <div style={{textTransform: "uppercase", fontWeight: "bold"}}>
                                             Project:&nbsp;
                                        </div>
                                        <div>
                                             {record.project.project_name}
                                        </div>
                                   </div>
                                   <div style={{display: "inline-block", width: "30%", verticalAlign: "top", textAlign: "right"}}>
                                        <div style={{fontWeight: "bold"}}>
                                             Date
                                        </div>
                                        <div>
                                             {record.date_format}
                                        </div>
                                   </div>
                              </div>
                         ) : (
                              <div style={{borderBottom: "2px solid #ccc", padding: "8px 8px 10px"}}>
                                   <div style={{display: "inline-block", width: "80%", verticalAlign: "top"}}>
                                        <div style={{display: "inline-block", verticalAlign: "top", textTransform: "uppercase", fontWeight: "bold"}}>
                                             Project:&nbsp;
                                        </div>
                                        <div style={{display: "inline-block", verticalAlign: "top"}}>
                                             {record.project.project_name}
                                        </div>
                                   </div>
                                   <div style={{display: "inline-block", width: "20%", verticalAlign: "top", textAlign: "right"}}>
                                        <b>Date:</b> {record.date_format}
                                   </div>
                              </div>
                         )}
                    </div>
               ) : (
                    <div>
                         <div style={{borderBottom: "2px solid #ccc", paddingBottom: "10px"}}>
                              <div style={{display: "inline-block", width: "49%"}}>
                                   {(record.client) ? (
                                        <img src={record.client.logo} style={{maxWidth: "100%", maxHeight: logoHeight}} />
                                   ) : null}
                              </div>
                              <div style={{display: "inline-block", width: "49%", textAlign: "right"}}>
                                   <img src={record.logo} style={{maxWidth: "100%", maxHeight: logoHeight}} />
                              </div>
                         </div>
                         <div style={{borderBottom: "2px solid #ccc", paddingTop: "2px", paddingBottom: "4px", textAlign: "center", textTransform: "uppercase", fontWeight: "bold"}}>
                              {record.type}
                         </div>
                         { isSmall ? (
                              <div style={{borderBottom: "2px solid #ccc", padding: "8px 8px 10px"}}>
                                   <div style={{display: "inline-block", width: "70%", verticalAlign: "top"}}>
                                        <div style={{textTransform: "uppercase", fontWeight: "bold"}}>
                                             Project:&nbsp;
                                        </div>
                                        <div>
                                        {record.project ? (
                                             <>
                                                  {record.project.project_name}
                                             </>
                                        ) : null}
                                        </div>
                                   </div>
                                   <div style={{display: "inline-block", width: "30%", verticalAlign: "top", textAlign: "right"}}>
                                        <div style={{fontWeight: "bold"}}>
                                             Date
                                        </div>
                                        <div>
                                             {record.date_format}
                                        </div>
                                   </div>
                              </div>
                         ) : (
                              <div style={{borderBottom: "2px solid #ccc", padding: "8px 8px 10px"}}>
                                   <div style={{display: "inline-block", width: "80%", verticalAlign: "top"}}>
                                        <div style={{display: "inline-block", verticalAlign: "top", textTransform: "uppercase", fontWeight: "bold"}}>
                                             Project:&nbsp;
                                        </div>
                                        <div style={{display: "inline-block", verticalAlign: "top"}}>
                                             {record.client ? (
                                                  <>
                                                       {record.client.name}
                                                  </>
                                             ) : null}
                                        </div>
                                   </div>
                                   <div style={{display: "inline-block", width: "20%", verticalAlign: "top", textAlign: "right"}}>
                                        <b>Date:</b> {record.date_format}
                                   </div>
                              </div>
                         )}
                    </div>
               )}

               <div>
                    <>
                         {record.report_items ? (
                              <>
                                   {record.report_items.map(report_item => report_item && report_item.id ? (
                                        <>
                                             { isSmall ? (
                                                  <div style={{borderBottom: "1px solid #999", padding: "6px 0"}}>
                                                       <div style={{display: "inline-block", width: "49.5%", verticalAlign: "top", background: "#EEE"}}>
                                                            <div style={{border: "1px solid #AAA", textAlign: "center", textTransform: "uppercase", padding: "5px 0", fontWeight: "bold"}}>Kind of Work</div>
                                                            <div style={{border: "1px solid #AAA", padding: "15px 5px"}}>{report_item.description}</div>
                                                            <div style={{border: "1px solid #AAA", textAlign: "center", textTransform: "uppercase", padding: "5px 0", fontWeight: "bold"}}>Location</div>
                                                            <div style={{border: "1px solid #AAA", padding: "15px 5px"}}>{report_item.location}</div>
                                                            <div style={{padding: "8px 0"}}>
                                                                 <ShowButton label={translate('custom.reports.show_task')} to={{
                                                                      pathname: `/tasks/${report_item.task_id}/show`
                                                                 }} />
                                                                 <DeleteButton undoable={false} confirmTitle="Delete?" basePath={redirect} record={report_item} resource="reportitems" style={{float: "right"}} />
                                                            </div>
                                                       </div>
                                                       <div style={{display: "inline-block", width: "49.5%", verticalAlign: "top"}}>
                                                            {report_item.attachments ? (
                                                                 <>
                                                                      {report_item.attachments.map(attachment => attachment && attachment.id ? (
                                                                           <div style={{boxSizing: "border-box", padding: "0 1% 1%"}}>
                                                                                <img src={attachment.path} style={{maxWidth: "100%"}} />
                                                                           </div>
                                                                      ) : null )}
                                                                 </>
                                                            ) : null }
                                                       </div>
                                                  </div>
                                             ) : (
                                                  <div style={{borderBottom: "1px solid #999", padding: "6px 0"}}>
                                                       <div style={{display: "inline-block", width: "32%", verticalAlign: "top", background: "#EEE"}}>
                                                            <div style={{border: "1px solid #AAA", textAlign: "center", textTransform: "uppercase", padding: "5px 0", fontWeight: "bold"}}>Kind of Work</div>
                                                            <div style={{border: "1px solid #AAA", padding: "15px 5px"}}>{report_item.description}</div>
                                                            <div style={{border: "1px solid #AAA", textAlign: "center", textTransform: "uppercase", padding: "5px 0", fontWeight: "bold"}}>Location</div>
                                                            <div style={{border: "1px solid #AAA", padding: "15px 5px"}}>{report_item.location}</div>
                                                            <div style={{padding: "8px 0"}}>
                                                                 <ShowButton label={translate('custom.reports.show_task')} to={{
                                                                      pathname: `/tasks/${report_item.task_id}/show`
                                                                 }} />
                                                                 <DeleteButton undoable={false} confirmTitle="Delete?" basePath={redirect} record={report_item} resource="reportitems" style={{float: "right"}} />
                                                            </div>
                                                       </div>
                                                       <div style={{display: "inline-block", width: "66%", verticalAlign: "top"}}>
                                                            {report_item.attachments ? (
                                                                 <>
                                                                      {report_item.attachments.map(attachment => attachment && attachment.id ? (
                                                                           <div style={{display: "inline-block", verticalAlign: "top", maxWidth: "50%", boxSizing: "border-box", padding: "0 1% 1%"}}>
                                                                                <img src={attachment.path} style={{maxWidth: "100%"}} />
                                                                           </div>
                                                                      ) : null )}
                                                                 </>
                                                            ) : null }
                                                       </div>
                                                  </div>
                                             )}
                                        </>
                                   ) : null)}
                              </>
                         ) : null }
                    </>
                    <>
                         {record.report_items_custom ? (
                              <>
                                   {record.report_items_custom.map(report_item => report_item && report_item.id ? (
                                        <>
                                             { isSmall ? (
                                                  <div style={{borderBottom: "1px solid #999", padding: "6px 0"}}>
                                                       <div style={{display: "inline-block", width: "49.5%", verticalAlign: "top", background: "#EEE"}}>
                                                            <div style={{border: "1px solid #AAA", textAlign: "center", textTransform: "uppercase", padding: "5px 0", fontWeight: "bold"}}>Kind of Work</div>
                                                            <div style={{border: "1px solid #AAA", padding: "15px 5px"}}>{report_item.description}</div>
                                                            <div style={{border: "1px solid #AAA", textAlign: "center", textTransform: "uppercase", padding: "5px 0", fontWeight: "bold"}}>Location</div>
                                                            <div style={{border: "1px solid #AAA", padding: "15px 5px"}}>{report_item.location}</div>
                                                            <div style={{padding: "8px 0", height: "46px", boxSizing: "border-box"}}>
                                                                 <DeleteButton undoable={false} confirmTitle="Delete?" basePath={redirect} record={report_item} resource="reportitemscustom" style={{float: "right"}} />
                                                            </div>
                                                       </div>
                                                       <div style={{display: "inline-block", width: "49.5%", verticalAlign: "top"}}>
                                                            {report_item.attachments ? (
                                                                 <>
                                                                      {report_item.attachments.map(attachment => attachment && attachment.id ? (
                                                                           <div style={{boxSizing: "border-box", padding: "0 1% 1%"}}>
                                                                                <img src={attachment.path} style={{maxWidth: "100%"}} />
                                                                           </div>
                                                                      ) : null )}
                                                                 </>
                                                            ) : null }
                                                       </div>
                                                  </div>
                                             ) : (
                                                  <div style={{borderBottom: "1px solid #999", padding: "6px 0"}}>
                                                       <div style={{display: "inline-block", width: "32%", verticalAlign: "top", background: "#EEE"}}>
                                                            <div style={{border: "1px solid #AAA", textAlign: "center", textTransform: "uppercase", padding: "5px 0", fontWeight: "bold"}}>Kind of Work</div>
                                                            <div style={{border: "1px solid #AAA", padding: "15px 5px"}}>{report_item.description}</div>
                                                            <div style={{border: "1px solid #AAA", textAlign: "center", textTransform: "uppercase", padding: "5px 0", fontWeight: "bold"}}>Location</div>
                                                            <div style={{border: "1px solid #AAA", padding: "15px 5px"}}>{report_item.location}</div>
                                                            <div style={{padding: "8px 0", height: "46px", boxSizing: "border-box"}}>
                                                                 <DeleteButton undoable={false} confirmTitle="Delete?" basePath={redirect} record={report_item} resource="reportitemscustom" style={{float: "right"}} />
                                                            </div>
                                                       </div>
                                                       <div style={{display: "inline-block", width: "66%", verticalAlign: "top"}}>
                                                            {report_item.attachments ? (
                                                                 <>
                                                                      {report_item.attachments.map(attachment => attachment && attachment.id ? (
                                                                           <div style={{display: "inline-block", verticalAlign: "top", maxWidth: "50%", boxSizing: "border-box", padding: "0 1% 1%"}}>
                                                                                <img src={attachment.path} style={{maxWidth: "100%"}} />
                                                                           </div>
                                                                      ) : null )}
                                                                 </>
                                                            ) : null }
                                                       </div>
                                                  </div>
                                             )}
                                        </>
                                   ) : null)}
                              </>
                         ) : null }
                    </>
               </div>
               <div>
                    <div style={{paddingTop: "20px", textAlign: "center"}}>
                         <ReportTaskItemQuickCreate report_id={record.id} project_id={record.project_id} />
                         <ReportCustomItemQuickCreate report_id={record.id} />
                    </div>
               </div>
          </div>
     )
}

const ReportDefectLayout = (props) => {

     const { source } = props;
     const record = useRecordContext(props);
     const translate = useTranslate();

     const redirect = record ? `/reports/${record.id}/show` : false;

     return (
          <div>
               {(record.project_id != null && record.project) ? (
                    <div>
                         <div style={{borderBottom: "1px solid #CCC", padding: "6px 0 "}}>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "30%", textAlign: "right", fontWeight: "bold", paddingRight: "14px", boxSizing: "border-box"}}>
                                   Store Name:
                              </div>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "69%"}}>
                                   {record.project.project_name}
                              </div>
                         </div>
                         <div style={{borderBottom: "1px solid #CCC", padding: "6px 0 "}}>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "30%", textAlign: "right", fontWeight: "bold", paddingRight: "14px", boxSizing: "border-box"}}>
                                   Checking Date:
                              </div>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "69%"}}>
                                   {record.date_format}
                              </div>
                         </div>
                         <div style={{borderBottom: "1px solid #CCC", padding: "6px 0 "}}>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "30%", textAlign: "right", fontWeight: "bold", paddingRight: "14px", boxSizing: "border-box"}}>
                                   Project Leader:
                              </div>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "69%"}}>
                                   {record.foremen ? (
                                        <>
                                             {record.foremen.map(foremen => foremen && foremen.id ? (
                                                  <span>{foremen.name}, </span>
                                             ) : null)}
                                        </>
                                   ) : null }
                              </div>
                         </div>
                         <div style={{borderBottom: "1px solid #CCC", padding: "6px 0 "}}>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "30%", textAlign: "right", fontWeight: "bold", paddingRight: "14px", boxSizing: "border-box"}}>
                                   Contractor Name:
                              </div>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "69%"}}>
                                   {record.teammembers ? (
                                        <>
                                             {record.teammembers.map(teammember => teammember && teammember.id ? (
                                                  <span>{teammember.name}, </span>
                                             ) : null)}
                                        </>
                                   ) : null }
                              </div>
                         </div>
                    </div>
               ) : (
                    <div>
                         <div style={{borderBottom: "1px solid #CCC", padding: "6px 0 "}}>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "30%", textAlign: "right", fontWeight: "bold", paddingRight: "14px", boxSizing: "border-box"}}>
                                   Store Name:
                              </div>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "69%"}}>
                                   {(record.client) ? (
                                        <span>{record.client.name}</span>
                                   ) : null }
                              </div>
                         </div>
                         <div style={{borderBottom: "1px solid #CCC", padding: "6px 0 "}}>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "30%", textAlign: "right", fontWeight: "bold", paddingRight: "14px", boxSizing: "border-box"}}>
                                   Checking Date:
                              </div>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "69%"}}>
                                   {record.date_format}
                              </div>
                         </div>
                         <div style={{borderBottom: "1px solid #CCC", padding: "6px 0 "}}>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "30%", textAlign: "right", fontWeight: "bold", paddingRight: "14px", boxSizing: "border-box"}}>
                                   Project Leader:
                              </div>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "69%"}}>
                                   {record.foremen ? (
                                        <>
                                             {record.foremen.map(foremen => foremen && foremen.id ? (
                                                  <span>{foremen.name}, </span>
                                             ) : null)}
                                        </>
                                   ) : null }
                              </div>
                         </div>
                         <div style={{borderBottom: "1px solid #CCC", padding: "6px 0 "}}>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "30%", textAlign: "right", fontWeight: "bold", paddingRight: "14px", boxSizing: "border-box"}}>
                                   Contractor Name:
                              </div>
                              <div style={{display: "inline-block", verticalAlign: "top", width: "69%"}}>
                                   {record.teammembers ? (
                                        <>
                                             {record.teammembers.map(teammember => teammember && teammember.id ? (
                                                  <span>{teammember.name}, </span>
                                             ) : null)}
                                        </>
                                   ) : null }
                              </div>
                         </div>
                    </div>
               )}
               <div style={{paddingTop: "15px"}}>

                    <table style={{width: "100%", borderCollapse: "collapse"}}>
                         <thead>
                         <tr>
                              <th style={{width: "25%", border: "1px solid #CCC", borderCollapse: "collapse", padding: "4px 0", textAlign: "center", fontWeight: "bold", background: "#ffffa5"}}>Description</th>
                              <th style={{width: "25%", border: "1px solid #CCC", borderCollapse: "collapse", padding: "4px 0", textAlign: "center", fontWeight: "bold", background: "#ffffa5"}}>Before</th>
                              <th style={{width: "25%", border: "1px solid #CCC", borderCollapse: "collapse", padding: "4px 0", textAlign: "center", fontWeight: "bold", background: "#ffffa5"}}>After</th>
                              <th style={{width: "25%", border: "1px solid #CCC", borderCollapse: "collapse", padding: "4px 0", textAlign: "center", fontWeight: "bold", background: "#ffffa5"}}>Remarks</th>
                         </tr>
                         </thead>

                         <tbody>
                              <>
                                   {record.report_items ? (
                                        <>
                                             {record.report_items.map(report_item => report_item && report_item.id ? (
                                                  <tr>
                                                       <td style={{width: "25%", border: "1px solid #AAA", borderCollapse: "collapse", padding: "5px 5px 52px", position: "relative"}}>
                                                            {report_item.description}
                                                            <div style={{padding: "8px 0", position: "absolute", left: 0, right: 0, bottom: 0}}>
                                                                 <ShowButton label={translate('custom.reports.show_task')} style={{padding: 0}} to={{
                                                                      pathname: `/tasks/${report_item.task_id}/show`
                                                                 }} />
                                                                 <DeleteButton undoable={false} basePath={redirect} record={report_item} resource="reportitems" style={{float: "right", padding: 0}} />
                                                            </div>
                                                       </td>
                                                       <td style={{width: "25%", border: "1px solid #AAA", borderCollapse: "collapse", padding: "5px"}}>
                                                            {report_item.attachments ? (
                                                                 <>
                                                                      {report_item.attachments.map(attachment => attachment && attachment.id && attachment.attachment_type == "before" ? (
                                                                           <div style={{display: "inline-block", verticalAlign: "top", maxWidth: "100%", boxSizing: "border-box", padding: "1%"}}>
                                                                                <img src={attachment.path} style={{maxWidth: "100%"}} />
                                                                           </div>
                                                                      ) : null )}
                                                                 </>
                                                            ) : null }
                                                       </td>
                                                       <td style={{width: "25%", border: "1px solid #AAA", borderCollapse: "collapse", padding: "5px"}}>
                                                            {report_item.attachments ? (
                                                                 <>
                                                                      {report_item.attachments.map(attachment => attachment && attachment.id && attachment.attachment_type == "after" ? (
                                                                           <div style={{display: "inline-block", verticalAlign: "top", maxWidth: "100%", boxSizing: "border-box", padding: "1%"}}>
                                                                                <img src={attachment.path} style={{maxWidth: "100%"}} />
                                                                           </div>
                                                                      ) : null )}
                                                                 </>
                                                            ) : null }
                                                       </td>
                                                       <td style={{width: "25%", border: "1px solid #AAA", borderCollapse: "collapse", padding: "5px"}}>
                                                            {report_item.remarks}
                                                       </td>
                                                  </tr>
                                             ) : null)}
                                        </>
                                   ) : null }
                              </>
                              <>
                                   {record.report_items_custom ? (
                                        <>
                                             {record.report_items_custom.map(report_item => report_item && report_item.id ? (
                                                  <tr>
                                                       <td style={{width: "25%", border: "1px solid #AAA", borderCollapse: "collapse", padding: "5px 5px 52px", position: "relative"}}>
                                                            {report_item.description}
                                                            <div style={{padding: "8px 5px", position: "absolute", left: 0, right: 0, bottom: 0}}>
                                                                 <DeleteButton undoable={false} confirmTitle="Delete?" basePath={redirect} record={report_item} resource="reportitemscustom" style={{float: "right"}} />
                                                            </div>
                                                       </td>
                                                       <td style={{width: "25%", border: "1px solid #AAA", borderCollapse: "collapse", padding: "5px"}}>
                                                            {report_item.attachments ? (
                                                                 <>
                                                                      {report_item.attachments.map(attachment => attachment && attachment.id && attachment.attachment_type == "before" ? (
                                                                           <div style={{display: "inline-block", verticalAlign: "top", maxWidth: "100%", boxSizing: "border-box", padding: "1%"}}>
                                                                                <img src={attachment.path} style={{maxWidth: "100%"}} />
                                                                           </div>
                                                                      ) : null )}
                                                                 </>
                                                            ) : null }
                                                       </td>
                                                       <td style={{width: "25%", border: "1px solid #AAA", borderCollapse: "collapse", padding: "5px"}}>
                                                            {report_item.attachments ? (
                                                                 <>
                                                                      {report_item.attachments.map(attachment => attachment && attachment.id && attachment.attachment_type == "after" ? (
                                                                           <div style={{display: "inline-block", verticalAlign: "top", maxWidth: "100%", boxSizing: "border-box", padding: "1%"}}>
                                                                                <img src={attachment.path} style={{maxWidth: "100%"}} />
                                                                           </div>
                                                                      ) : null )}
                                                                 </>
                                                            ) : null }
                                                       </td>
                                                       <td style={{width: "25%", border: "1px solid #AAA", borderCollapse: "collapse", padding: "5px"}}>
                                                            {report_item.remarks}
                                                       </td>
                                                  </tr>
                                             ) : null)}
                                        </>
                                   ) : null }
                              </>
                         </tbody>
                    </table>
               </div>
               <div>
                    <div style={{paddingTop: "20px", textAlign: "center"}}>
                         <ReportItemQuickCreate report_id={record.id} />
                         <ReportCustomItemQuickCreate report_id={record.id} />
                    </div>
               </div>
          </div>
     )
}

const ReportFields = (props) => {

     const { source } = props;
     const record = useRecordContext(props);

     const translate = useTranslate();

     const redirect = record ? `${config.standard_server}/generate-report-pdf/${record.id}` : false;

     return (
          <div>
               <div style={{textAlign: "right", marginBottom: "20px"}}>
                    <a download href={redirect} style={{textDecoration: "none", color: "#3f51b5", borderRadius: "5px", background: "rgba(63, 81, 181, 0.04)", padding: "5px", textTransform: "uppercase", fontSize: "0.8125rem", fontWeight: "bold", fontFamily: "Roboto, Helvetica, Arial, sans-serif", lineHeight: "25px"}}><GetAppIcon style={{verticalAlign: "top"}} /> <span style={{verticalAlign: "top"}}>{translate('custom.reports.download_report')}</span></a>

               </div>
               {(record.type == "photo-report") ? (
                    <ReportPhotoLayout />
               ) : (
                    <ReportDefectLayout />
               )}
          </div>
     )
}

export const ReportShow = props => {

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     const [value, setValue] = React.useState(0);

     const handleChange = (event, newValue) => {
          setValue(newValue);
     };

     return (
          <Show {...props} title=" ">
               <SimpleShowLayout>
                    <ReportFields />
               </SimpleShowLayout>
          </Show>
)};

const ReportFilter = (props) => {

     const translate = useTranslate();

     return (
          <Filter {...props}>
               <ReferenceInput source="project_id" reference="projects">
                    <SelectInput optionText="project_name" />
               </ReferenceInput>
               <ReferenceInput source="client_id" reference="clients">
                    <SelectInput optionText="name" />
               </ReferenceInput>
               <SelectInput source="type" choices={[
                    { id: 'photo-report', name: 'Photo Report' },
                    { id: 'defect-report', name: 'Defect Report' },
               ]} />
               <DateInput source="date_range_start" label="custom.general.date_range_start" />
               <DateInput source="date_range_end" label="custom.general.date_range_end" />
          </Filter>
)};

export const ReportList = props => {

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();
     const translate = useTranslate();

     return (
          <>
               <div style={{paddingLeft: "15px", paddingTop: "15px", fontSize: "20px", fontWeight: "bold"}}>{translate('resources.reports.name')}</div>
               <List {...props} title=" " filters={<ReportFilter />} bulkActionButtons={false} perPage="1000" exporter={false}>
                    {isSmall ? (
                         <div>
                              <SimpleList
                              linkType="show"
                              primaryText={record => `${record.report_id} - ${record.type}`}
                              secondaryText={record => record.client_id ? `${record.client_name} (${translate('custom.reports.client')})` : `${record.project_name} (${translate('custom.reports.project')})`}
                              tertiaryText={record => record.date}
                               />

                         </div>
                    ) : (
                              <Datagrid rowClick="show">
                                   <TextField source="report_id" />
                                   <TextField source="project_name" />
                                   <TextField source="client_name" />
                                   <TextField source="type" />
                                   <TextField source="date" />
                                   <ShowButton />
                              </Datagrid>
                    )}
               </List>
          </>
)};

const ShowReportEditFields = props => {

     const { source } = props;
     const record = useRecordContext(props);
     const translate = useTranslate();

     const checkedType = (record.project_id) ? false : true;

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     return (
          <>
               {(isSmall) ? (
                    <>
                         {(!checkedType) ? (
                              <ReferenceInput allowEmpty={true} source="project_id" reference="projects" label='resources.reports.fields.project' fullWidth>
                                   <SelectInput optionText="project_name" style={{maxWidth: "calc(100vw - 30px)"}} />
                              </ReferenceInput>
                         ) : (
                              <>
                                   <ReferenceInput source="client_id" reference="clients" label='resources.reports.fields.client_id' fullWidth>
                                        <SelectInput optionText="name" />
                                   </ReferenceInput>
                              </>
                         )}
                         <DateInput fullWidth source="date" label='resources.reports.fields.date' />
                         <SelectInput fullWidth source="type" label='resources.reports.fields.type' choices={[
                              { id: 'photo-report', name: 'Photo Report' },
                              { id: 'defect-report', name: 'Defect Report' },
                         ]} />
                         <SelectInput fullWidth source="signatures" label='resources.reports.fields.signatures' choices={[
                              { id: 'standard', name: translate('custom.reports.standard') },
                              { id: 'consultant', name: translate('custom.reports.standard_consultant') },
                              { id: 'contractor', name: translate('custom.reports.standard_contractor') },
                              { id: 'full', name: translate('custom.reports.full') },
                         ]} />
                         <ReferenceInput source="worker" label='resources.reports.fields.worker' reference="users" fullWidth>
                              <SelectInput optionText="name" />
                         </ReferenceInput>
                         <TextInput source="model" label='resources.reports.fields.model' fullWidth  />
                    </>
               ) : (
                    <div style={{width: "60%", margin: "0 auto"}}>
                         {(!checkedType) ? (
                              <ReferenceInput allowEmpty={true} source="project_id" reference="projects" label='resources.reports.fields.project' fullWidth>
                                   <SelectInput optionText="project_name" />
                              </ReferenceInput>
                         ) : (
                              <>
                                   <ReferenceInput allowEmpty={true} source="client_id" reference="clients" label='resources.reports.fields.client_id' fullWidth>
                                        <SelectInput optionText="name" />
                                   </ReferenceInput>
                              </>
                         )}
                         <div>
                              <DateInput source="date" label='resources.reports.fields.date' fullWidth />
                         </div>
                         <div>
                              <SelectInput fullWidth source="type" label='resources.reports.fields.type' choices={[
                                   { id: 'photo-report', name: 'Photo Report' },
                                   { id: 'defect-report', name: 'Defect Report' },
                              ]} />
                         </div>
                         <div>
                              <SelectInput fullWidth source="signatures" label='resources.reports.fields.signatures' choices={[
                                   { id: 'standard', name: translate('custom.reports.standard') },
                                   { id: 'consultant', name: translate('custom.reports.standard_consultant') },
                                   { id: 'contractor', name: translate('custom.reports.standard_contractor') },
                                   { id: 'full', name: translate('custom.reports.full') },
                              ]} />
                         </div>
                         <div>
                              <ReferenceInput source="worker" label='resources.reports.fields.worker' reference="users" fullWidth>
                                   <SelectInput optionText="name" />
                              </ReferenceInput>
                         </div>
                         <div>
                              <TextInput source="model" label='resources.reports.fields.model' fullWidth  />
                         </div>
                    </div>
               )}
          </>
     )
}

export const ReportEdit = props => {

     return (
          <div>
               <Edit undoable={false} title=" " {...props}>
                    <SimpleForm redirect="/reports">
                         <ShowReportEditFields />
                    </SimpleForm>
               </Edit>
          </div>
)};

export const ReportCreate = props => {

     const [state, setState] = React.useState({
          checkedType: false,
     });

     const handleSwitchChange = (event) => {
          setState({ ...state, [event.target.name]: event.target.checked });
     };

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();
     const translate = useTranslate();

     return (
          <div>
               {(isSmall) ? (
                    <Create undoable={false} title=" " {...props}>
                         <SimpleForm  redirect="/reports">
                              <Grid component="label" container alignItems="center" spacing={1} style={{textAlign: "center", margin: "0 auto"}}>
                                   <Grid item>{translate('custom.reports.project')}</Grid>
                                   <Grid item>
                                        <Switch
                                             checked={state.checkedType}
                                             onChange={handleSwitchChange}
                                             color="primary"
                                             name="checkedType"
                                             inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                   </Grid>
                                   <Grid item>{translate('custom.reports.client')}</Grid>
                              </Grid>
                              <hr />
                              {(!state.checkedType) ? (
                                   <ReferenceInput allowEmpty={true} source="project_id" label='resources.reports.fields.project' reference="projects" fullWidth>
                                        <SelectInput optionText="project_name" style={{maxWidth: "calc(100vw - 30px)"}} />
                                   </ReferenceInput>
                              ) : (
                                   <>
                                        <ReferenceInput source="client_id" label='resources.reports.fields.client_id' reference="clients" fullWidth>
                                             <SelectInput optionText="name" />
                                        </ReferenceInput>
                                   </>
                              )}
                              <DateInput fullWidth source="date" />
                              <SelectInput fullWidth source="type" label='resources.reports.fields.type' choices={[
                                   { id: 'photo-report', name: 'Photo Report' },
                                   { id: 'defect-report', name: 'Defect Report' },
                              ]} />
                              <SelectInput fullWidth source="signatures" label='resources.reports.fields.signatures' choices={[
                                   { id: 'standard', name: translate('custom.reports.standard') },
                                   { id: 'consultant', name: translate('custom.reports.standard_consultant') },
                                   { id: 'contractor', name: translate('custom.reports.standard_contractor') },
                                   { id: 'full', name: translate('custom.reports.full') },
                              ]} />
                              <ReferenceInput source="worker" label='resources.reports.fields.worker' reference="users" fullWidth>
                                   <SelectInput optionText="name" />
                              </ReferenceInput>
                              <TextInput source="model" label='resources.reports.fields.model' fullWidth  />
                         </SimpleForm>
                    </Create>
               ) : (
                    <Create undoable={false} title=" " {...props}>
                         <SimpleForm redirect="/reports">
                              <div style={{width: "60%", margin: "0 auto"}}>
                                   <Grid component="label" container alignItems="center" spacing={1} style={{textAlign: "center", margin: "0 auto"}}>
                                        <Grid item>{translate('custom.reports.project_report')}</Grid>
                                        <Grid item>
                                             <Switch
                                                  checked={state.checkedType}
                                                  onChange={handleSwitchChange}
                                                  color="primary"
                                                  name="checkedType"
                                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                             />
                                        </Grid>
                                        <Grid item>{translate('custom.reports.client_report')}</Grid>
                                   </Grid>
                                   <hr />
                                   {(!state.checkedType) ? (
                                        <ReferenceInput allowEmpty={true} source="project_id" reference="projects" label='resources.reports.fields.project' fullWidth>
                                             <SelectInput optionText="project_name" />
                                        </ReferenceInput>
                                   ) : (
                                        <>
                                             <ReferenceInput allowEmpty={true} source="client_id" reference="clients" label='resources.reports.fields.client_id' fullWidth>
                                                  <SelectInput optionText="name" />
                                             </ReferenceInput>
                                        </>
                                   )}
                                   <div>
                                        <DateInput fullWidth source="date" label='resources.reports.fields.date' />
                                   </div>
                                   <div>
                                        <SelectInput fullWidth source="type"  label='resources.reports.fields.type' choices={[
                                             { id: 'photo-report', name: 'Photo Report' },
                                             { id: 'defect-report', name: 'Defect Report' },
                                        ]} />
                                   </div>
                                   <div>
                                        <SelectInput fullWidth source="signatures" label='resources.reports.fields.signatures' choices={[
                                             { id: 'standard', name: translate('custom.reports.standard') },
                                             { id: 'consultant', name: translate('custom.reports.standard_consultant') },
                                             { id: 'contractor', name: translate('custom.reports.standard_contractor') },
                                             { id: 'full', name: translate('custom.reports.full') },
                                        ]} />
                                   </div>
                                   <div>
                                        <ReferenceInput source="worker" label='resources.reports.fields.worker' reference="users" fullWidth>
                                             <SelectInput optionText="name" />
                                        </ReferenceInput>
                                   </div>
                                   <div>
                                        <TextInput source="model" label='resources.reports.fields.model' fullWidth  />
                                   </div>
                              </div>
                         </SimpleForm>
                    </Create>
               )}
          </div>
)};
