import React, { useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import {
     required,
     SaveButton,
     TextInput,
     ReferenceInput,
     SelectInput,
     BooleanInput,
     FileInput,
     FileField,
     DateInput,
     ImageInput,
     ImageField,
     useCreate,
     useNotify,
     useQuery,
     FormWithRedirect,
     useRefresh
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { Link } from 'react-router-dom';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import { Button, CircularProgress  } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import ClassIcon from '@material-ui/icons/Class';

import { useTranslate } from 'react-admin';

import config from './config';

function NotificationsShow({ task_id, project_id }) {
     const [showDialog, setShowDialog] = useState(false);
     const notify = useNotify();
     const translate = useTranslate();

     const api_token = localStorage.getItem('api_token');

     const [notificationNotice, setNotificationNotice] = useState("none");
     const [notifications, setNotifications] = useState();
     const [notificationsLoading, setNotificationsLoading] = useState(true);

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     const handleClick = () => {

          fetch(`${config.api_server}/notifications?api_token=${api_token}`, {
               method: 'GET',
          }).then(response => response.json())
          .then(responsedata => {
               if(responsedata)
               {
                    setNotifications(responsedata);
                    setNotificationsLoading(false);
               }
          });

          setShowDialog(true);
          setNotificationNotice("none");
     };

     const handleCloseClick = () => {
          setShowDialog(false);
     };

     var notificationTimer;

     const timerAction = function()
     {
          fetch(`${config.api_server}/unread-notifications?api_token=${api_token}`, {
               method: 'GET',
          }).then(response => response.json())
          .then(responsedata => {
               if(responsedata.id)
               {
                    setNotificationNotice("block");
                    clearInterval(notificationTimer);
               }
          });

     }

     const NotificationCenter = props => {

          notificationTimer = setInterval(timerAction, 5000);

          return isSmall ? (
               <div style={{display: notificationNotice, position: "fixed", background: "#D00", zIndex: 1000, left: "0", bottom: "60px", overflow: "hidden", width: "50px", height: "56px", lineHeight: "56px", textAlign: "center", borderRadius: "0 50% 50% 0"}}>
                    <Button onClick={handleClick} style={{padding: 0, margin: 0, color: "#FFF", width: "50px", height: "56px", minWidth: "auto"}}>
                         <AnnouncementIcon />
                    </Button>
               </div>
          ) : (
               <div style={{display: notificationNotice, position: "fixed", background: "#D00", zIndex: 1000, left: "20px", bottom: "20px", overflow: "hidden", width: "56px", height: "56px", lineHeight: "56px", textAlign: "center", borderRadius: "50%"}}>
                    <Button onClick={handleClick} style={{padding: 0, margin: 0, color: "#FFF", width: "56px", height: "56px", minWidth: "auto"}}>
                         <AnnouncementIcon />
                    </Button>
               </div>
          )
     }

     return (
          <>
               <NotificationCenter />
               <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Notifications">
                    <DialogTitle>{translate('custom.notifications.notifications')}</DialogTitle>
                         <>
                              <DialogContent>
                                   { notifications ? (
                                        <>
                                             { notifications.map(notification => notification && notification.id ? (
                                                  <>
                                                       {(notification.type == "task-create") ? (
                                                            <div style={{marginBottom: "10px", position: "relative"}}>
                                                                 <div style={{fontSize: "12px", background: "#E6E6E6", padding: "2px 5px", textAlign: "right"}}>{notification.created_at}</div>
                                                                 <Button onClick={handleCloseClick} component={Link} to={{pathname: `/tasks/${notification.item_id}/show`}} style={{textTransform: "initial", padding: "10px 8px", background: "#F3F3F3", width: "100%", textAlign: "left", justifyContent: "flex-start"}}>
                                                                      <AssignmentLateIcon style={{verticalAlign: "middle", color: "#c32059", marginRight: "6px"}} /> <span>{translate('custom.general.notification_task_assigned')}</span>
                                                                 </Button>
                                                            </div>
                                                       ) : (
                                                            <div style={{marginBottom: "10px", position: "relative"}}>
                                                                 <div style={{fontSize: "12px", background: "#E6E6E6", padding: "2px 5px", textAlign: "right"}}>{notification.created_at}</div>
                                                                 <Button onClick={handleCloseClick} component={Link} to={{pathname: `/projects/${notification.item_id}/show`}} style={{textTransform: "initial", padding: "10px 8px", background: "#F3F3F3", width: "100%", textAlign: "left", justifyContent: "flex-start"}}>
                                                                      <ClassIcon style={{verticalAlign: "middle", color: "#2196f3", marginRight: "6px"}} /> <span>{translate('custom.general.notification_project_assigned')}</span>
                                                                 </Button>
                                                            </div>
                                                       )}
                                                  </>
                                             ) : null)}
                                        </>
                                   ) : (
                                        <div style={{textAlign: "center"}}>{ notificationsLoading ? <CircularProgress /> : translate('custom.general.no_notifications')}</div>
                                   )}

                              </DialogContent>
                              <DialogActions>
                                   <Button onClick={handleCloseClick}>
                                        <IconCancel /><span>{translate('custom.general.close')}</span>
                                   </Button>
                              </DialogActions>
                         </>
               </Dialog>
          </>
     );
}

export default NotificationsShow;
