import { TranslationMessages } from 'ra-core';
import config from '../config';

const customEnglishMessages: TranslationMessages = {
     resources: {
          users: {
               name: 'Users',
               fields: {
                    user_details: 'User Details',
                    profile_picture: 'Profile Picture',
                    profile_picture_description: 'Drop a picture to upload, or click to update Profile Picture.',
                    user_group: 'User Group',
                    name: 'Name',
                    username: 'Username',
                    email: 'Email',
                    line_id: 'Line ID',
                    phone: 'Phone',
                    set_password: 'Set Password',
                    reset_password: 'Reset Password',
                    active: 'Active',
                    created_at: 'Created At',
               }
          },
          clients: {
               name: 'Clients',
               fields: {
                    client_details: 'Client Details',
                    logo: 'Logo',
                    logo_description: 'Drop a picture to upload, or click to update Logo.',
                    name: 'Name',
                    email: 'Email',
                    phone: 'Phone',
                    website: 'Website',
                    address: 'Address',
                    tax_id: 'Tax ID',
                    notes: 'Notes',
                    created_at: 'Created At',
               }
          },
          projects: {
               name: 'Projects',
               fields: {
                    project_name: 'Project Name',
                    foremen_id: 'Foremen',
                    client_id: 'Client',
                    address: 'Address',
                    notes: 'Notes',
               }
          },
          tasks: {
               name: 'Tasks',
               fields: {
                    title: 'Title',
                    start_date: 'Start Date',
                    deadline_date: 'Deadline Date',
                    updated_at: 'Updated At',
                    project: 'Project',
                    user: 'User',
                    project_id: 'Project',
                    user_id: 'User',
                    task_title: 'Task Title',
                    description: 'Description',
                    location: 'Location',
                    internal_notes: 'Internal Notes',
                    remarks: 'Remarks',
                    status: 'Status',
                    project_name: 'Project Name',
                    model: "Model",
               }
          },
          reports: {
               name: 'Reports',
               fields: {
                    report_id: 'Report ID',
                    client_id: 'Client',
                    type: 'Type',
                    date: 'Date',
                    project: 'Project',
                    user: 'User',
                    project_id: 'Project',
                    user_id: 'User',
                    signatures: 'Signatures',
                    foremen_id: 'Foremen',
                    logos: "Report Logos",
                    contractor_logo: "Contractor Logo",
                    worker: "Worker",
                    model: "Model",
               }
          }
     },
     custom: {
          general: {
               cancel: 'Cancel',
               close: 'Close',
               date_range_start: 'Date Range Start',
               date_range_end: 'Date Range End',
               welcome: 'Welcome!',
               welcome_message: `At ${config.project_name} Administration you can manage projects, tasks, reports, and clients`,
               notification_project_assigned: "You have a new Project assigned",
               notification_task_assigned: "You have a new Task assigned",
               no_notifications: "No Notifications",
          },
          users: {
               edit_profile: 'Edit My Profile',
               inactive: 'Inactive',
               active: 'Active',

          },
          projects: {
               tab_team_tasks: 'Teams & Tasks',
               tab_project_logs: 'Project Logs',
               tab_project_info: 'Project Info',
               tab_map: 'Map',
               tab_team: 'Teams',
               tab_task: 'Tasks',
               title_team: 'Teams',
               title_task: 'Tasks',
               create_team: 'Create Team',
               team_name: 'Team Name',
               add_team_member: 'Add Team Member',
               user: 'User',
               is_teamleader: 'Is teamleader',
          },
          tasks: {
               assigned: 'Assigned',
               started: 'Started',
               completed: 'Completed',
               show_more: 'Show More',
               create_task: "Create Task",
               start_task: "Start Task",
               complete_task: "Complete Task",
               start_date: "Start Date",
               deadline_date: "Deadline",
               started_on: "Started On",
               completed_on: "Completed",
               tab_task_info: "Task Info",
               tab_task_images: "Task Images",
               tab_task_logs: "Task Logs",
               task_images: "Task Images",
               save_image: "Save Image",
               saving: "Saving...",
               before: "Before",
               after: "After",
               add_task_image: "Add Task Image",
               before_image: "Before Image",
               after_image: "After Image",
               show_all: "Show All",
               show_in_report: "Show",
               hide_in_report: "Hide",
               in_report: "In Report",
               shown: "Shown",
               hidden: "Hidden",
          },
          reports: {
               project_report: 'Project Report',
               client_report: 'Client Report',
               project: 'Project',
               client: 'Client',
               standard: 'Standard',
               standard_consultant: 'Standard + Consultant',
               standard_contractor: 'Standard + Main Contractor',
               full: 'Full',
               download_report: 'Download Report',
               show_task: 'Show Task',
               add_task_items: "Add Task Items",
               add_custom_item: "Add Custom Item",
               add_task_item: "Add Task Item",
               description: 'Description',
               location: 'Location',
               remarks: 'Remarks',
               before_image: "Before Images",
               after_image: "After Images",
               standard_logo: 'Standard Logo',
               contractor_logo: 'Contractor Logo Only',
               standard_contractor_logo: 'Standard Logo + Contractor Logo',
               create_report: "Create / Add to Report",
               switch_create_report: "Create",
               switch_add_to_report: "Add To",
               image_select_text: "Choose up to 4 images",
               image_select_error: "limited to 4 images only",
          },
          logs: {
               logs: 'Logs',
               add_log: 'Add Log',
               log_files: 'Log Files',
               log_pictures: 'Log Pictures',
               content: 'Content',
          },
          announcements: {
               latest_announcements: "Latest Announcements",
               create_announcement: "Create Announcement",
               pictures: "Pictures",
               title: "Title",
               content: "Content",
          },
          notifications: {
               notifications: "Notifications",
          }
     }
};

export default customEnglishMessages;
