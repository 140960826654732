import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
     required,
     SaveButton,
     TextInput,
     ReferenceInput,
     SelectInput,
     BooleanInput,
     FileInput,
     FileField,
     ImageInput,
     ImageField,
     useCreate,
     useNotify,
     FormWithRedirect,
     useRefresh
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useTranslate } from 'react-admin';

function LogQuickCreate({ parent_id, component_type }) {
     const [showDialog, setShowDialog] = useState(false);
     const [create, { loading }] = useCreate(component_type);
     const notify = useNotify();
     const translate = useTranslate();

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     const handleClick = () => {
          setShowDialog(true);
     };

     const handleCloseClick = () => {
          setShowDialog(false);
     };

     const handleChange = useRefresh();

     const convertFileToBase64 = file =>
          new Promise((resolve, reject) => {
               const reader = new FileReader();
               reader.onload = () => resolve(reader.result);
               reader.onerror = reject;

               reader.readAsDataURL(file.rawFile);
     });


     const handleSubmit = async values => {

          values.parent_id = parent_id;
          values.user_id = localStorage.getItem('user_id');

          if(values.files)
          {
               if(Array.isArray(values.files))
               {
                    const newFiles = values.files.filter(
                         p => p.rawFile instanceof File
                    );

                    const formerFiles = values.files.filter(
                         p => !(p.rawFile instanceof File)
                    );

                    return Promise.all(newFiles.map(convertFileToBase64))
                    .then(base64Files =>
                         base64Files.map(file64 => ({
                              src: file64,
                              title: `${values.title}`,
                         }))
                    )
                    .then(transformedNewFiles => {
                         console.log(transformedNewFiles);
                         create({ payload: { data: {
                              ...values,
                              uploaded_files: [
                                   ...transformedNewFiles,
                                   ...formerFiles,
                              ],
                         } } },
                         {
                              onSuccess: ({ data }) => {
                                   setShowDialog(false);
                                   handleChange();
                              },
                              onFailure: ({ error }) => {
                                   notify(error.message, 'error');
                              }
                         }
                    )});
               }
               else
               {
                    const myFile = values.files;
                    if ( !myFile.rawFile instanceof File ){
                         return Promise.reject('Error: Not a file...'); // Didn't test this...
                    }

                    return Promise.resolve( convertFileToBase64(myFile) )
                    .then( (file64) => ({
                         src: file64,
                         title: `${myFile.title}`
                    }))
                    .then( transformedMyFile => {

                              console.log(transformedMyFile);
                                   create({ payload: { data: {
                                        ...values,
                                        uploaded_files: transformedMyFile
                                   } } },
                                   {
                                        onSuccess: ({ data }) => {
                                             setShowDialog(false);
                                             handleChange();
                                        },
                                        onFailure: ({ error }) => {
                                             notify(error.message, 'error');
                                        }
                                   }
                              )
                         }
                    )
               }
          }
          else
          {
               create(
                    { payload: { data: values } },
                    {
                         onSuccess: ({ data }) => {
                              setShowDialog(false);
                              handleChange();
                         },
                         onFailure: ({ error }) => {
                              notify(error.message, 'error');
                         }
                    }
               );
          }
     };

     return (
          <>
               <Button onClick={handleClick}>
                    <IconContentAdd /> <span>{translate('custom.logs.add_log')}</span>
               </Button>
               <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Add Log">
                    <DialogTitle>{translate('custom.logs.add_log')}</DialogTitle>

                    <FormWithRedirect resource={component_type} save={handleSubmit}
                         render={({
                              handleSubmitWithRedirect,
                              pristine,
                              saving
                         }) => (
                              <>
                                   <DialogContent>
                                        <FileInput source="files" label='custom.logs.log_files' multiple>
                                             <FileField source="src" title="title" />
                                        </FileInput>
                                        <TextInput multiline source="content" label='custom.logs.content' fullWidth />
                                   </DialogContent>
                                   <DialogActions>
                                        <Button onClick={handleCloseClick} disabled={loading}>
                                             <IconCancel /><span>{translate('custom.general.cancel')}</span>
                                        </Button>
                                        <SaveButton handleSubmitWithRedirect={handleSubmitWithRedirect} pristine={pristine} saving={saving} disabled={loading} />
                                   </DialogActions>
                              </>
                         )}
                    />
               </Dialog>
          </>
     );
}

export default LogQuickCreate;
