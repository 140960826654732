import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    Button,
    SaveButton,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    ImageInput,
    ImageField,
    useCreate,
    useNotify,
    FormWithRedirect,
    useRefresh
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import { useTranslate } from 'react-admin';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

function AnnouncementQuickCreate({ parent_id, component_type }) {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { loading }] = useCreate(component_type);
    const notify = useNotify();
    const translate = useTranslate();

    const handleClick = () => {
        setShowDialog(true);

    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleChange = useRefresh();


    const convertFileToBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;

            reader.readAsDataURL(file.rawFile);
        });

    const handleSubmit = async values => {
         values.parent_id = parent_id;
         values.user_id = localStorage.getItem('user_id');

         if(values.pictures)
         {
              if(Array.isArray(values.pictures))
              {
                   const newPictures = values.pictures.filter(
                        p => p.rawFile instanceof File
                   );

                   const formerPictures = values.pictures.filter(
                        p => !(p.rawFile instanceof File)
                   );

                   return Promise.all(newPictures.map(convertFileToBase64))
                        .then(base64Pictures =>
                             base64Pictures.map(picture64 => ({
                                  src: picture64,
                                  title: `${values.title}`,
                             }))
                        )
                        .then(transformedNewPictures =>
                             create({ payload: { data: {
                                 ...values,
                                 pictures: [
                                     ...transformedNewPictures,
                                     ...formerPictures,
                                 ],
                             } } },
                             {
                                  onSuccess: ({ data }) => {
                                       setShowDialog(false);
                                       handleChange();
                                  },
                                  onFailure: ({ error }) => {
                                       notify(error.message, 'error');
                                  }
                             }
                    ));
            }
            else
            {
                  const myFile = values.pictures;
                  if ( !myFile.rawFile instanceof File ){
                       return Promise.reject('Error: Not a file...'); // Didn't test this...
                  }

                  return Promise.resolve( convertFileToBase64(myFile) )
                  .then( (picture64) => ({
                       src: picture64,
                       title: `${myFile.title}`
                  }))
                  .then( transformedMyFile =>
                       create({ payload: { data: {
                               ...values,
                               pictures: transformedMyFile
                            } } },
                            {
                                 onSuccess: ({ data }) => {
                                      setShowDialog(false);
                                      handleChange();
                                 },
                                 onFailure: ({ error }) => {
                                      notify(error.message, 'error');
                                 }
                            }
                   )
              )
            }
        }
        else
        {
             create(
                  { payload: { data: values } },
                  {
                       onSuccess: ({ data }) => {
                            setShowDialog(false);
                            handleChange();
                       },
                       onFailure: ({ error }) => {
                            notify(error.message, 'error');
                       }
                  }
             );
        }
    };

    return (
        <>
            <Button onClick={handleClick} label={translate('custom.announcements.create_announcement')}>
                <IconContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Create Announcement"
            >
                <DialogTitle>{translate('custom.announcements.create_announcement')}</DialogTitle>

                <FormWithRedirect
                    resource={component_type}
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                            <DialogContent>
                                 <ImageInput source="pictures" label="custom.announcements.pictures"  multiple accept="image/jpeg,image/png" >
                                      <ImageField source="src" />
                                 </ImageInput>
                                 <TextInput source="title" label='custom.announcements.title' fullWidth />
                                 <TextInput multiline source="content" label='custom.announcements.content' fullWidth />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label={translate('custom.general.cancel')}
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

export default AnnouncementQuickCreate;
