import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    SaveButton,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    ImageInput,
    ImageField,
    useCreate,
    useNotify,
    FormWithRedirect,
    useRefresh
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import { useTranslate } from 'react-admin';

import { Button } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

function TaskImageQuickCreate({task_id }) {
     const [showDialog, setShowDialog] = useState(false);
     const [create, { loading }] = useCreate('taskimages');
     const notify = useNotify();
     const translate = useTranslate();

     const [state, setState] = React.useState({
          checkedType: false,
          checkedHideReport: false,
     });

     const handleSwitchChange = (event) => {
          setState({ ...state, [event.target.name]: event.target.checked });
     };

     const handleClick = () => {
          setShowDialog(true);
     };

     const handleCloseClick = () => {
          setShowDialog(false);
     };

     const handleChange = useRefresh();

     const convertFileToBase64 = file =>
         new Promise((resolve, reject) => {
             const reader = new FileReader();
             reader.onload = () => resolve(reader.result);
             reader.onerror = reject;

             reader.readAsDataURL(file.rawFile);
         });

     const handleSubmit = async values => {
          values.task_id = task_id;
          values.user_id = localStorage.getItem('user_id');
          values.attachment_type = (state.checkedType) ? "after" : "before";
          values.hidden_in_report = (state.checkedHideReport) ? 1 : 0;

          if(values.pictures)
          {
               if(Array.isArray(values.pictures))
               {
                    const newPictures = values.pictures.filter(
                         p => p.rawFile instanceof File
                    );

                    const formerPictures = values.pictures.filter(
                         p => !(p.rawFile instanceof File)
                    );

                    return Promise.all(newPictures.map(convertFileToBase64))
                         .then(base64Pictures =>
                              base64Pictures.map(picture64 => ({
                                   src: picture64,
                                   title: `${values.title}`,
                              }))
                         )
                         .then(transformedNewPictures =>
                              create({ payload: { data: {
                                   ...values,
                                   pictures: [
                                        ...transformedNewPictures,
                                        ...formerPictures,
                                   ],
                              } } },
                              {
                                   onSuccess: ({ data }) => {
                                        setShowDialog(false);
                                        handleChange();
                                   },
                                   onFailure: ({ error }) => {
                                        notify(error.message, 'error');
                                   }
                              }
                     ));
              }
              else
              {
                   const myFile = values.pictures;
                   if ( !myFile.rawFile instanceof File ){
                        return Promise.reject('Error: Not a file...'); // Didn't test this...
                   }

                   return Promise.resolve( convertFileToBase64(myFile) )
                   .then( (picture64) => ({
                        src: picture64,
                        title: `${myFile.title}`
                   }))
                   .then( transformedMyFile =>
                        create({ payload: { data: {
                                 ...values,
                                 pictures: transformedMyFile
                             } } },
                             {
                                  onSuccess: ({ data }) => {
                                       setShowDialog(false);
                                       handleChange();
                                  },
                                  onFailure: ({ error }) => {
                                       notify(error.message, 'error');
                                  }
                             }
                    )
               )
              }
         }
     };

     return (
          <>


               <Button onClick={handleClick}>
                    <IconContentAdd /> <span>{translate('custom.tasks.add_task_image')}</span>
               </Button>

               <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={handleCloseClick}
                    aria-label="Add Task Image"
               >
                    <DialogTitle>{translate('custom.tasks.add_task_image')}</DialogTitle>

                    <FormWithRedirect
                         resource="taskimages"
                         save={handleSubmit}
                         render={({
                              handleSubmitWithRedirect,
                              pristine,
                              saving
                         }) => (
                              <>
                                   <DialogContent>
                                        <Grid component="label" container alignItems="center" spacing={1} style={{textAlign: "center", margin: "0 auto"}}>
                                             <Grid item>{translate('custom.tasks.before_image')}</Grid>
                                             <Grid item>
                                                  <Switch
                                                       checked={state.checkedType}
                                                       onChange={handleSwitchChange}
                                                       color="primary"
                                                       name="checkedType"
                                                       inputProps={{ 'aria-label': 'primary checkbox' }}
                                                  />
                                             </Grid>
                                             <Grid item>{translate('custom.tasks.after_image')}</Grid>
                                        </Grid>
                                        <hr />
                                        <p style={{marginBottom: "0", fontWeight: "bold"}}>{translate('custom.tasks.in_report')}</p>
                                        <Grid component="label" container alignItems="center" spacing={1} style={{textAlign: "center", margin: "0 auto"}}>
                                             <Grid item>{translate('custom.tasks.show_in_report')}</Grid>
                                             <Grid item>
                                                  <Switch
                                                       checked={state.checkedHideReport}
                                                       onChange={handleSwitchChange}
                                                       color="primary"
                                                       name="checkedHideReport"
                                                       inputProps={{ 'aria-label': 'primary checkbox' }}
                                                  />
                                             </Grid>
                                             <Grid item>{translate('custom.tasks.hide_in_report')}</Grid>
                                        </Grid>
                                        <hr />
                                        <ImageInput source="pictures" label="custom.tasks.task_images" multiple accept="image/jpeg,image/png" >
                                             <ImageField source="src" />
                                        </ImageInput>
                                   </DialogContent>
                                   <DialogActions>
                                        <Button onClick={handleCloseClick} disabled={loading}>
                                             <IconCancel /><span>{translate('custom.general.cancel')}</span>
                                        </Button>
                                        <SaveButton handleSubmitWithRedirect={handleSubmitWithRedirect} pristine={pristine} saving={saving} disabled={loading} />
                                   </DialogActions>
                              </>
                         )}
                    />
               </Dialog>
          </>
     );
}

export default TaskImageQuickCreate;
