import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    link: {
        textDecoration: 'none',
    },
    icon: {
        height: '32px',
        marginBottom: '-4px',
    },
});

const Logo = props => {

     const classes = useStyles();

     return (
          <span>
               <img src={require('./assets/images/logo.png')} className={classes.icon} />
          </span>
    )
};

export default Logo;
