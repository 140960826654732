import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    SaveButton,
    TextInput,
    ReferenceInput,
    SelectInput,
    useCreate,
    useNotify,
    FormWithRedirect,
    useRefresh
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useTranslate } from 'react-admin';

function TeamQuickCreate({project_id }) {
     const [showDialog, setShowDialog] = useState(false);
     const [create, { loading }] = useCreate('teams');
     const notify = useNotify();

     const translate = useTranslate();

    const handleClick = () => {
        setShowDialog(true);

    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleChange = useRefresh();

    const handleSubmit = async values => {
         values.project_id = project_id;
        create(
            { payload: { data: values } },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false);
                    handleChange();
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    return (
        <>
             <Button onClick={handleClick}>
                  <IconContentAdd /> <span>{translate('custom.projects.create_team')}</span>
             </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Create Team"
            >
                <DialogTitle>{translate('custom.projects.create_team')}</DialogTitle>

                <FormWithRedirect
                    resource="teams"
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }) => (
                        <>
                            <DialogContent>
                                 <TextInput source="team_name" label='custom.projects.team_name' fullWidth  />
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleCloseClick} disabled={loading}>
                                 <IconCancel /><span>{translate('custom.general.cancel')}</span>
                            </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

export default TeamQuickCreate;
