import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    Button,
    SaveButton,
    useCreate,
    useUpdate,
    useNotify,
    FormWithRedirect,
    EditButton,
    DeleteButton,
    useRefresh
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import ImageIcon from '@material-ui/icons/Image';
import CheckIcon from '@material-ui/icons/Check';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-image-editor/dist/service-mobile.css';
import ImageEditor from '@toast-ui/react-image-editor';

import { useTranslate } from 'react-admin';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

import config from './config';

function TaskImageEditMobile({ task_id, attachments }) {
     const [showDialog, setShowDialog] = useState(false);
     const [update, { loading, error }] = useUpdate();

     const translate = useTranslate();

     const [showEditor, setShowEditor] = useState('none');
     const [currentAttachment, setCurrentAttachment] = useState({});
     const [savingLabel, setSavingLabel] = useState(translate('custom.tasks.save_image'));

     const cssWidth = document.documentElement.clientWidth;
     const cssHeight = (parseInt(document.documentElement.clientHeight)-140);

     const notify = useNotify();

     const editorRef = React.createRef();

     const handleRefresh = useRefresh();

     const setVisibility = (attachment) => {

          update('taskimages', attachment.id, { editType: "hidden_in_report" }, attachment,{
               onSuccess: ({ data }) => {
                    handleRefresh();
               },
               onFailure: ({ error }) => {
                    notify(error.message, 'error');
               }
          });

     };

     const handleClick = (attachment) => {

          setShowEditor('block');

          setCurrentAttachment(attachment);

          const editorInstance = editorRef.current.getInstance();
          editorInstance.loadImageFromURL(attachment.editpath, 'EditImage');
     };


     const handleCloseClick = () => {
          setShowEditor('none');
     };

     const handleChange = useRefresh();

     const saveImage = () => {
          setSavingLabel(translate('custom.tasks.saving'));

          const editorInstance = editorRef.current.getInstance();

          var content = editorInstance.toDataURL();

          update('taskimages', currentAttachment.id, { pictures: content }, currentAttachment,{
               onSuccess: ({ data }) => {
                    setShowEditor('none');
                    setSavingLabel(translate('custom.tasks.save_image'));
                    handleChange();
               },
               onFailure: ({ error }) => {
                    notify(error.message, 'error');
               }
          });

     };


     const redirect = task_id ? `/tasks/${task_id}/show` : false;

     return (
          <>
               <div style={{display: showEditor, position: "fixed", top: '0', bottom: 0, left: 0, right: 0, background: "#FFF", zIndex: "2000"}}>
                    <div style={{position: "absolute", top: 0, right:0, zIndex: 200, paddingTop: "5px", paddingRight: "8px"}}>
                         <Button label={savingLabel} onClick={saveImage} style={{background: "#FFF", marginRight: "8px"}} disabled={loading}>
                              <CheckIcon />
                         </Button>
                         <Button label="custom.general.cancel" onClick={handleCloseClick} style={{background: "#FFF"}}>
                              <IconCancel />
                         </Button>
                    </div>
                    <ImageEditor ref={editorRef}
                         includeUI={{
                              loadImage: {
                                   path: `${config.api_server}/return-image?file=storage/images/default.png`,
                                   name: 'edit-image',
                              },
                         }}
                         cssMaxHeight={cssHeight}
                         cssMaxWidth={cssWidth}
                         selectionStyle={{
                              borderColor: 'red', // Selection line color
    cornerColor: 'green', // Selection corner color
    cornerSize: 20, // Selection corner size
    rotatingPointOffset: 100, // Distance from selection area to rotation corner
    transparentCorners: false, // Selection corner Transparency
                         }}
                         usageStatistics={false}
                    />
               </div>
               <div>
                    { (attachments && attachments.length > 0) ? (
                         <Gallery>
                         { attachments.map(attachment => (
                              <div key={attachment.id} style={{background: "#EEE", display: "inline-block", width: "45.5%", border: "2px solid #CCC", margin: "10px 1.5%", position: "relative"}}>
                                   <div style={{position: "absolute", bottom: "36px", left: 0, right:0, background: "rgba(0,0,0,0.4)", color: "#FFF", textAlign: "center", textTransform: "capitalize", padding: "2px 0", fontSize: "10px", fontWeight: "bold"}}>{translate(`custom.tasks.${attachment.attachment_type}`)}</div>
                                   <Item original={attachment.path} thumbnail={attachment.path} width={attachment.image_width} height={attachment.image_height} >
                                        {({ ref, open }) => (
                                             <img ref={ref} onClick={open} src={attachment.thumb} style={{maxWidth: "100%"}} />
                                        )}
                                   </Item>
                                   { (attachment.hidden_in_report == "1") ? (
                                        <div style={{position: "absolute", top: 0, left: 0, right:0, background: "rgba(0,0,0,0.5)", padding: "1px", color: "#FFF", textTransform: "capitalize", fontSize: "10px", fontWeight: "bold"}}>
                                             <div onClick={() => setVisibility(attachment)} style={{float: "right", background: "#3f9fb5", padding: "6px 20px", cursor: "pointer"}}>{translate(`custom.tasks.show_in_report`)}</div>
                                             <span style={{paddingLeft: "8px", lineHeight: "24px"}}><VisibilityOffIcon style={{fontSize: "17px", paddingBottom: "2px", verticalAlign: "middle"}} /> {translate(`custom.tasks.hidden`)}</span>
                                        </div>
                                   ) : (
                                        <div style={{position: "absolute", top: 0, left: 0, right:0, background: "rgba(0,0,0,0.5)", padding: "1px", color: "#FFF", textTransform: "capitalize", fontSize: "10px", fontWeight: "bold"}}>
                                             <div onClick={() => setVisibility(attachment)} style={{float: "right", background: "#3f51b5", padding: "6px 20px", cursor: "pointer"}}>{translate(`custom.tasks.hide_in_report`)}</div>
                                             <span style={{paddingLeft: "8px", lineHeight: "24px"}}><VisibilityIcon style={{fontSize: "17px", paddingBottom: "2px", verticalAlign: "middle"}} /> {translate(`custom.tasks.shown`)}</span>
                                        </div>
                                   )}
                                   <div style={{borderTop: "1px solid #AAA"}}>
                                        <div style={{float: "right"}}>
                                             <DeleteButton undoable={false} record={attachment} basePath={redirect} resource="taskimages" />
                                        </div>
                                        <EditButton onClick={() => handleClick(attachment)} />
                                   </div>
                              </div>
                         ))}
                         </Gallery>
                    ) : (
                         <div style={{textAlign: "center"}}>
                              <ImageIcon style={{fontSize: "250px", color: "#AAA"}} />
                              <div style={{fontSize: "22px", marginTop: "-15px", marginBottom: "20px"}}>No Images Yet</div>
                         </div>
                    ) }
               </div>
          </>
     );
}

export default TaskImageEditMobile;
