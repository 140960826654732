import { TranslationMessages } from 'ra-core';
import config from '../config';

const thaiMessages: TranslationMessages = {
     ra: {
          action: {
               delete: 'ลบ',
               show: 'แสดง',
               list: 'รายการ',
               save: 'บันทึก',
               create: 'สร้าง',
               edit: 'แก้ไข',
               cancel: 'ยกเลิก',
               refresh: 'รีเฟรช',
               add_filter: 'เพิ่มตัวกรอง',
               remove_filter: 'ลบตัวกรอง',
               confirm: 'ยืนยัน',
               undo: 'เลิกทำ',
          },
          boolean: {
               true: 'ใช่',
               false: 'ไม่ใช่',
          },
          page: {
               list: 'รายการ %{name}',
               edit: 'แก้ไข %{name} #%{id}',
               show: 'แสดง %{name} #%{id}',
               create: 'สร้าง %{name}',
               delete: 'ลบ %{name} #%{id}',
               dashboard: 'แดชบอร์ด',
          },
          input: {
               image: {
                    upload_several: 'อัปโหลดหลายไฟล์',
                    upload_single: 'อัปโหลด',
               },
               file: {
                    upload_several: 'อัปโหลดหลายไฟล์',
                    upload_single: 'อัปโหลด',
               },
          },
          message: {
               yes: 'ใช่',
               no: 'ไม่ใช่',
               are_you_sure: 'คุณแน่ใจหรือไม่?',
               about: 'เกี่ยวกับ',
               delete_title: 'ลบ?',
               delete_content: 'คุณแน่ใจหรือไม่ว่าต้องการลบ',
          },
          navigation: {
               page_out_of_boundaries: 'เกินจำนวนหน้าทั้งหมด',
               page_out_from_end: 'เกินจำนวนหน้าสุดท้าย',
               page_out_from_begin: 'เกินจำนวนหน้าแรก',
               page_range_info: '%{offsetBegin}-%{offsetEnd} จาก %{total}',
               page_rows_per_page: '',
               next: 'ถัดไป',
               prev: 'ย้อนกลับ',
          },
          auth: {
               username: 'ชื่อผู้ใช้งาน',
               password: 'รหัสผ่าน',
               sign_in: 'เข้าระบบ',
               sign_in_error: 'การเข้าระบบล้มเหลว',
               logout: 'ออกจากระบบ',
          },
          notification: {
               updated: 'แก้ไขข้อมูลแล้ว',
               created: 'เพิ่มข้อมูลแล้ว',
               deleted: 'ลบข้อมูลแล้ว',
               item_doesnt_exist: 'ไม่มีรายการ',
               http_error: 'การเชื่อมต่อล้มเหลว',
          },
          validation: {
               required: 'จำเป็น',
          },
     },
     resources: {
          users: {
               name: 'ผู้ใช้',
               fields: {
                    user_details: 'รายละเอียดผู้ใช้',
                    profile_picture: 'รูปประวัติ',
                    profile_picture_description: 'วางรูปภาพเพื่ออัปโหลด หรือคลิกเพื่ออัปเดตรูปโปรไฟล์',
                    user_group: 'กลุ่มผู้ใช้',
                    name: 'ชื่อ',
                    username: 'ชื่อผู้ใช้',
                    email: 'อีเมล',
                    line_id: 'ไอดีไลน์',
                    phone: 'โทรศัพท์',
                    set_password: 'ตั้งรหัสผ่าน',
                    reset_password: 'รีเซ็ตรหัสผ่าน',
                    active: 'ใช้งาน',
                    created_at: 'สร้างที่',
               }
          },
          clients: {
               name: 'ลูกค้า',
               fields: {
                    client_details: 'รายละเอียดลูกค้า',
                    logo: 'โลโก้',
                    logo_description: 'วางรูปภาพเพื่ออัปโหลด หรือคลิกเพื่ออัปเดตโลโก้',
                    name: 'ชื่อ',
                    email: 'อีเมล',
                    phone: 'โทรศัพท์',
                    website: 'เว็บไซต์',
                    address: 'ที่อยู่',
                    tax_id: 'เลขประจำตัวผู้เสียภาษี',
                    notes: 'บันทึกย่อ',
                    created_at: 'สร้างที่',
               }
          },
          projects: {
               name: 'โครงการ',
               fields: {
                    project_name: 'ชื่อโครงการ',
                    foremen_id: 'หัวหน้าคนงาน',
                    client_id: 'ลูกค้า',
                    address: 'ที่อยู่',
                    notes: 'บันทึกย่อ',
               }
          },
          tasks: {
               name: 'งาน',
               fields: {
                    title: 'ชื่อ',
                    start_date: 'วันที่เริ่มต้น',
                    deadline_date: 'วันกำหนดส่ง',
                    updated_at: 'ปรับปรุงที่',
                    project: 'โครงการ',
                    user: 'ผู้ใช้',
                    project_id: 'โครงการ',
                    user_id: 'ผู้ใช้',
                    task_title: 'ชื่องาน',
                    description: 'คำอธิบาย',
                    location: 'ที่ตั้ง',
                    internal_notes: 'หมายเหตุภายใน',
                    remarks: 'หมายเหตุ',
                    status: 'สถานะ',
                    project_name: 'โครงการ',
                    model: "รุ่น",
               }
          },
          reports: {
               name: 'รายงาน',
               fields: {
                    report_id: 'รหัสรายงาน',
                    client_id: 'ลูกค้า',
                    type: 'พิมพ์',
                    date: 'วันที่',
                    project: 'โครงการ',
                    user: 'ผู้ใช้',
                    project_id: 'โครงการ',
                    user_id: 'ผู้ใช้',
                    signatures: 'ลายเซ็น',
                    foremen_id: 'หัวหน้าคนงาน',
                    logos: "โลโก้รายงาน",
                    contractor_logo: "โลโก้ผู้รับเหมา",
                    worker: "ผู้จัดทำ",
                    model: "รุ่น",
               }
          }
     },
     custom: {
          general: {
               cancel: 'ยกเลิก',
               close: 'ปิด',
               date_range_start: 'ช่วงวันที่เริ่มต้น',
               date_range_end: 'ช่วงวันที่สิ้นสุด',
               welcome: 'ยินดีต้อนรับ!',
               welcome_message: `ที่ ${config.project_name} Administration คุณสามารถจัดการโครงการ์ งาน รายงาน และลูกค้า`,
               notification_project_assigned: "คุณมีโครงการใหม่ที่ได้รับมอบหมาย",
               notification_task_assigned: "คุณมีงานใหม่ที่ได้รับมอบหมาย",
               no_notifications: "No Notifications",
          },
          users: {
               edit_profile: 'แก้ไขโปรไฟล์ของฉัน',
               inactive: 'ไม่ได้ใช้งาน',
               active: 'ใช้งาน',
          },
          projects: {
               tab_team_tasks: 'ทีม & งาน',
               tab_project_logs: 'บันทึกโครงการ',
               tab_project_info: 'ข้อมูลโครงการ',
               tab_map: 'แผนที่',
               tab_team: 'ทีม',
               tab_task: 'งาน',
               title_team: 'ทีม',
               title_task: 'งาน',
               create_team: 'สร้างทีม',
               team_name: 'ชื่อทีม',
               add_team_member: 'เพิ่มสมาชิกในทีม',
               user: 'ผู้ใช้',
               is_teamleader: 'เป็นหัวหน้าทีม',
          },
          tasks: {
               assigned: 'ที่ได้รับมอบหมาย',
               started: 'เริ่ม',
               completed: 'สมบูรณ์',
               show_more: 'แสดง',
               create_task: "สร้างงาน",
               start_task: "เริ่มงาน",
               complete_task: "เสร็จสิ้นภารกิจ",
               start_date: 'วันที่เริ่มต้น',
               deadline_date: 'วันกำหนดส่ง',
               started_on: "เริ่มเมื่อ",
               completed_on: "เสร็จเมื่อ",
               tab_task_info: "ข้อมูลงาน",
               tab_task_images: "รูปภาพงาน",
               tab_task_logs: "บันทึกงาน",
               task_images: "รูปภาพงาน",
               save_image: "บันทึกภาพ",
               saving: "ประหยัด...",
               before: "ก่อน",
               after: "หลังจาก",
               add_task_image: "เพิ่มภาพงาน",
               before_image: "ก่อน",
               after_image: "หลังจาก",
               show_all: "แสดงทั้งหมด",
               show_in_report: "แสดง",
               hide_in_report: "ซ่อน",
               in_report: "ในรายงาน",
               shown: "แสดง",
               hidden: "ซ่อนอยู่",
          },
          reports: {
               project_report: 'รายงานโครงการ',
               client_report: 'รายงานลูกค้า',
               project: 'โครงการ',
               client: 'ลูกค้า',
               standard: 'มาตรฐาน',
               standard_consultant: 'มาตรฐาน + ที่ปรึกษา',
               standard_contractor: 'มาตรฐาน + ผู้รับเหมาหลัก',
               full: 'เต็ม',
               download_report: 'ดาวน์โหลดรายงาน',
               show_task: 'แสดงงาน',
               add_task_items: "เพิ่มรายการงาน",
               add_custom_item: "เพิ่มรายการที่กำหนดเอง",
               add_task_item: "เพิ่มรายการงาน",
               description: 'คำอธิบาย',
               location: 'ที่ตั้ง',
               remarks: 'หมายเหตุ',
               before_image: "รูปภาพก่อน",
               after_image: "รูปภาพหลังจาก",
               standard_logo: 'โลโก้มาตรฐาน',
               contractor_logo: 'โลโก้ผู้รับเหมา',
               standard_contractor_logo: 'โลโก้มาตรฐาน + โลโก้ผู้รับเหมา',
               create_report: "สร้าง / เพิ่มในรายงาน",
               switch_create_report: "สร้าง",
               switch_add_to_report: "เพิ่ม",
               image_select_text: "เลือกได้สูงสุด 4 ภาพ",
               image_select_error: "จำกัดเพียง 4 ภาพเท่านั้น",
          },
          logs: {
               logs: 'บันทึก',
               add_log: 'เพิ่มบันทึก',
               log_files: 'ล็อกไฟล์',
               log_pictures: 'ล็อกรูปภาพ',
               content: 'กระแสความ',
          },
          announcements: {
               latest_announcements: "ประกาศล่าสุด",
               create_announcement: "สร้างประกาศ",
               pictures: "รูปภาพ",
               title: "หัวข้อ",
               content: "กระแสความ",
          },
          notifications: {
               notifications: "การแจ้งเตือน",
          }
     }
};

export default thaiMessages;
