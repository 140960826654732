import * as React from "react";
import { useRecordContext, Button, CreateButton, DeleteButton, ShowButton, Show, List, SimpleShowLayout, SimpleShowLayoutList, SimpleList, Datagrid, ReferenceField, ReferenceManyField, SingleFieldList, ReferenceArrayInput, SelectArrayInput, BooleanField, ChipField, DateField, ImageField, TextField, EmailField, EditButton, Create, Edit, SimpleForm, BooleanInput, ImageInput, PasswordInput, ReferenceInput, SelectInput, TextInput, DateInput, } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Toolbar } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DescriptionIcon from '@material-ui/icons/Description';
import { useLocation } from 'react-router';

import { useTranslate } from 'react-admin';

import LogQuickCreate from './LogQuickCreate';

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

export const LogShow = ({ component_type }, props) => {

     const translate = useTranslate();

     const { source } = props;
     const record = useRecordContext(props);

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     return record ? (
          <div>
               <div style={{height: "50px"}}>
                    <div style={{float: "right", paddingTop: "2px", paddingRight: "10px"}}>
                         <LogQuickCreate parent_id={record.id} component_type={component_type} />
                    </div>
                    <Typography variant="h6">{translate('custom.logs.logs')}</Typography>
               </div>
               { record.logs ? (
                    <div>
                    {record.logs.map(log => (
                         <div key={log.id} style={{background: "#EEE", marginBottom: "10px"}}>
                              { isSmall ? (
                                   <div style={{background: "#DDD", padding: "6px"}}>
                                        <div>
                                             <div style={{display: "inline-block", verticalAlign: "middle"}}>
                                                  <img src={log.profile_picture} style={{maxHeight: '50px', margin: '-1px 10px -1px -1px', borderRadius: '50px', backgroundColor: '#DDD', verticalAlign: 'middle'}} />
                                             </div>
                                             <div style={{display: "inline-block", verticalAlign: "middle"}}>
                                                  <b>{log.name}</b>
                                                  <div style={{fontSize: "12px"}}>{log.created_at}</div>
                                             </div>
                                        </div>
                                   </div>
                              ) : (
                                   <div style={{background: "#DDD", padding: "6px"}}>
                                        <div style={{float: "right"}}>{log.created_at}</div>
                                        <div>
                                             <img src={log.profile_picture} style={{maxHeight: '50px', margin: '-1px 10px -1px -1px', borderRadius: '50px', backgroundColor: '#DDD', verticalAlign: 'middle'}} />
                                             <b>{log.name}</b>
                                        </div>
                                   </div>
                              )}
                              <div style={{padding: "12px"}}>
                                   <div style={{marginBottom: "10px", paddingLeft: "15px"}}>{log.content}</div>
                                   <div style={{paddingLeft: "15px"}}>
                                        <Gallery>
                                             {log.attachments.map(attachment => (attachment.type == "image") ? (
                                                  <div style={{maxWidth: "60px", display: "inline-block", marginRight: "15px"}}>
                                                       <Item original={attachment.path} thumbnail={attachment.path} width={attachment.image_width} height={attachment.image_height} >
                                                            {({ ref, open }) => (
                                                                 <img ref={ref} onClick={open} src={attachment.thumb} style={{maxWidth: "100%"}} />
                                                            )}
                                                       </Item>
                                                  </div>
                                             ) : null)}
                                        </Gallery>
                                        <div>
                                        {log.attachments.map(attachment => (attachment.type == "document") ? (
                                             <div style={{marginBottom: "6px"}}>
                                                  <a href={attachment.editpath} style={{textDecoration: "none", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100vw - 105px)", display: "block"}}>
                                                        <DescriptionIcon style={{verticalAlign: "middle"}} /> {attachment.name}
                                                  </a>
                                             </div>
                                        ) : null)}
                                        </div>
                                   </div>
                              </div>
                         </div>
                    ))}
                    </div>
               ) : null}
          </div>

) : null};


export default LogShow;
