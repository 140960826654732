import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    SaveButton,
    EditButton,
    TextInput,
    ReferenceInput,
    SelectInput,
    useGetOne,
    useNotify,
    FormWithRedirect,
    useRefresh
} from 'react-admin';
import Button from '@material-ui/core/Button';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useTranslate } from 'react-admin';

import config from './config';


function ClientQuickShow({client_id, show_type, show_edit }) {
     const [showDrawer, setShowDrawer] = useState(false);
     const { data, loading, error } = useGetOne('clients', client_id);
     const notify = useNotify();

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     const translate = useTranslate();

     const phoneUrl = (data && data.phone) ? `tel:${data.phone}` : null;

     const handleClick = () => {
          setShowDrawer(true);
     };

     const handleCloseClick = () => {
          setShowDrawer(false);
     };

     return data ? (
          <>
               { (show_type == "showbutton") ? (
                    <Button onClick={handleClick} color="primary">
                         <VisibilityIcon style={{marginRight: "8px"}} /> {translate('ra.action.show')}
                    </Button>
               ) : (
                    <Button onClick={handleClick} color="primary" style={{padding: 0, textTransform: "initial"}}>
                         {data.name}
                    </Button>
               )}

               { isSmall ? (
                    <Dialog fullWidth open={showDrawer} onClose={handleCloseClick}>
                         <div style={{overflowX: "hidden", padding: "15px"}}>
                              <div style={{fontSize: "18px", background: config.appbar_background, margin: "-16px -16px 20px", padding: "12px 8px", textAlign: "center", color: "#FFF", textTransform: "uppercase", position: "relative"}}>
                                   {(show_edit) ? (
                                        <div style={{position: "absolute", right: "8px"}}>
                                             <EditButton basePath="/clients" label='ra.action.edit' record={data} style={{color: "#FFF"}} />
                                        </div>
                                   ) : null}
                                   <span>{translate('resources.clients.fields.client_details')}</span>
                              </div>
                              <div style={{textAlign: "center"}}>
                                   <img src={data.logo} style={{maxWidth: "70%", maxHeight: "180px", margin: "30px 0"}} />
                              </div>
                              <div style={{fontSize: "25px", marginBottom: "35px", textAlign: "center"}}>{data.name}</div>
                              <div style={{fontSize: "16px", marginBottom: "10px"}}>
                                   <b>{translate('resources.clients.fields.email')}:</b>
                                   <span style={{float: "right"}}>{data.email}</span>
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>{translate('resources.clients.fields.phone')}:</b>
                                   { data.phone ? (
                                        <span style={{float: "right"}}><a href={phoneUrl}>{data.phone}</a></span>
                                   ) : (
                                        <span style={{float: "right"}}> - </span>
                                   )}
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>{translate('resources.clients.fields.website')}:</b>
                                   { data.website ? (
                                        <span style={{float: "right"}}><a href={data.website}>{data.website}</a></span>
                                   ) : (
                                        <span style={{float: "right"}}> - </span>
                                   )}
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>{translate('resources.clients.fields.created_at')}:</b>
                                   <span style={{float: "right"}}>{data.created_at}</span>
                              </div>
                              <hr />
                              <div style={{fontSize: "16px", marginBottom: "10px"}}>
                                   <b>{translate('resources.clients.fields.address')}:</b>
                                   <p>{data.address}</p>
                              </div>
                              <hr />
                              <div style={{fontSize: "16px", marginBottom: "10px"}}>
                                   <b>{translate('resources.clients.fields.notes')}:</b>
                                   <p>{data.notes}</p>
                              </div>
                         </div>
                    </Dialog>
               ) : (
                    <Drawer anchor="right" open={showDrawer} onClose={handleCloseClick}>
                         <div style={{width: "400px", overflow: "hidden", padding: "15px"}}>
                              <div style={{fontSize: "18px", background: config.appbar_background, margin: "-16px -16px 20px", padding: "12px 8px", textAlign: "center", color: "#FFF", textTransform: "uppercase", position: "relative"}}>
                                   {(show_edit) ? (
                                        <div style={{position: "absolute", right: "8px", marginTop: "-4px"}}>
                                             <EditButton basePath="/clients" label='ra.action.edit' record={data} style={{color: "#FFF"}} />
                                        </div>
                                   ) : null}
                                   <span>{translate('resources.clients.fields.client_details')}</span>
                              </div>
                              <div style={{textAlign: "center"}}>
                                   <img src={data.logo} style={{maxWidth: "70%", maxHeight: "180px", margin: "30px 0"}} />
                              </div>
                              <div style={{fontSize: "25px", marginBottom: "35px", textAlign: "center"}}>{data.name}</div>
                              <div style={{fontSize: "16px", marginBottom: "10px"}}>
                                   <b>{translate('resources.clients.fields.email')}:</b>
                                   <span style={{float: "right"}}>{data.email}</span>
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>{translate('resources.clients.fields.phone')}:</b>
                                   { data.phone ? (
                                        <span style={{float: "right"}}><a href={phoneUrl}>{data.phone}</a></span>
                                   ) : (
                                        <span style={{float: "right"}}> - </span>
                                   )}
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>{translate('resources.clients.fields.website')}:</b>
                                   { data.website ? (
                                        <span style={{float: "right"}}><a href={data.website}>{data.website}</a></span>
                                   ) : (
                                        <span style={{float: "right"}}> - </span>
                                   )}
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>{translate('resources.clients.fields.created_at')}:</b>
                                   <span style={{float: "right"}}>{data.created_at}</span>
                              </div>
                              <hr />
                              <div style={{fontSize: "16px", marginBottom: "10px"}}>
                                   <b>{translate('resources.clients.fields.address')}:</b>
                                   <p>{data.address}</p>
                              </div>
                              <hr />
                              <div style={{fontSize: "16px", marginBottom: "10px"}}>
                                   <b>{translate('resources.clients.fields.notes')}:</b>
                                   <p>{data.notes}</p>
                              </div>
                         </div>
                    </Drawer>
               )}
          </>
     ) : null;
}

export default ClientQuickShow;
