import * as React from "react";
import { List, SimpleList, Datagrid, ReferenceField, BooleanField, ChipField, DateField, ImageField, TextField, EmailField, EditButton, Create, Edit, SimpleForm, BooleanInput, ImageInput, PasswordInput, ReferenceInput, SelectInput, TextInput, } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Toolbar } from '@material-ui/core';
import { useLocation } from 'react-router';

import { useTranslate } from 'react-admin';

const useStyles = makeStyles({
     profilePicture: {
          paddingTop: "5px",
          '& img': {
               maxHeight: "50px",
               margin: 0,
               border: '2px solid #bbb',
               borderRadius: '50px',
               backgroundColor: '#EEE',
          },
     },
     editProfilePicture: {
          '& img': {
               border: '2px solid #bbb',
               borderRadius: '50%',
               backgroundColor: '#EEE',
          },
     },
});

export const TeamEdit = props => {

     const location = useLocation();
     const project_id =
          location.state && location.state.record
          ? location.state.record.project_id
          : undefined;

     const translate = useTranslate();

     const redirect = project_id ? `/projects/${project_id}/show` : false;

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     return (
     <div>
          {isSmall ? (
               <Edit undoable={false} title=" " {...props}>
                    <SimpleForm redirect={redirect}>
                         <ReferenceInput disabled source="project_id" label="resources.projects.name" reference="projects" fullWidth>
                              <SelectInput optionText="project_name" />
                         </ReferenceInput>
                         <TextInput source="team_name" label='custom.projects.team_name' fullWidth  />
                    </SimpleForm>
               </Edit>
          ) : (
               <Edit undoable={false} title=" " {...props}>
                    <SimpleForm redirect={redirect}>
                         <div style={{width: "60%", margin: "0 auto"}}>
                              <ReferenceInput disabled source="project_id" label="resources.projects.name" reference="projects" fullWidth>
                                   <SelectInput optionText="project_name" />
                              </ReferenceInput>
                              <TextInput source="team_name" label='custom.projects.team_name' fullWidth  />
                         </div>
                    </SimpleForm>
               </Edit>
          )}
     </div>
)};

export const TeamCreate = props => {

     const location = useLocation();
     const project_id =
          location.state && location.state.record
          ? location.state.record.project_id
          : undefined;

     const redirect = project_id ? `/projects/${project_id}/show` : false;

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     return (
          <div>
               {(isSmall) ? (
                    <Create undoable={false} {...props}>
                         <SimpleForm redirect={redirect}>
                              <ReferenceInput disabled source="project_id" reference="projects" fullWidth>
                                   <SelectInput optionText="project_name" />
                              </ReferenceInput>
                              <TextInput source="team_name" fullWidth  />
                         </SimpleForm>
                    </Create>
               ) : (
                    <Create undoable={false} {...props}>
                         <SimpleForm redirect={redirect}>
                              <div style={{width: "60%", margin: "0 auto"}}>
                                   <ReferenceInput disabled source="project_id" reference="projects" fullWidth>
                                        <SelectInput optionText="project_name" />
                                   </ReferenceInput>
                                   <TextInput source="team_name" fullWidth  />
                              </div>
                         </SimpleForm>
                    </Create>
               )}
          </div>
)};
