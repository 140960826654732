import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    SaveButton,
    TextInput,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    BooleanInput,
    useCreate,
    useNotify,
    FormWithRedirect,
    useRefresh
} from 'react-admin';
import { useMediaQuery, Drawer, Button } from '@material-ui/core';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useTranslate } from 'react-admin';

function TeammemberQuickCreate({team_id }) {
     const [showDialog, setShowDialog] = useState(false);
     const [create, { loading }] = useCreate('teammembers');
     const notify = useNotify();

     const translate = useTranslate();

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     const handleClick = () => {
          setShowDialog(true);
     };

     const handleCloseClick = () => {
          setShowDialog(false);
     };

     const handleChange = useRefresh();

     const handleSubmit = async values => {
          values.team_id = team_id;
          create(
               { payload: { data: values } },
               {
                    onSuccess: ({ data }) => {
                         setShowDialog(false);
                         handleChange();
                    },
                    onFailure: ({ error }) => {
                         notify(error.message, 'error');
                    }
               }
          );
     };

     return (
          <>
               <Button onClick={handleClick}>
                    <IconContentAdd /> <span>{translate('custom.projects.add_team_member')}</span>
               </Button>

               <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Add Team Member">
                    <DialogTitle>{translate('custom.projects.add_team_member')}</DialogTitle>

                    <FormWithRedirect
                         resource="teams"
                         save={handleSubmit}
                         render={({
                              handleSubmitWithRedirect,
                              pristine,
                              saving
                         }) => (
                              <>
                                   <DialogContent>
                                        <ReferenceInput source="user_id" reference="users" label='custom.projects.user' perPage="1000" fullWidth>
                                             <AutocompleteInput optionText="name" />
                                        </ReferenceInput>
                                        <BooleanInput source="is_teamleader" label='custom.projects.is_teamleader' />
                                   </DialogContent>
                                   <DialogActions>
                                        <Button onClick={handleCloseClick} disabled={loading}>
                                             <IconCancel /><span>{translate('custom.general.cancel')}</span>
                                        </Button>
                                        <SaveButton handleSubmitWithRedirect={handleSubmitWithRedirect} pristine={pristine} saving={saving} disabled={loading} />
                                   </DialogActions>
                              </>
                         )}
                    />
               </Dialog>
          </>
     );
}

export default TeammemberQuickCreate;
