import * as React from 'react';
import { Sidebar, Layout } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import MyAppBar from './MyAppBar';

import config from './config';

const useSidebarStyles = makeStyles({
     drawerPaper: {
          backgroundColor: config.navbar_background,
          color: "#FFFFFF",
          marginRight: "20px",
          '& a': {
               color: config.navbar_link_color,
          },
          '& span': {
               color: config.navbar_link_color,
          },
          '& svg': {
               color: config.navbar_link_color,
          },
          '& .RaMenuItemLink-active-47': {
               color: config.navbar_link_color_active,
               '& svg': {
                    color: config.navbar_link_color_active,
               },
          }
     },
});

const MySidebar = props => {
    const classes = useSidebarStyles();
    return (
        <Sidebar classes={classes} {...props} />
    );
};

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} sidebar={MySidebar} />;

export default MyLayout;
