import * as React from "react";

const config = {
     app_title: "Flowcon",
     api_server: "https://flowcon.work/api",
     standard_server: "https://flowcon.work",
     project_name: "Flowcon",
     appbar_background: "#3180d2",
     navbar_background: "#333",
     navbar_link_color: "#FFF",
     navbar_link_color_active: "#888",
     google_maps_key: "AIzaSyDRxeZfYhpqU1sNVAdipjoRm_l4yPynTC0",
};


export default config;
