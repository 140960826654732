import * as React from "react";
import { useGetList } from 'react-admin';
import { useMediaQuery, Card, CardContent, CardHeader } from '@material-ui/core';

import AnnouncementQuickCreate from './AnnouncementQuickCreate';

import AddAlertIcon from '@material-ui/icons/AddAlert';
import HomeWorkIcon from '@material-ui/icons/HomeWork';

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { useTranslate } from 'react-admin';

import { Gallery, Item } from 'react-photoswipe-gallery'

const accessRights = localStorage.getItem('access_rights');

export default () => {
     const translate = useTranslate();

     const { data, ids, loading, error } = useGetList('announcements');

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     return (
          <div style={{paddingTop: "15px"}}>

               <div style={{textAlign: "center", padding: "30px 15px", marginBottom: "45px"}}>
                    <div><HomeWorkIcon style={{fontSize: "70px", color: "#00a2aa"}} /></div>
                    <div style={{fontSize: "70px", fontFamily: "'Roboto', sans-serif", color: "#00a2aa"}}>{translate('custom.general.welcome')}</div>
                    <div>{translate('custom.general.welcome_message')}</div>
               </div>

               <div style={{padding: "10px"}}>
                    {(accessRights == "superadmin" || accessRights == "admin") ? (
                         <div style={{float: "right", marginTop: "-15px"}}><AnnouncementQuickCreate  component_type="announcements" /></div>
                    ) : null }
                    <div style={{fontSize: "26px", lineHeight: "22px", color: "#3f51b5", marginBottom: "10px"}}><AddAlertIcon style={{marginLeft: "4px", marginRight: "4px", verticalAlign: "top"}} /> {translate('custom.announcements.latest_announcements')}</div>
               </div>

               { data ? (
                    <div style={{paddingTop: "20px"}}>
                    {ids.map(id => (
                         <>
                              { isSmall ? (
                                   <div style={{margin: "0.5%", padding: "10px"}}>
                                        <Card key={id} style={{marginBottom: "10px"}} variant="outlined">
                                             <CardHeader title={data[id].title} subheader={data[id].created_at} />
                                             <CardContent>
                                                  <div style={{marginBottom: "10px"}}>
                                                       <Gallery>
                                                            {data[id].attachments.map(attachment => (
                                                                 <div style={{maxWidth: "60px", display: "inline-block", marginRight: "15px"}}>
                                                                      <Item original={attachment.path} thumbnail={attachment.path} width={attachment.image_width} height={attachment.image_height} >
                                                                           {({ ref, open }) => (
                                                                                <img ref={ref} onClick={open} src={attachment.thumb} style={{maxWidth: "100%"}} />
                                                                           )}
                                                                      </Item>
                                                                 </div>
                                                            ))}
                                                       </Gallery>
                                                  </div>
                                                  <div>{data[id].content}</div>

                                             </CardContent>
                                        </Card>
                                   </div>
                              ) : (
                                   <div style={{display: "inline-block", width: "49%", margin: "0.5%", verticalAlign: "top"}}>
                                        <Card key={id} style={{marginBottom: "10px"}} variant="outlined" style={{height: "280px", overflow: "auto"}}>
                                             <CardHeader title={data[id].title} subheader={data[id].created_at} />
                                             <CardContent>
                                                  <div style={{marginBottom: "10px"}}>
                                                       <Gallery>
                                                            {data[id].attachments.map(attachment => (
                                                                 <div style={{maxWidth: "60px", display: "inline-block", marginRight: "15px"}}>
                                                                      <Item original={attachment.path} thumbnail={attachment.path} width={attachment.image_width} height={attachment.image_height} >
                                                                           {({ ref, open }) => (
                                                                                <img ref={ref} onClick={open} src={attachment.thumb} style={{maxWidth: "100%"}} />
                                                                           )}
                                                                      </Item>
                                                                 </div>
                                                            ))}
                                                       </Gallery>
                                                  </div>
                                                  <div>{data[id].content}</div>

                                             </CardContent>
                                        </Card>
                                   </div>
                              )}
                         </>
                    ))}
                    </div>
               ) : null}
          </div>
)};
