import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    SaveButton,
    TextInput,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    DateInput,
    ImageInput,
    ImageField,
    useCreate,
    useNotify,
    useQuery,
    FormWithRedirect,
    useRefresh
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { useTranslate } from 'react-admin';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const ReportTaskItemForm = ({taskId, report_id}) => {

     const [create, { loading }] = useCreate('reportitemscustom');
     const notify = useNotify();

     const [selectedImages, setSelectedImages] = useState(new Array());

     const { data, taskLoadingData, taskErrorData } = useQuery({
          type: 'getOne',
          resource: 'tasks',
          payload: { id: taskId }
     });

     const translate = useTranslate();

     const toggleSelectedImage = (e, attachmentId) => {

          let currentSelectedImages = selectedImages;

          if(currentSelectedImages.indexOf(attachmentId) != -1)
          {

               setSelectedImages(currentSelectedImages.filter(item => item !== attachmentId));
          }
          else
          {
               if(currentSelectedImages.length < 4)
               {
                    setSelectedImages(currentSelectedImages => [...currentSelectedImages, attachmentId]);
               }
               else
               {
                    notify(translate('custom.reports.image_select_error'), 'error');
               }
          }
     }

     const handleChange = useRefresh();

     const handleSubmit = async values => {
          values.report_id = report_id;
          values.task_images = selectedImages;


          create(
               { payload: { data: values } },
               {
                    onSuccess: ({ data }) => {
                         // setShowDialog(false);
                         handleChange();
                    },
                    onFailure: ({ error }) => {
                         notify(error.message, 'error');
                    }
               }
          );
     };

     return (
          <FormWithRedirect record={data}
               resource="reportitemscustom"
               save={handleSubmit}
               render={({
                    handleSubmitWithRedirect,
                    pristine,
                    saving
               }) => (
                    <>
                         <DialogContent>

                              <TextInput multiline source="description" label='custom.reports.description' fullWidth />
                              <TextInput multiline source="location" label='custom.reports.location' fullWidth />
                              <TextInput multiline source="remarks" label='custom.reports.remarks' fullWidth />

                              <div style={{marginTop: "10px", marginBottom: "5px", fontSize: "13px", fontWeight: "bold"}}>{translate('custom.reports.image_select_text')}</div>

                              <div style={{textAlign: "center"}}>
                                   { (data && data.attachments) ? data.attachments.map(attachment => (attachment.id) ? (
                                        <div key={attachment.id} onClick={((e) => toggleSelectedImage(e, attachment.id))} style={{cursor: "pointer", background: "#EEE", display: "inline-block", overflow: "hidden", border: "2px solid #CCC", width: "29%", margin: "5px 1%", position: "relative"}}>
                                             { (selectedImages.indexOf(attachment.id) != -1) ? (
                                                  <div style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0, background: "rgba(0,0,0,0.4)", textAlign: "center", color: "#0F0"}}>
                                                       <CheckCircleIcon style={{fontSize: "60px", marginTop: "calc(50% - 30px)"}} />
                                                  </div>
                                             ) : null }
                                             <img src={attachment.thumb} style={{maxWidth: "100%", marginBottom: "-7px"}} />
                                        </div>
                                   ) : null) : null}
                              </div>

                         </DialogContent>
                         <DialogActions>

                              <SaveButton
                                   handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                   }
                                   pristine={pristine}
                                   saving={saving}
                                   disabled={loading}
                              />
                         </DialogActions>
                    </>
               )}
          />
     )
};

const ReportTaskItemQuickCreate = ({ report_id, project_id }) => {

     const [create, { loading }] = useCreate('reportitemscustom');

     const { data, loadingData, errorData } = useQuery({
          type: 'getList',
          resource: 'tasks',
          payload: { pagination: { page: 1 , perPage: 1000 }, filter: {project_id: project_id}, sort: { field: 'published_at', order: 'DESC' } }
     });

     const [showDialog, setShowDialog] = useState(false);
     const [showItemDialog, setShowItemDialog] = useState(false);

     const translate = useTranslate();

     const [taskId, setTaskId] = useState(false);



     const handleClick = () => {
          setShowDialog(true);
     };

     const handleItemClick = (e, props) => {
          setTaskId(props);

          setShowDialog(false);
          setShowItemDialog(true);
     };

     const handleCloseClick = () => {
          setShowDialog(false);
     };

     const handleCloseItemClick = () => {
          setShowItemDialog(false);
     };




     return (data) ? (
          <>
               <Button onClick={handleClick}>
                    <IconContentAdd /> <span>{translate('custom.reports.add_task_item')}</span>
               </Button>
               <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={handleCloseClick}
                    aria-label="Add Task Item"
               >
                    <DialogTitle>{translate('custom.reports.add_task_item')}</DialogTitle>
                    <>
                         <DialogContent>
                              <div>
                                   {data.map(taskItem => (
                                        <div><div onClick={((e) => handleItemClick(e, taskItem.id))} style={{width: "100%", textAlign: "left", padding: "10px 6px", background: "#EEE", marginBottom: "10px", cursor: "pointer"}}>{taskItem.task_title} <div style={{float: "right", marginTop: "-4px", fontSize: "13px"}}><div style={{fontSize: "10px"}}>Updated At</div>{taskItem.updated_at}</div></div></div>
                                   ))}
                              </div>
                         </DialogContent>
                         <DialogActions>
                              <Button onClick={handleCloseClick} disabled={loading}>
                                   <IconCancel /><span>{translate('custom.general.cancel')}</span>
                              </Button>
                         </DialogActions>
                    </>
               </Dialog>
               <Dialog
                    fullWidth
                    open={showItemDialog}
                    onClose={handleCloseItemClick}
                    aria-label="Add Task Item"
               >
                    <DialogTitle>{translate('custom.reports.add_task_item')}</DialogTitle>
                    {(taskId) ? (
                         <ReportTaskItemForm taskId={taskId} report_id={report_id} />
                    ): null}
                    <div style={{position: "absolute", bottom: "8px", right: "115px"}}>
                         <Button onClick={handleCloseItemClick} disabled={loading}>
                              <IconCancel /><span>{translate('custom.general.cancel')}</span>
                         </Button>
                    </div>
               </Dialog>
          </>
     ) : null;

}

export default ReportTaskItemQuickCreate;
