import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    SaveButton,
    EditButton,
    TextInput,
    ReferenceInput,
    SelectInput,
    useGetOne,
    useNotify,
    FormWithRedirect,
    useRefresh
} from 'react-admin';
import Button from '@material-ui/core/Button';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useTranslate } from 'react-admin';

import config from './config';

const accessRights = localStorage.getItem('access_rights');


function UserQuickShow({user_id, image_height, show_type, show_edit }) {
     const [showDrawer, setShowDrawer] = useState(false);
     const { data, loading, error } = useGetOne('users', user_id);
     const notify = useNotify();

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

     const translate = useTranslate();

     const phoneUrl = (data && data.phone) ? `tel:${data.phone}` : null;
     const lineUrl = (data && data.line_id) ? `https://line.me/R/${data.line_id}` : null;

     const backgroundImage = (data && data.profile_picture) ? `url('${data.profile_picture}')` : null;

     const handleClick = () => {
          setShowDrawer(true);
     };

     const handleCloseClick = () => {
          setShowDrawer(false);
     };

     return data ? (
          <>
               { (show_type == "showbutton") ? (
                    <Button onClick={handleClick} color="primary">
                         <VisibilityIcon style={{marginRight: "8px"}} /> {translate('ra.action.show')}
                    </Button>
               ) : (
                    <Button onClick={handleClick} style={{padding: 0, margin:0, minWidth: "initial", background: "transparent"}}>
                         <div style={{height: image_height, width: image_height, backgroundImage: backgroundImage, backgroundSize: "cover", backgroundPosition: "center center", borderRadius: '50%', backgroundColor: '#DDD', border: '1px solid #DDD', verticalAlign: 'middle'}} alt={data.name} title={data.name}></div>
                    </Button>
               )}

               { isSmall ? (
                    <Dialog fullWidth open={showDrawer} onClose={handleCloseClick}>
                         <div style={{overflowX: "hidden", padding: "15px"}}>
                              <div style={{fontSize: "18px", background: config.appbar_background, margin: "-16px -16px 20px", padding: "12px 8px", textAlign: "center", color: "#FFF", textTransform: "uppercase", position: "relative"}}>
                                   {(show_edit && (accessRights == "superadmin" || (accessRights == "admin" && data.user_goup_label != "Super Admin" && data.user_goup_label != "Admin"))) ? (
                                        <div style={{position: "absolute", right: "8px", marginTop: "-4px"}}>
                                             <EditButton basePath="/users" label='ra.action.edit' record={data} style={{color: "#FFF"}} />
                                        </div>
                                   ) : null}
                                   <span>{translate('resources.users.fields.user_details')}</span>
                              </div>
                              <div style={{textAlign: "center"}}>
                                   <img src={data.profile_picture} style={{maxWidth: "150px", borderRadius: "50%", margin: "25px 0"}} />
                              </div>
                              <div style={{fontSize: "25px", marginBottom: "30px", textAlign: "center"}}>{data.name}</div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>User Group:</b>
                                   <span style={{float: "right"}}>{data.user_goup_label}</span>
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>Email:</b>
                                   <span style={{float: "right"}}>{data.email}</span>
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>Phone:</b>
                                   { data.phone ? (
                                        <span style={{float: "right"}}><a href={phoneUrl}>{data.phone}</a></span>
                                   ) : (
                                        <span style={{float: "right"}}> - </span>
                                   )}
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>Line ID:</b>
                                   { data.line_id ? (
                                        <span style={{float: "right"}}><a href={lineUrl}>{data.line_id}</a></span>
                                   ) : (
                                        <span style={{float: "right"}}> - </span>
                                   )}
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>Created At:</b>
                                   <span style={{float: "right"}}>{data.created_at}</span>
                              </div>
                         </div>
                    </Dialog>
               ) : (
                    <Drawer anchor="right" open={showDrawer} onClose={handleCloseClick}>
                         <div style={{width: "350px", overflow: "hidden", padding: "15px"}}>
                              <div style={{fontSize: "18px", background: config.appbar_background, margin: "-16px -16px 20px", padding: "12px 8px", textAlign: "center", color: "#FFF", textTransform: "uppercase", position: "relative"}}>
                                   {(show_edit && (accessRights == "superadmin" || (accessRights == "admin" && data.user_goup_label != "Super Admin" && data.user_goup_label != "Admin"))) ? (
                                        <div style={{position: "absolute", right: "8px", marginTop: "-4px"}}>
                                             <EditButton basePath="/users" label='ra.action.edit' record={data} style={{color: "#FFF"}} />
                                        </div>
                                   ) : null}
                                   <span>{translate('resources.users.fields.user_details')}</span>
                              </div>
                              <div style={{textAlign: "center"}}>
                                   <img src={data.profile_picture} style={{maxWidth: "150px", borderRadius: "50%", margin: "25px 0"}} />
                              </div>
                              <div style={{fontSize: "25px", marginBottom: "30px", textAlign: "center"}}>{data.name}</div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>{translate('resources.users.fields.user_group')}:</b>
                                   <span style={{float: "right"}}>{data.user_goup_label}</span>
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>{translate('resources.users.fields.email')}:</b>
                                   <span style={{float: "right"}}>{data.email}</span>
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>{translate('resources.users.fields.phone')}:</b>
                                   { data.phone ? (
                                        <span style={{float: "right"}}><a href={phoneUrl}>{data.phone}</a></span>
                                   ) : (
                                        <span style={{float: "right"}}> - </span>
                                   )}
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>{translate('resources.users.fields.line_id')}:</b>
                                   { data.line_id ? (
                                        <span style={{float: "right"}}><a href={lineUrl}>{data.line_id}</a></span>
                                   ) : (
                                        <span style={{float: "right"}}> - </span>
                                   )}
                              </div>
                              <div style={{fontSize: "16px", marginBottom: "16px"}}>
                                   <b>{translate('resources.users.fields.created_at')}:</b>
                                   <span style={{float: "right"}}>{data.created_at}</span>
                              </div>
                         </div>
                    </Drawer>
               )}
          </>
     ) : null;
}

export default UserQuickShow;
