import * as React from "react";
import { useRecordContext, Toolbar, SaveButton, DeleteButton, Show, List, SimpleList, Datagrid, ReferenceField, BooleanField, ChipField, DateField, UrlField, ImageField, TextField, EmailField, ShowButton, EditButton, Create, Edit, SimpleForm, BooleanInput, ImageInput, PasswordInput, ReferenceInput, SelectInput, TextInput, } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

import ClientQuickShow from './ClientQuickShow';

import { useTranslate } from 'react-admin';

import config from './config';

const accessRights = localStorage.getItem('access_rights');

const useStyles = makeStyles({
     profilePicture: {
          paddingTop: "5px",
          '& img': {
               maxHeight: "50px",
               margin: 0,
               maxWidth: '100%',
          },
     },
     editProfilePicture: {
          '& img': {
               maxWidth: '100%',
               margin: '22px 0',
          },
     },
});

const ClientQuickShowInput = (props) => {
     const { source } = props;
     const record = useRecordContext(props);

     return record ? (
          <ClientQuickShow client_id={record.id} show_type="showbutton" show_edit={true} />
     ) : null
};

export const ClientList = props => {

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     return (
     <List {...props} title=" " bulkActionButtons={false} perPage="1000" exporter={false}>
          {isSmall ? (
               <div>
                <SimpleList
                    linkType="show"
                    leftAvatar={record => <img src={record.logo} style={{maxHeight: "100%"}} />}
                    primaryText={record => record.name}
                    secondaryText={record => record.website}
                >

                </SimpleList>
                </div>
            ) : (
               <Datagrid>
                    <ImageField source="logo" className={classes.profilePicture} />
                    <TextField source="name" />
                    <EmailField source="email" />
                    <TextField source="phone" />
                    <UrlField source="website" />
                    <ClientQuickShowInput />
               </Datagrid>
          )}
     </List>
)};


const ClientProfileCard = (props) => {

     const { source } = props;
     const record = useRecordContext(props);

     const translate = useTranslate();

     const phoneUrl = (record && record.phone) ? `tel:${record.phone}` : null;

     return record ? (
          <div style={{padding: "0 10px", marginBottom: "15px"}}>
               <div style={{maxWidth: "400px", margin: "0 auto", overflow: "hidden", padding: "15px", borderRadius: "10px", boxShadow: "0 0 5px 2px #aaa", background: "#FAFAFA"}}>
                    <div style={{fontSize: "17px", background: config.appbar_background, margin: "-16px -16px 20px", padding: "8px", textAlign: "center", color: "#FFF", textTransform: "uppercase"}}>{translate('resources.clients.fields.client_details')}</div>
                    <div style={{textAlign: "center"}}>
                         <img src={record.logo} style={{maxWidth: "70%", margin: "30px 0"}} />
                    </div>
                    <div style={{fontSize: "25px", marginBottom: "35px", textAlign: "center"}}>{record.name}</div>
                    <div style={{fontSize: "16px", marginBottom: "10px"}}>
                         <b>{translate('resources.clients.fields.email')}:</b>
                         <span style={{float: "right"}}>{record.email}</span>
                    </div>
                    <div style={{fontSize: "16px", marginBottom: "16px"}}>
                         <b>{translate('resources.clients.fields.phone')}:</b>
                         { record.phone ? (
                              <span style={{float: "right"}}><a href={phoneUrl}>{record.phone}</a></span>
                         ) : (
                              <span style={{float: "right"}}> - </span>
                         )}
                    </div>
                    <div style={{fontSize: "16px", marginBottom: "16px"}}>
                         <b>{translate('resources.clients.fields.website')}:</b>
                         { record.website ? (
                              <span style={{float: "right"}}><a href={record.website}>{record.website}</a></span>
                         ) : (
                              <span style={{float: "right"}}> - </span>
                         )}
                    </div>
                    <div style={{fontSize: "16px", marginBottom: "16px"}}>
                         <b>{translate('resources.clients.fields.created_at')}:</b>
                         <span style={{float: "right"}}>{record.created_at}</span>
                    </div>
                    <hr />
                    <div style={{fontSize: "16px", marginBottom: "10px"}}>
                         <b>{translate('resources.clients.fields.address')}:</b>
                         <p>{record.address}</p>
                    </div>
                    <hr />
                    <div style={{fontSize: "16px", marginBottom: "10px"}}>
                         <b>{translate('resources.clients.fields.notes')}:</b>
                         <p>{record.notes}</p>
                    </div>
               </div>
          </div>
     ) : null;
};

export const ClientShow = props => {

     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     return (
          <>

               <Show {...props} title=" " component="div">
                    { isSmall ? (
                         <ClientProfileCard />
                    ) : (
                         <ClientProfileCard />
                    )}
               </Show>
          </>
)};

const EditTitle = ({ record }) => {
     return <span>Client: {record ? `${record.name}` : ''}</span>;
};

const Aside = ({ record }) => {
     const classes = useStyles();

     return(
     <div style={{ width: "200px", minWidth: "200px", margin: '1em' }}>
          {record && (
               <div style={{textAlign: "center"}}>
                    <ImageField source="logo" label="" className={classes.editProfilePicture}  />
                    <Typography variant="h6">
                         { record.name }
                    </Typography>
                    <hr />
                    <Typography variant="body2">
                         { record.email }
                    </Typography>
               </div>
          )}
     </div>
)};

const EditToolbar = props => {

     if(accessRights == "superadmin")
     {
          return (
               <Toolbar {...props} style={{justifyContent: "space-between"}}>
                    <SaveButton />
                    <DeleteButton />
               </Toolbar>
          )
     }
     else
     {
          return (
               <Toolbar {...props}>
                    <SaveButton />
               </Toolbar>
          )
     }
};

export const ClientEdit = props => {
     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     const translate = useTranslate();

     return (
     <div>
          {isSmall ? (
               <Edit undoable={false} title=" " {...props}>
                    <SimpleForm redirect="/clients" toolbar={<EditToolbar />}>
                              <ImageInput source="pictures"  label="resources.clients.fields.logo" accept="image/jpeg,image/png" placeholder={translate('resources.clients.fields.logo_description')} >
                                   <ImageField source="src" />
                              </ImageInput>
                              <TextInput source="name" label="resources.clients.fields.name" fullWidth  />
                              <TextInput source="email" label="resources.clients.fields.email" fullWidth />
                              <TextInput source="phone" label="resources.clients.fields.phone" fullWidth />
                              <TextInput source="website" label="resources.clients.fields.website" fullWidth />
                              <TextInput multiline source="address" label="resources.clients.fields.address" fullWidth />
                              <TextInput source="tax_id" label="Tax ID" label="resources.clients.fields.tax_id" fullWidth />
                              <TextInput multiline source="notes" label="resources.clients.fields.notes" fullWidth />
                    </SimpleForm>
               </Edit>
          ) : (
               <Edit undoable={false} title=" " aside={<Aside />} {...props}>
                    <SimpleForm redirect="/clients" toolbar={<EditToolbar />}>
                         <div style={{width: "60%", margin: "0 auto"}}>
                              <ImageInput source="pictures"  label="resources.clients.fields.logo" accept="image/jpeg,image/png" placeholder={translate('resources.clients.fields.logo_description')} >
                                   <ImageField source="src" />
                              </ImageInput>
                              <TextInput source="name" label="resources.clients.fields.name" fullWidth  />
                              <TextInput source="email" label="resources.clients.fields.email" fullWidth />
                              <TextInput source="phone" label="resources.clients.fields.phone" fullWidth />
                              <TextInput source="website" label="resources.clients.fields.website" fullWidth />
                              <TextInput multiline source="address" label="resources.clients.fields.address" fullWidth />
                              <TextInput source="tax_id" label="Tax ID" label="resources.clients.fields.tax_id" fullWidth />
                              <TextInput multiline source="notes" label="resources.clients.fields.notes" fullWidth />
                         </div>
                    </SimpleForm>
               </Edit>
          )}
     </div>
)};

export const ClientCreate = props => {
     const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
     const classes = useStyles();

     const translate = useTranslate();

     return (
          <div>
               {(isSmall) ? (
                    <Create undoable={false} title=" " {...props}>
                         <SimpleForm redirect="/clients">
                              <ImageInput source="pictures"  label="resources.clients.fields.logo" accept="image/jpeg,image/png" placeholder={translate('resources.clients.fields.logo_description')} >
                                   <ImageField source="src" />
                              </ImageInput>
                              <TextInput source="name" label="resources.clients.fields.name" fullWidth  />
                              <TextInput source="email" label="resources.clients.fields.email" fullWidth />
                              <TextInput source="phone" label="resources.clients.fields.phone" fullWidth />
                              <TextInput source="website" label="resources.clients.fields.website" fullWidth />
                              <TextInput multiline source="address" label="resources.clients.fields.address" fullWidth />
                              <TextInput source="tax_id" label="Tax ID" label="resources.clients.fields.tax_id" fullWidth />
                              <TextInput multiline source="notes" label="resources.clients.fields.notes" fullWidth />
                         </SimpleForm>
                    </Create>
               ) : (
                    <Create undoable={false} title=" " {...props}>
                         <SimpleForm redirect="/clients">
                              <div style={{width: "60%", margin: "0 auto"}}>
                                   <ImageInput source="pictures"  label="resources.clients.fields.logo" accept="image/jpeg,image/png" placeholder={translate('resources.clients.fields.logo_description')} >
                                        <ImageField source="src" />
                                   </ImageInput>
                                   <TextInput source="name" label="resources.clients.fields.name" fullWidth  />
                                   <TextInput source="email" label="resources.clients.fields.email" fullWidth />
                                   <TextInput source="phone" label="resources.clients.fields.phone" fullWidth />
                                   <TextInput source="website" label="resources.clients.fields.website" fullWidth />
                                   <TextInput multiline source="address" label="resources.clients.fields.address" fullWidth />
                                   <TextInput source="tax_id" label="Tax ID" label="resources.clients.fields.tax_id" fullWidth />
                                   <TextInput multiline source="notes" label="resources.clients.fields.notes" fullWidth />
                              </div>
                         </SimpleForm>
                    </Create>
               )}
          </div>
)};
