import * as React from "react";
import Button from '@material-ui/core/Button';

const LocaleSwitcher = () => {
    const currentLocale = (localStorage.getItem('locale')) ? localStorage.getItem('locale') : "en";

    const handleLocalSwitch = (locale) => {
         localStorage.setItem('locale', locale);

         window.location.reload();
    }

    const englishFlag = `url('${require("./assets/images/en.png")}')`;
    const thaiFlag = `url('${require("./assets/images/th.png")}')`;

    return (currentLocale == "th") ? (
        <span style={{position: "absolute", right: "60px", zIndex:10}}>
            <Button onClick={() => handleLocalSwitch('en')}><div style={{height: "30px", width: "30px", backgroundImage: englishFlag, backgroundSize: "cover", backgroundPosition: "center center", borderRadius: '50%', backgroundColor: '#FFF', verticalAlign: 'middle'}}></div></Button>

        </span>
   ) : (
       <span style={{position: "absolute", right: "60px", zIndex:10}}>
          <Button onClick={() => handleLocalSwitch('th')}><div style={{height: "30px", width: "30px", backgroundImage: thaiFlag, backgroundSize: "cover", backgroundPosition: "center center", borderRadius: '50%', backgroundColor: '#FFF', verticalAlign: 'middle'}}></div></Button>
       </span>
   );
};

export default LocaleSwitcher;
