// in src/App.js
import * as React from "react";
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser } from 'react-admin';
import UserIcon from '@material-ui/icons/Group';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ClassIcon from '@material-ui/icons/Class';

import myDataProvider from './myDataProvider';

import { makeStyles } from '@material-ui/core/styles';
import MyLayout from './MyLayout';
import {UserList, UserShow, UserEdit, UserCreate} from './users';
import {ClientList, ClientShow, ClientEdit, ClientCreate} from './clients';
import {ProjectList, ProjectShow, ProjectEdit, ProjectCreate} from './projects';
import {TeamEdit, TeamCreate} from './teams';
import {TeamMemberEdit, TeamMemberCreate} from './teammembers';
import {TaskList, TaskEdit, TaskCreate, TaskShow} from './tasks';
import {ReportList, ReportEdit, ReportCreate, ReportShow} from './reports';
import Dashboard from './Dashboard';
import authProvider from './authProvider';

import { useLocale, useSetLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import customEnglishMessages from './custom-language-english';
import thaiMessages from './ra-language-thai';

import config from './config';

const messages = {
    th: thaiMessages,
    en: { ...englishMessages, ...customEnglishMessages },
};


const App = () => {
     const locale = (localStorage.getItem('locale')) ? localStorage.getItem('locale') : "en";
     const i18nProvider = polyglotI18nProvider(() => messages[locale], locale);

     const accessRights = localStorage.getItem('access_rights');

     // const currentVersion = "1.4";
     //
     // if(!localStorage.getItem('login_refresh'))
     // {
     //      localStorage.setItem('login_refresh', '1');
     //      window.location.reload();
     // }
     //
     //
     // if(!localStorage.getItem('current_version') || localStorage.getItem('current_version') != currentVersion)
     // {
     //      localStorage.setItem('current_version', currentVersion);
     //      window.location.reload();
     // }

     if(accessRights == "superadmin" || accessRights == "admin")
     {
          return (
               <Admin locale="th" i18nProvider={i18nProvider} dashboard={Dashboard} title={config.app_title} layout={MyLayout} authProvider={authProvider} dataProvider={myDataProvider}>
                    <Resource name="projects" list={ProjectList} create={ProjectCreate} edit={ProjectEdit} show={ProjectShow} icon={ClassIcon} />
                    <Resource name="tasks" list={TaskList} create={TaskCreate} edit={TaskEdit} show={TaskShow} icon={AssignmentLateIcon} />
                    <Resource name="reports" list={ReportList} edit={ReportEdit} create={ReportCreate} show={ReportShow} icon={AssignmentIcon} />
                    <Resource name="clients" list={ClientList} edit={ClientEdit} create={ClientCreate} show={ClientShow} icon={AssignmentIndIcon} />
                    <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} show={UserShow} icon={UserIcon} />
                    <Resource name="teams" create={TeamCreate} edit={TeamEdit} />
                    <Resource name="teammembers" create={TeamMemberCreate} edit={TeamMemberEdit} />
                    <Resource name="usergroups" />
                    <Resource name="foremen" />
                    <Resource name="taskimages" />
                    <Resource name="tasklogs" />
                    <Resource name="projectlogs" />
                    <Resource name="reportitems" />
                    <Resource name="reportitemscustom" />
                    <Resource name="announcements" />
                    <Resource name="notifications" />
               </Admin>
          )
     }
     else if(accessRights == "foremen")
     {
          return (
               <Admin locale="th" i18nProvider={i18nProvider} dashboard={Dashboard} title={config.app_title} layout={MyLayout} authProvider={authProvider} dataProvider={myDataProvider}>
                    <Resource name="projects" list={ProjectList} show={ProjectShow} icon={ClassIcon} />
                    <Resource name="tasks" list={TaskList} create={TaskCreate} edit={TaskEdit} show={TaskShow} icon={AssignmentLateIcon} />
                    <Resource name="reports" list={ReportList} edit={ReportEdit} create={ReportCreate} show={ReportShow} icon={AssignmentIcon} />
                    <Resource name="clients" list={ClientList} show={ClientShow} icon={AssignmentIndIcon} />
                    <Resource name="users" list={UserList} show={UserShow} icon={UserIcon} />
                    <Resource name="teams" create={TeamCreate} edit={TeamEdit} />
                    <Resource name="teammembers" create={TeamMemberCreate} edit={TeamMemberEdit} />
                    <Resource name="usergroups" />
                    <Resource name="foremen" />
                    <Resource name="taskimages" />
                    <Resource name="tasklogs" />
                    <Resource name="projectlogs" />
                    <Resource name="reportitems" />
                    <Resource name="reportitemscustom" />
                    <Resource name="announcements" />
                    <Resource name="notifications" />
               </Admin>
          )
     }
     else
     {
          return (
               <Admin locale="th" i18nProvider={i18nProvider} dashboard={Dashboard} title={config.app_title} layout={MyLayout} authProvider={authProvider} dataProvider={myDataProvider}>
                    <Resource name="projects" list={ProjectList} show={ProjectShow} icon={ClassIcon} />
                    <Resource name="tasks" list={TaskList} create={TaskCreate} edit={TaskEdit} show={TaskShow} icon={AssignmentLateIcon} />
                    <Resource name="reports" icon={AssignmentIcon} />
                    <Resource name="clients" icon={AssignmentIndIcon} />
                    <Resource name="users" list={UserList} show={UserShow} icon={UserIcon} />
                    <Resource name="teams" create={TeamCreate} edit={TeamEdit} />
                    <Resource name="teammembers" create={TeamMemberCreate} edit={TeamMemberEdit} />
                    <Resource name="usergroups" />
                    <Resource name="foremen" />
                    <Resource name="taskimages" />
                    <Resource name="tasklogs" />
                    <Resource name="projectlogs" />
                    <Resource name="reportitems" />
                    <Resource name="reportitemscustom" />
                    <Resource name="announcements" />
                    <Resource name="notifications" />
               </Admin>
          )
     }
};


export default App;
